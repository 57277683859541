import './Accordian.css';
import axios from 'axios';
import Feature from 'ol/Feature';
import { Polygon } from 'ol/geom';
import Modal from '../Modal/Modal';
import Menu from '@mui/material/Menu';
import { unByKey } from 'ol/Observable';
import Tooltip from '@mui/material/Tooltip';
import { useParams } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import { useLocation } from 'react-router-dom';
import { ORDER_URL } from '../../helper/ApiUrl';
import EditIcon from '@mui/icons-material/Edit';
import AuthToken from '../../context/AuthToken';
import SortIcon from '@mui/icons-material/Sort';
import IconButton from '@mui/material/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { createEmpty, extend, buffer } from 'ol/extent';
import { useScreenType } from '../../utils/commonMethods';
import FunctionsIcon from '@mui/icons-material/Functions';
import { CONVERT, TOAST_TYPE } from '../../utils/constants';
import { useLayerContext } from '../../context/LayerContext';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ReactComponent as NewArrow } from '../../assets/NewArrow.svg';
import React, { useEffect, useRef, useCallback, useState } from 'react';
import editLayer from '../../OpenLayers/DIYInteractions/EditInteraction';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import hoverEffect from '../../OpenLayers/OLInteractions/HoverInteraction';
import removeInteraction from '../../OpenLayers/OLFeatures/EscRemoveInteraction';
import removeAllInteraction from '../../OpenLayers/OLFeatures/RemoveInteraction';
import CenterFocusWeakOutlinedIcon from '@mui/icons-material/CenterFocusWeakOutlined';
import { encodeJwtToken } from '../../utils/EncodeJwt';

// import for the move and Delete on right click on parcel
// import MoveIcon from '@mui/icons-material/DriveFileMove';
// import MenuOpenRightIcon from '@mui/icons-material/KeyboardArrowRight';
// import MoveFeature from '../../OpenLayers/OLFeatures/MoveFeature';
// import ClickInteraction from '../../OpenLayers/OLInteractions/ClickInteraction';



// import for the move and Delete on right click on parcel end

export default function Accordian({
  name,
  data,
  type,
  elem,
  viewId,
  number,
  layerId,
  visible,
  selected,
  hex2rgba,
  fillColor,
  modalShow,
  isPreview,
  toolbarRef,
 
  setSelected,
  hoverMapKey,
  layersCount,
  handleError,
  fillOpacity,
  setZoomLayer,
  setModalShow,
  strokeWeight,
  handleSuccess,
  setOneFeature,
  uploadedLayer,
  isStudioOrder,
  outerViewName,
  otterfeatureId,
  setLayersCount,
  setHoverMapKey,
  selectedLayers,
  measurementUnit,
  setUploadedLayer,
  setSelectedLayers,
  setLayersRestrict,
  featureMeasurement,
  // contextInteraction today
   contextInteraction,
  isContextInteraction,
  setHoverOrderFeatureId,
  setHoverOrderFeatureElement,
  // today
  multipleContextInteraction,
  setMultipleContextInteraction,
}) {
  const { id } = useParams();
  const token = AuthToken();
  const modalRef = useRef();
  const location = useLocation();
  const deleteLayerRef = useRef();
  const isMobile = useScreenType();
  const deleteLayerInnerRef = useRef();
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);
  const [sortAreaID, setSortIDActive] = useState(false);
  const pathDecider = location.pathname.substring(0, 12);
  const [sortAreaActive, setSortAreaActive] = useState(false);

  const {
    olMap,
    layerStyle,
    setLayerID,
    setLayerName,
    setLayerType,
    setLayerStyle,
    editInteraction,
    setCutInteraction,
    setCutAllInteraction,
    setLineInteraction,
    setEditInteraction,
    setPointInteraction,
    setSplitInteraction,
    setPolygonInteraction,
    setMultipleSelectInteraction,
  } = useLayerContext();

  const handleAccordianToggle = (num) => {
    if (selected === num) {
      return setSelected(null);
    }
    setSelected(num);
  };

  const handleLayerVisibility = (e) => {
    let updatedItemList = uploadedLayer.map((item) => {
      if (item.name === e.target.id) {
        item.visible = !visible;
        setOneFeature(true);
        item.data.map((singleItem) => {
          singleItem.visible = !visible;
          return { ...singleItem };
        });
      }
      return { ...item };
    });
    setUploadedLayer([...updatedItemList]);
  };

  const handleFeatureVisibility = (e) => {
    let bool = true;
    let updatedItemList = uploadedLayer.map((item) => {
      if (item.name == e.target.id.split('__')[0]) {
        data.map((singleItem) => {
          if (singleItem.id == e.target.id.split('__')[1]) {
            singleItem.visible = !singleItem.visible;
            return { ...singleItem };
          }
          item.visible = true;
        });
        data.map((singleItem) => {
          if (singleItem.visible) {
            bool = false;
          }
        });
        if (bool) {
          item.visible = false;
        } else {
          item.visible = true;
        }
      }
      return { ...item };
    });
    setUploadedLayer([...updatedItemList]);
  };

  const handleModal = (e) => {
    if (modalRef.current) {
      if (!modalRef.current.contains(e.target)) {
        setModalShow(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleModal);
    return () => {
      document.removeEventListener('mousedown', handleModal);
    };
  }, []);

  const deleteLayer = (layerId, otterfeatureId) => {
    if (outerViewName !== 'Default View' || (isStudioOrder && outerViewName== 'Default View')) {
      axios
        .delete(`${ORDER_URL}${id}/view/${viewId}/layer/${layerId}`, {
          headers: {
            Authorization: `Token ${token}`,
            
            Accept: 'application/json',
          },
        })
        .then((res) => {
          const newList = uploadedLayer.filter((item) => {
            return item.id !== layerId;
          });
          const newLayerIds = selectedLayers.filter((item) => {
            return item !== `${otterfeatureId}`;
          });
          setUploadedLayer(newList);
          setLayersRestrict(newLayerIds);
          setSelectedLayers(newLayerIds);
          setLayersCount(layersCount - 1);
          handleSuccess(TOAST_TYPE.SUCCESS, 'Layer Deleted Successfully!');
        })
        .catch((error) => {
          handleError(error);
        });
    } else {
      handleSuccess(
        TOAST_TYPE.WARNING,
        'Deleting Layers in Default View is not allowed!'
      );
    }
  };

  const sortLayerFeatureByID = () => {
    if (sortAreaID) {
      uploadedLayer.map((layer) => {
        if (layer.name === name) {
          layer.data.sort((a, b) => a.newId - b.newId);
        }
      });
    } else if (!sortAreaID) {
      uploadedLayer.map((layer) => {
        if (layer.name === name) {
          layer.data.sort((a, b) => b.newId - a.newId);
        }
      });
    }
    setUploadedLayer(uploadedLayer);
  };

  const sortLayerFeatureByArea = () => {
    if (sortAreaActive) {
      uploadedLayer.map((layer) => {
        if (layer.name === name) {
          layer.data.sort((a, b) => a.measurement - b.measurement);
        }
      });
    } else if (!sortAreaActive) {
      uploadedLayer.map((layer) => {
        if (layer.name === name) {
          layer.data.sort((a, b) => b.measurement - a.measurement);
        }
      });
    }
    setUploadedLayer(uploadedLayer);
  };

  const deleteLayerFeature = (featureId) => {

    if (outerViewName !== 'Default View'  || (isStudioOrder && outerViewName== 'Default View')) {
      axios
        .delete(
          `${ORDER_URL}${id}/view/${viewId}/layer/${layerId}/layer-data/${featureId}`,
          {
            headers: {
              
              "x-api-key":`${encodeJwtToken()}`,
              Accept: 'application/json',
            },
          }
          ,
          data,
         
        )
        .then((response) => {
          
          uploadedLayer.map((layer) => {
            layer.data = layer.data.filter(
              (feature) => feature.id != `${featureId}`
            );
          });
          setUploadedLayer([...uploadedLayer]);
          if (editInteraction) {
            editLayer(
              id,
              olMap,
              name,
              type,
              viewId,
              layerId,
              setZoomLayer,
              uploadedLayer,
              setUploadedLayer
            );
          }
          handleSuccess(TOAST_TYPE.SUCCESS, 'Feature Deleted Successfully!');
        })
        .catch((error) => {
          alert(error)
          console.log(error);
        });
    } else {
      handleSuccess(
        TOAST_TYPE.WARNING,
        'Deleting Feature in Default View is not allowed!'
      );
    }
  };

  const handleLayerStyle = (num) => {
    if (layerStyle === num) {
      return setLayerStyle(null);
    }
    setLayerStyle(num);
  };

  const editLayerStyle = () => {
    handleClose();
    closeAllInteraction();
    // try to open when i click on the edit button , it will display on every time in the middlepart In studio Only for checking
    if (isStudioOrder  || outerViewName !== 'Default View') {
      setLayerID(layerId);
      setLayerName(name);
      setLayerType(type);
      toolbarRef.current.style.display = 'block';
      
      unByKey(hoverMapKey);
      handleLayerStyle(number);
    } 

    // try to open when i click on the edit button , it will display on every time in the middlepart In studio Only for checking
    // if (isStudioOrder || outerViewName !== 'Default View') {
    //   setLayerID(layerId);
    //   setLayerName(name);
    //   setLayerType(type);
    //   toolbarRef.current.style.display = 'block';
    //   unByKey(hoverMapKey);
    //   handleLayerStyle(number);
    // }
     else {
      handleSuccess(
        TOAST_TYPE.WARNING,
        'Editing Layers in Default View is not allowed!'
      );
    }
    isContextInteraction(true);
    setMultipleContextInteraction(false);
  };

  const handleClickOutSide = (e) => {
    if (deleteLayerInnerRef.current) {
      if (!deleteLayerInnerRef.current.contains(e.target)) {
        deleteLayerRef.current.style.opacity = 0;
        deleteLayerRef.current.style.pointerEvents = 'none';
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutSide, true);
  }, []);

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      setLayerStyle(null);
      removeAllInteraction(olMap);
      setCutInteraction(false);
      setEditInteraction(false);
      setLineInteraction(false);
      setPointInteraction(false);
      setSplitInteraction(false);
      setPolygonInteraction(false);
      setMultipleSelectInteraction(false);
      document.body.classList.remove('cut-cursor');
      document.body.classList.remove('edit-cursor');
      document.body.classList.remove('split-cursor');
      document.body.classList.remove('crosshair-cursor');
      isContextInteraction(true);
      setMultipleContextInteraction(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  const closeAllInteraction = () => {
    setLayerStyle(null);
    setCutInteraction(false);
    // setCutAllInteraction(false);
    setEditInteraction(false);
    setLineInteraction(false);
    setPointInteraction(false);
    setSplitInteraction(false);
    isContextInteraction(true);
    setMultipleContextInteraction(false);
    removeAllInteraction(olMap);
    setPolygonInteraction(false);
    setMultipleSelectInteraction(false);
    document.body.classList.remove('cut-cursor');
    document.body.classList.remove('edit-cursor');
    document.body.classList.remove('split-cursor');
    document.body.classList.remove('crosshair-cursor');
  };

  const closeDiyTool = () => {
    unByKey(hoverMapKey);
    setLayerStyle(null);
    setCutInteraction(false);
    // setCutAllInteraction(false);
    setEditInteraction(false);
    setLineInteraction(false);
    setPointInteraction(false);
    setSplitInteraction(false);
    isContextInteraction(true);
    setMultipleContextInteraction(false);
    removeAllInteraction(olMap);
    setPolygonInteraction(false);
    setMultipleSelectInteraction(false);
    hoverEffect(olMap, hex2rgba, hoverMapKey, setHoverMapKey, elem);
    if (pathDecider !== '/share/order') {
      toolbarRef.current.style.display = 'none';
    }
    document.body.classList.remove('cut-cursor');
    document.body.classList.remove('edit-cursor');
    document.body.classList.remove('split-cursor');
    document.body.classList.remove('crosshair-cursor');
  };

  const handleMouseOver = (id, visible) => {
    if (id && pathDecider !== '/share/order') {
      console.log(id, name)
      setHoverOrderFeatureId([id, name, fillColor, visible]);
      setHoverOrderFeatureElement('mouseover');
    }
  };

  const handleMouseOut = (id, visible) => {
    if (id && pathDecider !== '/share/order') {
      setHoverOrderFeatureId([id, name, fillColor, visible]);
      setHoverOrderFeatureElement('mouseout');
    }
  };

  const handleFeatureZoomIn = (coords, type) => {
    const polygon = new Feature({
      geometry: new Polygon([coords]),
    });
    if (type === 'polygon') {
      var extentOfAllFeatures = createEmpty();
      extend(extentOfAllFeatures, polygon.getGeometry().getExtent());
      extentOfAllFeatures = buffer(extentOfAllFeatures, 30);
      if (extentOfAllFeatures[0] != 'Infinity') {
        olMap.getView().fit(extentOfAllFeatures);
      }
    } else if (type === 'line' || type === 'point') {
      olMap
        .getView()
        .animate({ duration: 1000 }, { center: coords }, { zoom: 20 });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // feature move and Delete
  // today
  // const [contextInteraction, isContextInteraction] = useState();
  // today

  // const [moveFeatureData, setMoveFeatureData] = useState([]);
  // const [multipleMoveFeatureData, setMultipleMoveFeatureData] = useState([]);
  // const [showMoveFeatureList, setShowMoveFeatureList] = useState();
  // const [map, setMap] = useState();
  // const [multipleContextInteraction, setMultipleContextInteraction] =
  //   useState(false);
    // const [multiClickKey, setMultiClickKey] = useState();
    // const [multipleSelectKey, setMultipleSelectKey] = useState();
    // const [singleClickKey, setSingleClickKey] = useState();
// complex horha ab 5:11pm 28 may 2024




  // const [featureMenuElem, setFeatureMenuElem] = useState();
 
  // useEffect(() => {
  //   const elem = document.getElementById('feature-menu-cont');
  //   setFeatureMenuElem(elem);
  // }, []);

  // useEffect(() => {
  //   if (multipleContextInteraction === false) {
  //     setMultipleMoveFeatureData([]);
  //     unByKey(multiClickKey);
  //     unByKey(multipleSelectKey);
  //   }
  //   if (
  //     map &&
  //     multipleContextInteraction === false &&
  //     contextInteraction &&
  //     outerViewName == 'Default View'
  //   )
  //     ClickInteraction(
  //       map,
  //       featureMenuElem,
  //       setMoveFeatureData,
  //       contextInteraction,
  //       setSingleClickKey
  //     );
  //   else if (
  //     multipleContextInteraction === true ||
  //     contextInteraction === false
  //   )
  //     unByKey(singleClickKey);
  // }, [multipleContextInteraction, contextInteraction, map, outerViewName]);

  // required function handlers 

  // const OpenMoveFeatureBar = () => {
  //   let x = true;
  //   if (moveFeatureData.length)
  //     uploadedLayer.map((item, index) => {
  //       if (
  //         item.id !== parseInt(moveFeatureData[1]) &&
  //         item.type === moveFeatureData[2]
  //       )
  //         x = false;
  //     });
  //   else if (multipleMoveFeatureData.length)
  //     uploadedLayer.map((item, index) => {
  //       if (
  //         item.id !== multipleMoveFeatureData[1] &&
  //         item.type === multipleMoveFeatureData[2]
  //       )
  //         x = false;
  //     });
  //   if (x) setShowMoveFeatureList(false);
  //   else setShowMoveFeatureList(true);
  // };


  // feature move and Delete end

  console.log(layerStyle === number , layerStyle, number, "layerStyle === number ")
  
  return (
    <div className="feature-cont">
      <div className="feature-cont-outter">
        <div
          className={`feature-cont-outter-one ${layerStyle === number ? 'layer-style-active' : pathDecider === '/share/order' ? 'feature-cont-share-outter-one' : '' }`}
          // style={isStudioOrder ? { gridTemplateColumns: "5% 9% 7% 40% 34% 5%" } : { gridTemplateColumns: "5% 9% 7% 40% 28% 11%" }}
          style={isStudioOrder ? { gridTemplateColumns: "5% 9% 7% 40% 28% 11%" } : { gridTemplateColumns: "5% 9% 7% 40% 28% 1" }}
        >
          <div
            className="change-color"
            onClick={() => {
              closeDiyTool();
              setModalShow(name);
            }}
            style={{ backgroundColor: fillColor }}
          >
            {modalShow === name ? (
              <Modal
                name={name}
                type={type}
                modalRef={modalRef}
                fillColor={fillColor}
                fillOpacity={fillOpacity}
                borderWidth={strokeWeight}
                uploadedLayer={uploadedLayer}
                setUploadedLayer={setUploadedLayer}
              />
            ) : null}
          </div>
          <div
            className="expand-layer-container hideOnScreenshot"
            onClick={() => {
              handleAccordianToggle(number);
            }}
          >
            {selected === number ? (
              <Tooltip title="Close" placement="top-end">
                <NewArrow
                  style={{
                    rotate: '90deg',
                    marginTop: '6px',
                  }}
                  className="hideOnScreenshot"
                />
              </Tooltip>
            ) : (
              <Tooltip title="Open" placement="top-end">
                <NewArrow
                  style={{
                    cursor: 'pointer',
                  }}
                  className="hideOnScreenshot"
                />
              </Tooltip>
            )}
          </div>
          <input
            id={name}
            type="checkbox"
            checked={visible}
            onChange={handleLayerVisibility}
            className="featureLayerToggle  hideOnScreenshot"
          />
          <span className="feature-name-span">{name}</span>
          {type === 'point' ? (
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
              }}

            >
              <span
                style={
                  measurementUnit === 'acres'
                    ? { width: '4em', textAlign: 'right' }
                    : { width: '5em', textAlign: 'right' }
                }
                className="measurement-val"
              >
                {data.length}
              </span>
            </span>
          ) : type === 'polygon' ? (
            <Tooltip
              placement="left"
              title={
                measurementUnit === 'acres'
                  ? (Number(featureMeasurement) / CONVERT.acre_sqft).toFixed(5)
                  : featureMeasurement
              }
            >
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                }}
              >
                <span
                  style={
                    measurementUnit === 'acres'
                      ? { width: '4em', textAlign: 'right', marginRight: '5px' }
                      : { width: '5em', textAlign: 'right', marginRight: '5px' }
                  }
                  className="measurement-val"
                  onClick={() => setModalShow(false)}
                >
                  {featureMeasurement
                    ? measurementUnit === 'acres'
                      ? (
                        Number(featureMeasurement) / CONVERT.acre_sqft
                      ).toFixed(2)
                      : Math.floor(featureMeasurement)
                    : '____'}
                </span>
                <span>{measurementUnit === 'acres' ? ' acres' : ' sqft'}</span>
              </span>
            </Tooltip>
          ) : (
            <Tooltip
              title={Number(featureMeasurement).toFixed(2)}
              placement="left"
            >
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                }}
              >
                <span
                  style={
                    measurementUnit === 'acres'
                      ? { width: '4em', textAlign: 'right', marginRight: '5px' }
                      : { width: '5em', textAlign: 'right', marginRight: '5px' }
                  }
                  className="measurement-val"
                >
                  {Math.floor(featureMeasurement)}
                </span>
                <span> ft</span>
              </span>
            </Tooltip>
          )}
          {!isMobile &&
            pathDecider !== '/share/order' &&
            isPreview === false ? (
            <div style={{ display: "flex" }} className='hideOnScreenshot'>
              <Tooltip title="Edit" placement='right'>
                <EditIcon
                  className='studio-action-buttons'
                  onClick={editLayerStyle}
                />
              </Tooltip>

              {/*  to show delete button in both Studio and normal order */}
              {!isStudioOrder || isStudioOrder ?
                <Tooltip title="Delete" placement='right'>
                  <DeleteIcon
                    className='studio-action-buttons'
                    onClick={() => {
                      handleClose();
                      closeDiyTool();
                      if (outerViewName !== 'Default View' ) {
                        deleteLayerRef.current.style.opacity = 1;
                        deleteLayerRef.current.style.pointerEvents = 'auto';
                      } 
                      // Delete functionality active even in default View when user in Studio
                         if (outerViewName === 'Default View' && isStudioOrder) {
                        deleteLayerRef.current.style.opacity = 1;
                        deleteLayerRef.current.style.pointerEvents = 'auto';
                      }
                        // Delete functionality active even in default View when user in Studio 
                      else {
                        deleteLayer();
                      }
                    }}
                  />
                </Tooltip>
                : null}
            </div>
          ) : null}
        </div>

        <div ref={deleteLayerRef} className="no-layers-info-container">
          <div ref={deleteLayerInnerRef} className=" wrapperPopUpBox" style={{display:"block"}}>
            <div className="view-modal-top add-layer-modal-close" style={{margin:"0"}}>
              <span
                onClick={() => {
                  deleteLayerRef.current.style.opacity = 0;
                  deleteLayerRef.current.style.pointerEvents = 'none';
                }}
              >
                 <box-icon name='x'></box-icon>
              </span>
            </div>

        <div className="ImgBoxPopUpBox">
        <box-icon name='error-alt' type='solid' flip='horizontal' animation='tada' color='#edba83' size="lg"></box-icon>
        </div>
            <div className=" Remove_margins_of_p" >
            <p>Confirmation</p>
              <p>Are you sure, Delete this layer?</p>
              {/* <p> Delete this layer? </p> */}
            </div>
            <div className="view-modal-bttn" id="view__model__BTNS">
              <button className="cancel"
                onClick={() => {
                  deleteLayerRef.current.style.opacity = 0;
                  deleteLayerRef.current.style.pointerEvents = 'none';
                }}
              >
                Cancel
              </button>
              <button className="delete" id="DeleteBtn" 
                onClick={() => {
                  deleteLayer(layerId, otterfeatureId);
                  deleteLayerRef.current.style.opacity = 0;
                  deleteLayerRef.current.style.pointerEvents = 'none';
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {isMobile ? (
        <div
          className={`feature-panel-mobile ${selected === number ? 'accordian-active-mobile' : ''
            }`}
        >
          {/* <div className="feature-panel-mobile-header">
            <button
              onClick={() => {
                handleAccordianToggle(number);
              }}
            >
              <ArrowBackIosIcon className="expand-layer-mobile-arrow" />
            </button>
            <h3>{name}</h3>
          </div> */}
          <div className="feature-panel-heading">
            <div>
              <span>ID</span>
              <div
                onClick={() => {
                  setSortIDActive(!sortAreaID);
                  sortLayerFeatureByID();
                }}
              >
                {!sortAreaID ? (
                  <SortIcon className="acc-shorting-icon" />
                ) : (
                  <SortIcon className="dec-shorting-icon" />
                )}
              </div>
            </div>
            <div>
              <span>Measurement</span>
              <div
                onClick={() => {
                  setSortAreaActive(!sortAreaActive);
                  sortLayerFeatureByArea();
                }}
              >
                {!sortAreaActive ? (
                  <SortIcon className="acc-shorting-icon" />
                ) : (
                  <SortIcon className="dec-shorting-icon" />
                )}
              </div>
            </div>
          </div>
          <ul>
            {data.map((item, index) => {
              return (
                <li key={index}>
                  <div className="order-layer-feature-ids">
                    <div className="acc-inner-meas-one">
                      <input
                        type="checkbox"
                        checked={item.visible}
                        id={`${name}__${item.id}`}
                        onChange={handleFeatureVisibility}
                      />
                      <span>ID {item.newId}</span>
                    </div>
                    <div className="acc-inner-meas-two">
                      <div>
                        {type === 'polygon' ? (
                          <span>
                            {measurementUnit === 'acres'
                              ? (item.measurement / CONVERT.acre_sqft).toFixed(
                                2
                              )
                              : item.measurement}
                            {measurementUnit === 'acres' ? ' acres' : ' sqft'}
                          </span>
                        ) : type === 'line' ? (
                          <span>{item.measurement} ft</span>
                        ) : null}
                      </div>
                      <div>
                        <CenterFocusWeakOutlinedIcon
                          className="delete-layer-icon"
                          onClick={() =>
                            handleFeatureZoomIn(item.data[0], type)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="sum-container">
            <div>
              <FunctionsIcon /> Sum*{' '}
            </div>
            {type === 'point' ? (
              <span>{data.length}</span>
            ) : type === 'polygon' ? (
              <span>
                {featureMeasurement
                  ? measurementUnit === 'acres'
                    ? (featureMeasurement / CONVERT.acre_sqft).toFixed(2)
                    : featureMeasurement
                  : '____'}
                {measurementUnit === 'acres' ? ' acres' : ' sqft'}
              </span>
            ) : (
              <span> {featureMeasurement}</span>
            )}
          </div>
          <div className="footer-note">
            {type === 'point'
              ? `*Number of ${name}s`
              : type === 'line'
                ? `*Sum of all lines`
                : `*Sum of all polygons`}
          </div>
        </div>
      ) : (
        <div
          className={`feature-panel ${selected === number ? 'accordian-active' : ''
            }`}
        >
          <div className="feature-panel-heading">
            <div className="feature-panel-heading-left">
              <span>ID</span>
              <div
                onClick={() => {
                  setSortIDActive(!sortAreaID);
                  sortLayerFeatureByID();
                }}
              >
                {!sortAreaID ? (
                  <SortIcon className="acc-shorting-icon" />
                ) : (
                  <SortIcon className="dec-shorting-icon" />
                )}
              </div>
            </div>
            <div className="feature-panel-heading-right">
              <span>Measurement</span>
              <div
                onClick={() => {
                  setSortAreaActive(!sortAreaActive);
                  sortLayerFeatureByArea();
                }}
              >
                {!sortAreaActive ? (
                  <SortIcon className="acc-shorting-icon" />
                ) : (
                  <SortIcon className="dec-shorting-icon" />
                )}
              </div>
            </div>
          </div>
          <ul>
            {data.map((item, index) => {
              return (
                <li key={index}>
                  <div
                    onMouseOver={(e) =>
                      handleMouseOver(item.newId, item.visible)
                    }
                    onMouseOut={(e) => handleMouseOut(item.newId, item.visible)}
                    id={index + 1}
                    className="order-layer-feature-ids"
                  >
                    <div className="acc-inner-meas-one">
                      <input
                        type="checkbox"
                        checked={item.visible}
                        id={`${name}__${item.id}`}
                        onChange={handleFeatureVisibility}
                      />
                      <span>ID {item.newId}</span>
                    </div>
                    <div className="acc-inner-meas-two">
                      <div>
                        {type === 'polygon' ? (
                          <span>
                            {measurementUnit === 'acres'
                              ? (item.measurement / CONVERT.acre_sqft).toFixed(
                                2
                              )
                              : item.measurement}
                            {measurementUnit === 'acres' ? ' acres' : ' sqft'}
                          </span>
                        ) : type === 'line' ? (
                          <span>{item.measurement} ft</span>
                        ) : null}
                      </div>
                      <div>
                        <CenterFocusWeakOutlinedIcon
                          className="delete-layer-icon"
                          onClick={() =>
                            handleFeatureZoomIn(item.data[0], type)
                          }
                        />
                        {pathDecider !== '/share/order' &&
                          isPreview === false ? (
                          <DeleteIcon
                            className="delete-layer-icon"
                            onClick={() => deleteLayerFeature(item.id)}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="sum-container">
            <div>
              <FunctionsIcon /> Sum*{' '}
            </div>
            {type === 'point' ? (
              <span>{data.length}</span>
            ) : type === 'polygon' ? (
              <span>
                {featureMeasurement
                  ? measurementUnit === 'acres'
                    ? (featureMeasurement / CONVERT.acre_sqft).toFixed(2)
                    : featureMeasurement
                  : '____'}
                {measurementUnit === 'acres' ? ' acres' : ' sqft'}
              </span>
            ) : (
              <span> {featureMeasurement}</span>
            )}
          </div>
          <div className="footer-note">
            {type === 'point'
              ? `*Number of ${name}s`
              : type === 'line'
                ? `*Sum of all lines`
                : `*Sum of all polygons`}
          </div>
        </div>
      )}

      {/* To enable feature of MOVE and Delete at  mouse right click on parcel  */}
       {/* { isStudioOrder? ( */}
      {/* <div id="feature-menu-cont" className="feature-menu">
            <div className="feature-inner-cont">
              <div
                onClick={OpenMoveFeatureBar}
                style={{ borderBottom: '1px solid rgb(234, 227, 227)' }}
              >
                <MoveIcon className="feature-menu-icon" />
                <span>Move</span>
                <MenuOpenRightIcon
                  style={{ margin: '0 0 0 1em' }}
                  className="feature-menu-icon"
                />
              </div>
              <div
                onClick={() =>
                  deleteLayerFeature(moveFeatureData[0], moveFeatureData[1])
                }
              >
                <DeleteIcon className="feature-menu-icon" />
                <span>Delete</span>
              </div>
            </div>
            {showMoveFeatureList ? (
              <div id="feature-list-cont" className="feature-list-cont">
                <div className="feature-list-inner-cont">
                  {moveFeatureData.length
                    ? uploadedLayer.map((item, index) => {
                        if (
                          item.id !== parseInt(moveFeatureData[1]) &&
                          item.type === moveFeatureData[2]
                        )
                          return (
                            <div
                              key={index}
                              id={item.id}
                              onClick={() =>
                                MoveFeature(
                                  id,
                                  map,
                                  viewId,
                                  item.id,
                                  moveFeatureData[0],
                                  moveFeatureData[1],
                                  handleSuccess,
                                  handleError,
                                  uploadedLayer,
                                  setUploadedLayer,
                                  setMultipleContextInteraction,
                                  setMultipleSelectInteraction,
                                  isContextInteraction
                                )
                              }
                            >
                              {item.name}
                            </div>
                          );
                      })
                    : multipleMoveFeatureData.length
                    ? uploadedLayer.map((item, index) => {
                        if (
                          item.id !== multipleMoveFeatureData[1] &&
                          item.type === multipleMoveFeatureData[2]
                        )
                          return (
                            <div
                              key={index}
                              id={item.id}
                              onClick={() =>
                                MoveFeature(
                                  id,
                                  map,
                                  viewId,
                                  item.id,
                                  multipleMoveFeatureData[0],
                                  multipleMoveFeatureData[1],
                                  handleSuccess,
                                  handleError,
                                  uploadedLayer,
                                  setUploadedLayer,
                                  setMultipleContextInteraction,
                                  setMultipleSelectInteraction,
                                  isContextInteraction
                                )
                              }
                            >
                              {item.name}
                            </div>
                          );
                      })
                    : null}
                </div>
              </div>
            ) : null}
      </div>):null */}
       {/* } */}
      {/* To enable feature of MOVE and Delete at  mouse right click on parcel End  */}

    </div>
  );
}
