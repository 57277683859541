import axios from 'axios';
import React from 'react';
import './LandingPage.css';
import { useState } from 'react';
import { useEffect } from 'react';
import '@reach/combobox/styles.css';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import AuthToken from '../../context/AuthToken';
import HouseIcon from '@mui/icons-material/House';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { useLoader } from '../../context/LoaderProvider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { grey } from '@mui/material/colors'
import Button from '@mui/material/Button';
import { ALL_PROPERTIES_URL } from '../../helper/ApiUrl';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import AddressSearch from '../../components/AddressSearch/AddressSearch';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useMediaQuery from '@mui/material/useMediaQuery';

const LandingPage = ({ handleError, handleSuccess }) => {
  const token = AuthToken();
  const [completedProp, setCompletedProp] = useState([]);
  const [draft, setDraft] = useState([]);
  const { startLoader, stopLoader } = useLoader();
  const isLargerThan680px = useMediaQuery('(min-width:680px)');

  const getCompletedProp = () => {
    startLoader();
    axios
      .get(`${ALL_PROPERTIES_URL}&status=draft,measurement_completed`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setCompletedProp(response.data.data);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const getInProgressProp = () => {
    axios
      .get(`${ALL_PROPERTIES_URL}&status=draft`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setDraft(response.data.data);
        stopLoader();
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  useEffect(() => {
    getCompletedProp();
    getInProgressProp();
  }, []);


  return (
    <div className="landing-main-container">
      <div className='landing-container-wrapper'>

      {isLargerThan680px && <div className="landing-left-container">
        <div className="landing-left-top">
          <div className="recent-properties-bar ">
            <h3>Recent Properties</h3>
           {  completedProp.length > 3 &&  <div>
              <a href="/dashboard">
                <h4>View all</h4>
              </a>
              <KeyboardArrowRightOutlinedIcon className="view-all-icon" />
            </div>}
          </div>
          <div className="left-top-grid">
         
            { completedProp?.map((item, index) => {
                return (
                  <div className='landing-card' style={{height:`${completedProp.length<3 ? "60% " :"100%" } `}}> 
                  <a
                    key={index}
                    target="_blank"
                    href={`/order/${item.order_id}`}
                    
                  >
                    <div
                      className="landing-card-inner"
                      key={item.order_id}
                      id={item.order_id}
                      
                    >
                      <Tooltip title={item.address}>
                      <div className="landing-card-first-box" >
                        <HouseIcon className="house-icon" sx={{fontSize: "clamp(20px, 4vw, 0.90vw)"}} />
                        <p>{item.address}</p>
                      </div>
                      </Tooltip>
                      <div className="landing-card-second-box" style={{marginTop:"0.8rem "}}>
                        <div className="card-details">
                          <div className='' style={{display:"flex", gap:"0.4rem"}}>
                          <ArchitectureIcon className="inner-icon" />
                          <p style={{fontWeight: "600", alignSelf: "center"}}>Area size:</p>
                          </div>
                          <p >{item.area} acre</p>
                        </div>
                        <div className="card-details">
                        <div style={{display:"flex" ,gap:"0.4rem"}}>
                        <AccessTimeIcon className="inner-icon" />
                        <p style={{fontWeight: "600" , alignSelf: "center"}}>Created At:</p>
                        </div>
                          <p >{item.created_at.slice(0, 10)}</p>
                        </div>
                      </div>
                    </div>
                  </a>
                  </div>
                );
              })}

          </div>
        </div>
        <div className="landing-left-bottom">
          <div className="unfinished-projects-bar">
            <h3>Unfinished Project</h3>
            { draft.length > 3 &&  <div>
              <a href="/dashboard">
                <h4>View all</h4>
              </a>
              <KeyboardArrowRightOutlinedIcon className="view-all-icon" />
            </div>}
          </div>
          <div className="landing-projects-list" 
           style={{justifyContent:`${ draft.length < 4 ? "flex-start":"space-evenly"}`}}
          >
            { draft?.map((item, index) => {
                return (
                  <div
                    className="landing-projects-item"
                    key={item.order_id}
                    id={item.order_id}
                  >
                    <div style={{display: "flex", gap: "10px", width: "100%", alignItems: "center", overflow: "hidden"}}>
                    <FmdGoodIcon className="landing-projects-list-icon" />
                    <Tooltip title={item.address}>
                      <p>{item.address}</p>
                    </Tooltip>
                    </div>
                    <a target="_blank" href={`/order/${item.order_id}`}>
                      <Button>Preview</Button>
                    </a>
                  </div>
                );
              })}
          </div>
        </div>
      </div>}

      {!isLargerThan680px && <Box>
        <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <Box sx={{display: "flex", gap: "3%" , width: "100%", alignItems: "center"}}>
            <Typography sx={{fontWeight: "700", color: "#6D6E70"}} >Recent Properties</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
      {  completedProp.length > 3 && <Box sx={{display: "flex" , width: "100%", justifyContent: "flex-end", alignItems: "center" }}>
              <Link to="/dashboard">
                <Typography sx={{fontSize:  "13px"}}>View all</Typography>
              </Link>
              <KeyboardArrowRightOutlinedIcon className="view-all-icon" />
        </Box>}
        <div className="left-top-grid">
         
         { completedProp?.map((item, index) => {
             return (
               <div className='landing-card'> 
               <a
                 key={index}
                 target="_blank"
                 href={`/order/${item.order_id}`}
               >
                 <div
                   className="landing-card-inner"
                   key={item.order_id}
                   id={item.order_id}
                 >
                   <div className="landing-card-first-box">
                     <HouseIcon className="house-icon" />
                     <p>{item.address}</p>
                   </div>
                   <div className="landing-card-second-box">
                     <div className="card-details">
                       <div style={{display:"flex", alignItems: "center", gap:"0.4rem"}}>
                       <ArchitectureIcon className="inner-icon" />
                       <p style={{fontWeight: "600"}}>Area size:</p>
                       </div>
                       <p >{item.area} acre</p>
                     </div>
                     <div className="card-details">
                       <div style={{display:"flex", alignItems: "center",  gap:"0.4rem"}}>
                       <AccessTimeIcon className="inner-icon" />
                       <p  style={{fontWeight: "600"}}>Created At:</p>
                       </div>  
                       <p >{item.created_at.slice(0, 10)}</p>
                     </div>
                   </div>
                 </div>
               </a>
               </div>
             );
           })}

        </div>
        </AccordionDetails>
        </Accordion>
        <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <Box sx={{display: "flex", gap: "3%", width: "100%", alignItems: "center"}}>
            <Typography sx={{fontWeight: "700", color: "#6D6E70"}} >Unfinished Project</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
        { draft.length > 3 && <Box  sx={{display: "flex", width: "100%", justifyContent: "flex-end", alignItems: "center"}}>
              <Link to="/dashboard">
                <Typography sx={{fontSize:  "13px"}}>View all</Typography>
              </Link>
              <KeyboardArrowRightOutlinedIcon className="view-all-icon" />
        </Box>}
        <div className="landing-projects-list">
            { draft?.map((item, index) => {
                return (
                  <div
                    className="landing-projects-item"
                    key={item.order_id}
                    id={item.order_id}
                  >
                    <div style={{display: "flex", gap: "10px", width: "100%", alignItems: "center", overflow: "hidden"}}>
                    <FmdGoodIcon className="landing-projects-list-icon" />
                    <Tooltip title={item.address}>
                      <p>{item.address}</p>
                    </Tooltip>
                    </div>
                    <a target="_blank" href={`/order/${item.order_id}`}>
                      <Button>Preview</Button>
                    </a>
                  </div>
                );
              })}
          </div>
        </AccordionDetails>
        </Accordion>
      </Box>
      }

      <div className="landing-right-container">
        <div className="landing-search-bar">
          <AddressSearch
            handleError={handleError}
            handleSuccess={handleSuccess}
          />
        </div>
      </div>
      </div>
    </div>
  );
};

export default LandingPage;
