import { Extension } from '@tiptap/core';

const TextColor = Extension.create({
  name: 'textColor',

  addOptions() {
    return {
      types: ['textStyle'],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          color: {
            default: null,
            parseHTML: element => element.style.color.replace(/['"]+/g, ''),
            renderHTML: attributes => {
              if (!attributes.color) {
                return {};
              }
              return { style: `color: ${attributes.color}` };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setColor: color => ({ chain }) => {
        return chain().setMark('textStyle', { color }).run();
      },
      unsetColor: () => ({ chain }) => {
        return chain().setMark('textStyle', { color: null }).run();
      },
    };
  },
});

export default TextColor;
