import axios from 'axios';
import AuthToken from '../../context/AuthToken';
import { ORDER_URL } from '../../helper/ApiUrl';
import { encodeJwtToken } from '../../utils/EncodeJwt';

const saveAllLayers = (id, viewId, layerId, layerData, undoStack, redoStack, setSaveUndoStack, setSaveRedoStack) => {
  const token = AuthToken();
  axios
    .put(`${ORDER_URL}${id}/view/${viewId}/layer/${layerId}`, layerData, {
      headers: {
        Authorization: `Token ${token}`,
        // "x-api-key":`${encodeJwtToken()}`,
        Accept: 'application/json',
      },
    })
    .then((response) => {
      setSaveUndoStack(undoStack[undoStack.length - 1]);
      setSaveRedoStack(redoStack[redoStack.length - 1]);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default saveAllLayers;
