import React, { useState, useEffect } from "react";
import axios from "axios";
import './MyDocuments.css';
import './FolderFile.css';
import { FILE_UPLOAD,MOLE_ORDER_FILE_FOLDER } from '../../helper/ApiUrl';
import AuthToken from '../../context/AuthToken';
import Imageview from "./Imageview";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    styled,
} from '@mui/material';
import DocumentContainer from "../../components/DocumentContainer/DocumentContainer";
import { useNavigate } from "react-router-dom";
import ToggleButtons from "./ToggleButton";

import OttermapLoaderGif from '../../assets/ottermap-loading-gif.gif';


import useMediaQuery from '@mui/material/useMediaQuery';


const StyledFormControl = styled(FormControl)(({ theme }) => ({
    minWidth: 200,
    maxHeight: '300px',
    margin: theme.spacing(2),
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    display: 'flex',
    minHeight: '48px', // Adjust the height as needed
    alignItems: 'center',
}));

const MyDocumentsComponent = () => {
    const isSmallerThan820px = useMediaQuery('(max-width:820px)');
    const [documents, setDocuments] = useState([]);
    const [layout, setLayout] = useState('list');
    const [selectedImage, setSelectedImage] = useState(null);
    const [query, setQuery] = useState('');
    const [selectedFileType, setSelectedFileType] = useState('');
    const token = AuthToken();

    const fileTypes = [
        'PDF', 'DOC', 'DOCX', 'XLS', 'XLSX', 'PPT', 'PPTX', 'TXT', 'CSV',
        'JPEG', 'JPG', 'PNG', 'GIF',
    ];

    const filteredDocuments = documents?.filter(doc => {
        const matchesQuery = doc.file_name.toLowerCase().includes(query.toLowerCase());
        const matchesFileType = selectedFileType ? doc.file_name.toLowerCase().endsWith(selectedFileType.toLowerCase()) : true;
        return matchesQuery && matchesFileType;
    });

    useEffect(() => {
        getMyDocuments();
    }, []);

    const getMyDocuments = () => {
        axios
            .get(`${FILE_UPLOAD}`, {
                headers: {
                    Authorization: `Token ${token}`,
                    Accept: 'application/json',
                },
            })
            .then((response) => {
                setDocuments(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching documents', error);
            });
    };

    const handleDelete = async (filename) => {
        console.log("delete clicked") 
        const filenameArr = []
        filenameArr.push(filename)
     
         try {
           if(filename){
             console.log(filename,"selectedImageName")
           await axios.delete(`${FILE_UPLOAD}`, {
             headers: {
               Authorization: `Token ${token}`,
             },
             data: { 
              
               file_keys:filenameArr 
              },
           });
           
           getMyDocuments()
         }
         } catch (error) {
           console.error("Error deleting files:", error);
         }
       };

    const handleDownloadImage = (selectedImg) => {
        const link = document.createElement('a');
        link.href = selectedImg;
        link.download = selectedImg.split('/').pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleFileTypeChange = (event) => {
        setSelectedFileType(event.target.value);
    };

    const handleClearFilter = () => {
        setSelectedFileType('');
    };

    const [activeTab, setActiveTab] = useState('bulkImages');

    const navigate = useNavigate()

    return (
        <div className="mydocscomponent_container">
                   {/* 10Aug for routing */}
                   
         <ToggleButtons />
 
          {/* 10Aug for routing */}

            {/* Header section with search and filter options */}

            {!isSmallerThan820px && <div className='toggle_btn_div' style={{ display: "grid", justifyContent: "space-between", alignItems: "center", gridTemplateColumns:"70% auto"}}>
                {/* <div className="documents-filter-container"> */}

                <div className="" style={{border:"" ,display:"flex",alignItems:"center",justifyContent:"space-between", height:"fit-content"}} >

                    <div className="" style={{border:"1px solid #519259",overflow:"hidden",borderRadius:"5px", display:"grid",gridTemplateColumns:"85% auto"}}>
                        <input
                            type="text"
                            placeholder="Search by Name"
                            onChange={(e) => setQuery(e.target.value)}
                            style={{ width: "100%", border:'none', padding: "0.5rem" }}
                        />
                        <div
                      style={{background:"#519259",height:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}
                     ><box-icon name='search' color='#ffffff' ></box-icon></div>
                    </div>

                    {selectedFileType && (
                        <button onClick={handleClearFilter} className="documents-filter-clear" style={{width:"fit-content"}} >
                            <FilterListOffIcon className="clear-filter-icon" />
                            Clear
                        </button>
                    )}

                    <div className="documents-filter">
                        <StyledFormControl>
                            <InputLabel id="file-type-select-label" style={{top:"-5px",fontSize:"0.8rem"}}>File Type</InputLabel>
                            <Select
                                labelId="file-type-select-label"
                                id="file-type-select"
                                value={selectedFileType}
                                onChange={handleFileTypeChange}
                                style={{marginBottom:"0"}}
                            >
                                {fileTypes.map((fileType) => (
                                    <StyledMenuItem key={fileType} value={fileType}>
                                        {fileType}
                                    </StyledMenuItem>
                                ))}
                            </Select>
                        </StyledFormControl>
                    </div>
                </div>

                <div className="toggle_layout_btn_container" style={{ display: "flex", overflow: "hidden" }}>
                    <button
                        style={{ backgroundColor: layout === 'list' ? '#519259' : 'grey', color: layout === 'list' ? 'white' : 'black' }}
                        onClick={() => setLayout('list')}
                    >
                        <box-icon name='list-ul' color='#ffffff'></box-icon>
                    </button>
                    <button
                        style={{ backgroundColor: layout === 'grid' ? '#519259' : 'grey', color: layout === 'grid' ? 'white' : 'black' }}
                        onClick={() => setLayout('grid')}
                    >
                        <box-icon name='grid-horizontal' color='#ffffff'></box-icon>
                    </button>
                </div>
            </div>}

            {isSmallerThan820px && <div className='toggle_btn_div' style={{ display: "flex", flexDirection: "column", justifyContent: "", alignItems: "center", width: "100%"}}>
                {/* <div className="documents-filter-container"> */}

                <div style={{ display: "grid", gap: "10px", justifyContent: "space-evenly", alignItems: "center", gridTemplateColumns:"75% auto", width: "100%"}}>
                <div className="" style={{border:"1px solid #519259",overflow:"hidden",borderRadius:"5px", width :"100%", display:"grid",gridTemplateColumns:"85% auto"}}>
                        <input
                            type="text"
                            placeholder="Search by Name"
                            onChange={(e) => setQuery(e.target.value)}
                            style={{ width: "100%", border:'none', padding: "0.5rem" }}
                        />
                        <div
                      style={{background:"#519259",height:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}
                     ><box-icon name='search' color='#ffffff' ></box-icon></div>
                </div>
                <div className="toggle_layout_btn_container" style={{ display: "flex", overflow: "hidden", width: "100%", gap: "0px", height: "100%", borderRadius: "5px" }}>
                    <button
                        style={{ backgroundColor: layout === 'list' ? '#519259' : 'grey', color: layout === 'list' ? 'white' : 'black', margin: "0px" }}
                        onClick={() => setLayout('list')}
                    >
                        <box-icon name='list-ul' color='#ffffff'></box-icon>
                    </button>
                    <button
                        style={{ backgroundColor: layout === 'grid' ? '#519259' : 'grey', color: layout === 'grid' ? 'white' : 'black', margin: "0px" }}
                        onClick={() => setLayout('grid')}
                    >
                        <box-icon name='grid-horizontal' color='#ffffff'></box-icon>
                    </button>
                </div>
                </div>
                <div className="" style={{width:"100%" ,display:"flex",alignItems:"center",justifyContent:"flex-end", height:"fit-content", padding: "15px 10px 0px 5px"}} >

                    {selectedFileType && (
                        <button onClick={handleClearFilter} className="documents-filter-clear" style={{width:"fit-content", marginRight: "10px"}} >
                            <FilterListOffIcon className="clear-filter-icon" />
                            Clear
                        </button>
                    )}

                    <div className="documents-filter" >
                        <StyledFormControl sx={{margin: "0px !important"}}>
                            <InputLabel id="file-type-select-label" style={{top:"-5px",fontSize:"0.8rem"}}>File Type</InputLabel>
                            <Select
                                labelId="file-type-select-label"
                                id="file-type-select"
                                value={selectedFileType}
                                onChange={handleFileTypeChange}
                                style={{marginBottom:"0"}}
                            >
                                {fileTypes.map((fileType) => (
                                    <StyledMenuItem key={fileType} value={fileType}>
                                        {fileType}
                                    </StyledMenuItem>
                                ))}
                            </Select>
                        </StyledFormControl>
                    </div>
                </div>

            </div>}

            {/* Document display section */}
{/* otter-245 */}
            <div className={`documents-${layout}-layout documents__container`}>
                {  documents.length===0 &&filteredDocuments.length === 0 ? (
            <div className="alternate-table-container Loaders-Div">
            { filteredDocuments.length === 0?<img src={OttermapLoaderGif} alt="Ottermap Loader Gif" /> :<h1>Loading...</h1>}
{/* otter-245 */}
{/* otter-studio-responsive */}
         {/* {!isSmallerThan820px && <div className={`documents-${layout}-layout documents__container`}>
        {filteredDocuments.length === 0 ? (
                    

        <div className="alternate-table-container">
            <h1 >Loading...</h1> */}
{/* otter-studio-responsive */}
          </div>
        ) :  documents.length>0 && filteredDocuments.length === 0 ? (
          <div className="alternate-table-container Loaders-Div">
            <h1>No file found</h1>
          </div>
        )
        : filteredDocuments.map((doc) => (
               <div
                        key={doc.id}
                        className={layout === 'grid' ? 'card' : 'list-item'}
                        // onClick={() => setSelectedImage(doc.file_url)}
                    >
                        {layout === 'grid' ? (
                            <>
                                <div className="grid_img_box"  onClick={() => setSelectedImage(doc.file_url)}>
                                    <img src={doc.file_url} alt={doc.file_name} />
                                </div>
                                <div className="file__name_title">
                                    <h4>{doc.file_name}</h4>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="namediv" onClick={() => setSelectedImage(doc.file_url)}>{doc.file_name}</div>
                                <div className="downloaddiv checkboxdiv" title="Download" onClick={() => handleDownloadImage(doc.file_url)}>
                                    <box-icon type='solid' name='download'></box-icon>
                                </div>
                                <div className="previewdiv checkboxdiv" title="Preview" onClick={() => setSelectedImage(doc.file_url)}>
                                    <box-icon name='show-alt'></box-icon>
                                </div>
                                {/* <div className="previewdiv checkboxdiv" title="Delete" onClick={() =>{
                                  handleDelete(doc.file_name)
                                } }>
                              <box-icon type='solid' name='trash-alt'></box-icon>
                              </div> */}
                            </>
                        )}
                    </div>
                ))}
          </div>

        {isSmallerThan820px && <div className="documents__container"  style={{padding: "5px", display: "flex", flexDirection: "column", gap: "10px", paddingBottom: "150px"}}>
        {filteredDocuments.length === 0 ? (
        <div className="alternate-table-container">
            {/* <h1 >Loading...</h1> */}
          </div>
        ) : filteredDocuments.length < 1 ? (
          <div className="alternate-table-container">
            {/* <h1>No documents found :</h1> */}
          </div>
        )
        : filteredDocuments.map((doc) => (
                    <div
                        key={doc.id}
                        // onClick={() => setSelectedImage(doc.file_url)}
                    >
                        {layout === 'grid' ? (
                            <div style={{display: "flex", flexDirection :"column", gap: "0px", backgroundColor: "#519259"}}>
                                <div className="grid_img_box" onClick={() => setSelectedImage(doc.file_url)}>
                                    <img src={doc.file_url} alt={doc.file_name}   />
                                </div>
                                <div className="file__name_title">
                                    <h4>{doc.file_name}</h4>
                                </div>
                            </div>
                        ) : (
                            <div style={{padding: "10px 0px", height: "40px", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div className="namediv"  onClick={() => setSelectedImage(doc.file_url)}>{doc.file_name}</div>
                                <div className="downloaddiv checkboxdiv" title="Download" onClick={() => handleDownloadImage(doc.file_url)}>
                                    <box-icon type='solid' name='download'></box-icon>
                                </div>
                                <div className="previewdiv checkboxdiv" title="Preview" onClick={() => setSelectedImage(doc.file_url)}>
                                    <box-icon name='show-alt'></box-icon>
                                </div>
                                {/* <div className="previewdiv checkboxdiv" title="Delete" onClick={() =>{
                                  handleDelete(doc.file_name)
                                } }>
                              <box-icon type='solid' name='trash-alt'></box-icon>
                              </div> */}
                            </div>
                        )}
                    </div>
                ))}
          </div>}

            {/* Image view modal */}
            {selectedImage && (
              
                <Imageview
                    imageSrc={selectedImage}
                    onClose={() => setSelectedImage(null)}
                    onDownload={() => handleDownloadImage(selectedImage)}
                />
            )}
        </div>
    );
};

export default MyDocumentsComponent;
