import "./ExplorePolygonCheckbox.css";
import { useLocation } from "react-router-dom";
import React, { useRef, useEffect, useState} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import removeAllInteraction from "../../OpenLayers/OLFeatures/RemoveInteraction";
import DeleteWarningModal from './../DeleteWarningModal/DeleteWarningModal.jsx';
import ReportIcon from '@mui/icons-material/Report';
import CropPortraitIcon from '@mui/icons-material/CropPortrait';

export default function PolygonCheckBox({
  id,
  map,
  path,
  data,
  index,
  setPath,
  setHoverParcelId,
  setHoverParcelElement,

  // 30-5
  polygonList,
  setPolygonList,
  reActivateFunctionality,
  //07-6
  isDrawActive,
  isEditActive,
  isCutActive,
  setIsDrawActive,
  setIsEditActive,
  setIsCutActive,
}) {

  const innerRef = useRef();
  const outerRef = useRef();
  const location = useLocation();
  const pathName = location.pathname;

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  // console.log(  isDrawActive, "isDrawActive", isEditActive, "isEditActive", isCutActive, "isCutActive");
const functionalUpdatePolygonList = (polygonLISTS)=>{
  // setPolygonList(polygonLISTS)
  setPolygonList(polygonLISTS);
}
const functionalUpdatePath = (updateddPath)=>{
  // setPolygonList(polygonLISTS)
  setPath(updateddPath);
}
const DeactivateAllActive=()=>{
    setIsDrawActive(false);
    setIsEditActive(false);
    setIsCutActive(false);
}
const callreactive = ()=>{
  reActivateFunctionality();
}
  const removeParcel = () => {
    DeactivateAllActive()
    const updatedPath = path.filter((prop) => prop.id !== data.id);

    // console.log(updatedPath,"updatedPath")
    // console.log(path," delete me path before setpath")
    functionalUpdatePath(updatedPath)
    // setPath(updatedPath);
    polygonList = [...updatedPath];
    // console.log(polygonList, "polygonList inside the removePArcel"); //25june
    //  setPolygonList([...polygonList]);
    // setPolygonList(polygonList);
    functionalUpdatePolygonList(polygonList)
    //  setPolygonList(updatedPath);
    console.log(polygonList, "polygonList", updatedPath, "updatedPath");

    // setPolygonList(updatedPath);
    // setPolygonList([...polygonList]);// Ensure the updated path is used
    // setPolygonList(updatedPath);// Ensure the updated path is used

    // console.log(path," delete me Path after setPath")
    // console.log(path," afdffdsfdsfdsfdfd")

    // june 07 2024 Firsrtly disable all the active functionality
    // console.log(isDrawActive, isEditActive, isCutActive, "console in removeParcel")
    // setIsDrawActive(false);
    // setIsEditActive(false);
    // setIsCutActive(false);
    // DeactivateAllActive();
    // june 07 2024 Firsrtly disable all the active functionality

    removeAllInteraction(map);
    // reActivateFunctionality(); 
    // callreactive(); 
    reActivateFunctionality && reActivateFunctionality(polygonList);  
  };

      // useEffect(() => {
        
      // }, [isDrawActive, isEditActive, isCutActive]);

  const handleMouseOver = (id) => {
    setHoverParcelId(id);
    setHoverParcelElement("mouseover");
  };

  const handleMouseOut = (id) => {
    setHoverParcelId(id);
    setHoverParcelElement("mouseout");
  };

  const handleClickOutSide = (e) => {
    if (innerRef.current) {
      if (!innerRef.current.contains(e.target)) {
        outerRef.current.style.opacity = 0;
        outerRef.current.style.pointerEvents = "none";
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutSide, true);
  }, []);

  return (
    <div
      id={id}
      className="explore-parcel-info-container"
      onMouseOut={(e) => handleMouseOut(id)}
      onMouseOver={(e) => handleMouseOver(id)}
      style={{borderBottom: path?.length -1 !== index && "1px solid #b0bec5", margin: "0px", paddingLeft: "0px"}}
    >
      <CropPortraitIcon  className="deleteIcon"  sx={{color: "red", marginRight: "5px"}} />
      <label>Parcel {++index} area: </label>
      <div className="parcel-area"> {data.measurement} acre</div>
      <DeleteIcon
        className="deleteIcon"
        onClick={() => {
          setDeleteModalOpen(true)
          // outerRef.current.style.opacity = 1;
          // outerRef.current.style.pointerEvents = "auto";
        }}
      />
      <div ref={outerRef} className="review-and-submit-warning-outer">
        <div
          ref={innerRef}
          className="review-and-submit-warning-inner"
          style={{ height: "17%" }}
        >
          <div className="raise-feedback-inner-top">
            <span
              onClick={() => {
                outerRef.current.style.opacity = 0;
                outerRef.current.style.pointerEvents = "none";
              }}
            >
              X
            </span>
          </div>
          <div className="review-and-submit-warning-inner-mid">
            <p
              style={
                pathName !== "/diy/order"
                  ? {  margin: "10px 0px" }
                  : undefined
              }
            >
              Are you sure you want to delete this parcel?{" "}
            </p>
          </div>
          <div className="view-modal-bttns">
            <button
              onClick={() => {
                outerRef.current.style.opacity = 0;
                outerRef.current.style.pointerEvents = "none";
              }}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                removeParcel();
                outerRef.current.style.opacity = 0;
                outerRef.current.style.pointerEvents = "none";
              }}
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
      <DeleteWarningModal icon={<ReportIcon className="property-icon" sx={{ color: "#054635"}} />} cancelText="Cancel" submitText="Delete" submit={() => removeParcel()} title="Confirmation" description="Are you sure you want to delete this parcel?" setOpen={setDeleteModalOpen} open={deleteModalOpen}/>
    </div>
  );
}
