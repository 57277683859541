import Map from "ol/Map";
import axios from "axios";
import View from "ol/View";
import { Feature } from "ol";
import "./Explore.css";
import XYZ from "ol/source/XYZ";
import { Style } from "ol/style";
import Icon from "ol/style/Icon";
import Point from "ol/geom/Point";
import { toLonLat } from "ol/proj";
import { transform } from "ol/proj";
import TileLayer from "ol/layer/Tile";
import LayerGroup from "ol/layer/Group";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { useNavigate, useParams } from "react-router-dom";
import { defaults } from "ol/control/defaults";
import AuthToken from "../../context/AuthToken";
import "../OpenLayersIdPage/OpenLayersIdPage.css";
import markerIcon from "../../assets/location.svg";
import CreateIcon from "@mui/icons-material/Create";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { useLoader } from "../../context/LoaderProvider";
// import { useScreenType } from "../../utils/commonMethods";
import { CREATE_PROPERTY_URL } from "../../helper/ApiUrl";
import MobileOpenLayersPage from "./MobileExplore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useEffect, useRef, useState } from "react";
import usePlacesAutocomplete from "use-places-autocomplete";
import NavigationIcon from "@mui/icons-material/Navigation";
import rotateMap from "../../OpenLayers/OLControls/RotateMap";
import ExploreProgressBar from "./../../components/ExploreProgressBar/ExploreProgressBar.jsx";

import { ReactComponent as CheckIcon } from "../../assets/Check.svg";
import Rotate90DegreesCwIcon from "@mui/icons-material/Rotate90DegreesCw";
import AlternateOrderPage from "../AlternateOrderPage/AlternateOrderPage";
import resetRotation from "../../OpenLayers/OLControls/MapRotationControl";
import { ReactComponent as PropertyIcon } from "../../assets/Property.svg";
import Rotate90DegreesCcwIcon from "@mui/icons-material/Rotate90DegreesCcw";
import LayersTypeContainer from "../../components/LayersTypeContainer/LayersTypeContainer";
import Box from "@mui/material/Box";
import PublicAddressSearch from "./../../components/PublicAddressSearch/PublicAddressSearch.jsx";
import { usePublicAddressContext } from "./../../context/PublicAddressContext.jsx";
import { Select, Modify } from "ol/interaction";
import CustomModal from './../../components/CustomModal/CustomModal.jsx';
import {
  DIY_PARCEL_URL,
  DIY_NEARMAP_DATE,
  REMOVE_HOLES_URL,
} from "../../helper/ApiUrl";
import "./Explore.css";
import { Fill, Stroke } from "ol/style";
import { TOAST_TYPE, STATUS } from "../../utils/constants";
import { altShiftKeysOnly } from "ol/events/condition";
import setNearmap from "../../OpenLayers/OLFeatures/SetNearmap";
import selectedStyle from "../../OpenLayers/OLStyles/EditStyle";
import ExploreLeftBar from "../../components/ExploreLeftBar/ExploreLeftBar";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import renderParcel from "../../OpenLayers/OLFeatures/RenderParcel";
import handleZoomIn from "../../OpenLayers/OLControls/ZoomInControl";
import hoverOnParcel from "../../OpenLayers/OLFeatures/HoverOnParcel";
import calculateArea from "../../OpenLayers/OLFeatures/CalculateArea";
import handleZoomOut from "../../OpenLayers/OLControls/ZoomOutControl";
// import initializeMap from '../../OpenLayers/DIYFeatures/InitializeDiyMap';
import initializeMarker from "../../OpenLayers/OLFeatures/InitializeMarker";
import handleLayerTypeMap from "../../OpenLayers/OLControls/MapViewControl";
import ImageryDropdown from "../../components/ImageryDropdown/ImageryDropdown";
import removeAllInteraction from "../../OpenLayers/OLFeatures/RemoveInteraction";
import handleLayerTypeSatellite from "../../OpenLayers/OLControls/SatelliteViewControl";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import ExploreFeaturesModal from "./../../components/ExploreFeaturesModal/ExploreFeaturesModal.jsx";
import ExploreplaceOrderForm from "./../../components/ExploreplaceOrderForm/ExplorePlaceOrderForm.jsx";
// import getNearmapImage from '../../OpenLayers/OLFeatures/GetNearmapImage';
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import Step from "@mui/material/Step";
import { Global } from "@emotion/react";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import { Typography } from "@mui/material";
import { Textarea } from "@mui/joy";
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import {EXPLORE_PLACE_ORDER_URL, EXPLORE_FEATURES_LIST_URL} from './../../helper/ApiUrl.js';
import CottageIcon from '@mui/icons-material/Cottage';
import { encodeJwtToken } from "../../utils/EncodeJwt.js";


const OpenLayersPage = ({ handleError, handleSuccess }) => {
  const { id } = useParams();
  const markerRef = useRef();
  const nearmapImageRef = useRef();
  const [direction, setDirection] = useState();
  const isLargerThan820 = useMediaQuery('(min-width:820px)');
  const isSmallerThan820 = useMediaQuery('(max-width:820px)');

  markerRef.current = new LayerGroup({
    title: "Marker",
    layers: [],
  });

  nearmapImageRef.current = new LayerGroup({
    title: "NearmapImage",
    layers: [],
  });
  const navigate = useNavigate();
  const mapElement = useRef();
  // const isMobile = useScreenType();
  const [map, setMap] = useState();
  const { address, latLng, setLatLng, setAddress } = usePublicAddressContext();
  const [updatedAddress, setUpdatedAddress] = useState("");
  const { startLoader, stopLoader } = useLoader();
  const [center, setCenter] = useState([0, 0]);
  const [value, setValue] = useState("");
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const debounceTimeout = useRef(null);
  const [step, setStep] = useState(1);
  const [isPropertyConfirmed, setIsPropertyConfirmed] = useState(false);
  const tileGridLyr = useRef();
  const token = AuthToken();
  const parcelRef = useRef();
  const searchBarRef = useState();
  const [popup, showPopup] = useState(false);
  const [status, setStatus] = useState(null);
  const [oldAddress, setOldAddress] = useState(null);
  const [hoverElem, setHoverElem] = useState();
  const [hoverMapKey, setHoverMapKey] = useState();
  const [zoomLayer, setZoomLayer] = useState(true);
  const [polygonList, setPolygonList] = useState([]);
  const [parcelEdit, setParcelEdit] = useState(false);
  const [hoverParcelId, setHoverParcelId] = useState();
  const [nearmapcoords, setNearmapcoords] = useState([]);
  const [featureSelected, setFeatureSelected] = useState([]);
  const [nearmapImageDate, setNearmapImageDate] = useState("");
  const [nearmapSurveyData, setNearmapSurveyData] = useState([]);
  const [hoverParcelElement, setHoverParcelElement] = useState();
  const [selectedHoverFeature, setSelectedHoverFeature] = useState();
  const [removeEventKey, setRemoveEventKey] = useState();
  const isLargerThan1100 = useMediaQuery("(min-width:1100px)");
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [orderCreateLoading, setOrderCreateLoading] = useState(false);
  const [showNearmapImage, toggleNearmapImage] = useState(false);
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(true);
  const [featureLoading, setFeatureLoading] = useState(false)
  const [snowFeaturesList, setSnowFeaturesList] = useState([])
  const [landscapeFeaturesList, setlandscapeFeaturesList] = useState([])
  const [snowFeatureSelected, setSnowFeatureSelected] = useState(false)
  const [landscapeFeatureSelected, setLandscapeFeatureSelected] = useState(false)
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
  const [disableEverything, setDisableEverything] = useState(false)
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    contact: "",
    role: "",
  });
  const steps = ["1", "2", "3", "4"];

  const initializeMap = () => {
    if (address) {
      const initialMap = new Map({
        view: new View({
          zoom: 19,
        }),
        layers: [
          new TileLayer({
            source: new XYZ({

              url: "http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}",

              crossOrigin: "Anonymous",
            }),
            visible: true,
            title: 'Satellite',
          }),
          new TileLayer({
            source: new XYZ({

              url: "http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}",

              crossOrigin: "Anonymous",
            }),
            visible: false,
            title: 'Road',
          }),
        ],
        target: mapElement.current,
        keyboardEventTarget: document,
        controls: defaults({
          zoom: false,
          attribution: false,
          rotate: false,
        }),
      });
      setMap(initialMap);
      setCenter(latLng);
    }
  };

  const [hoverData, setHoverData] = useState({
    id: null,
    name: null,
    measurement: null,
    measurement_type: null,
  });

  // orders page functions
  markerRef.current = new LayerGroup({
    title: "Marker",
    layers: [],
  });

  parcelRef.current = new LayerGroup({
    title: "Parcel",
    layers: [],
  });

  tileGridLyr.current = new LayerGroup({
    title: "Tile Imagery",
    layers: [],
  });

  const [parcelStyle, setParcelStyle] = useState([
      new Style({
      stroke: new Stroke({
        color: "red",
        width: 2,
      }),
      fill: new Fill({
        color: "rgb(255, 255, 255,0.2)",
      }),
    }),
  ]);

  const select = new Select({
    wrapX: false,
    style: selectedStyle,
    condition: altShiftKeysOnly,
  });

  const modify = new Modify({
    features: select.getFeatures(),
  });

  const addEditInteraction = () => {
    document.getElementsByClassName("edit-parcel-button-active")[0].click();
  };

  //////////// --------- All the logic that depends on the updated polygonList is moved inside the setPolygonList functional update form ---/
  const updateThePolygonList = (List) => {
    setPolygonList(List);
  };

  const editPolygon = (List) => {
    console.log(List, " in DIY MOFIFYEND polygonlist in edit parcel");

    if (List && List.length > 0) {
      updateThePolygonList(List);
    }

    setZoomLayer(false);
    setParcelEdit(true);
    removeAllInteraction(map);
    map.addInteraction(select);
    map.addInteraction(modify);
    // document.body.classList.remove("cut-cursor");
    // document.body.classList.remove('split-cursor');
    // document.body.classList.remove('crosshair-cursor');
    // document.body.classList.add('edit-cursor');

    // const mapContainer = document.getElementById('map-cont');
    const mapContainer = document.querySelector(".ol-viewport");

    mapContainer.classList.remove("crosshair-cursor");
    mapContainer.classList.remove("split-cursor");
    mapContainer.classList.remove("cut-cursor");
    mapContainer.classList.add("edit-cursor");
    // let mapContainer = document.getElementById('map-cont');
    // mapContainer.classList.add('edit-cursor');

    map.getLayers().forEach((layer) => {
      if (layer.get("title") && layer.get("title") === "Parcel") {
        var childLayers = layer.getLayers().getArray();
        var length = childLayers.length;
        for (var i = 0; i < length; i++) {
          var lay = childLayers[i];
          lay.getSource().forEachFeature(function (feature) {
            select.getFeatures().push(feature);
          });
        }
      }
    });

    modify.on("modifyend", function (e) {
      const id = e.features.getArray()[0].getId();
      var coords = e.features.getArray()[0].getGeometry().getCoordinates();

      if (coords.length > 1) {
        console.log("this if block run ");
        const data = { parcel: coords };

        axios({
          url: `${REMOVE_HOLES_URL}`,
          method: "post",
          timeout: 8000,
          headers: {
            "x-api-key":`${encodeJwtToken()}`,
            "Content-Type": "application/json",
          },
          data: data,
        })
          .then((res) => {
            setPolygonList((prevPolygonList) => {
              let updatedPolygon = prevPolygonList.map((item) => {
                if (item.id === id) {
                  return {
                    ...item,
                    data: res.data.data,
                    measurement: calculateArea(coords),
                  };
                }
                return item;
              });
              console.log(
                updatedPolygon,
                "updatedPolygon in editpolygon openlayer"
              );
              return updatedPolygon;
            });
            setParcelStyle(selectedStyle);
            removeAllInteraction(map);
            addEditInteraction();
          })
          .catch((err) => console.error(err));
      } else {
        setPolygonList((prevPolygonList) => {
          let updatedPolygon = prevPolygonList.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                data: coords,
                measurement: calculateArea(coords),
              };
            }
            return item;
          });
          return updatedPolygon;
        });
        setParcelStyle(selectedStyle);
        removeAllInteraction(map);
        addEditInteraction();
      }
    });
  };

  useEffect(() => {
    if (map) {
      renderParcel(
        map,
        status,
        zoomLayer,
        parcelRef,
        polygonList,
        parcelStyle,
        setNearmapcoords
      );
    }
  }, [map, polygonList, parcelStyle]);

  useEffect(() => {
    if (map)
      map.getLayers().forEach(function (layer) {
        if (layer && layer.get("title") == "NearmapImage") {
          if (showNearmapImage) layer.setVisible(true);
          else layer.setVisible(false);
        }
      });
  }, [showNearmapImage]);


  useEffect(() => {
    const elem = document.getElementById("popup-cont");
    setHoverElem(elem);
    initializeMarker(
      map,
      latLng,
      hex2rgba,
      hoverMapKey,
      setHoverData,
      showPopup,
      markerRef,
      setHoverMapKey,
      setSelectedHoverFeature,
      elem
    );
  }, [latLng]);

  const hex2rgba = (hex, alpha) => {
    if (hex) {
      const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
      return `rgba(${r},${g},${b},${alpha})`;
    }
  };

  useEffect(() => {
    if (hoverParcelElement && hoverParcelId) {
      hoverOnParcel(map, hoverParcelElement, hoverParcelId, parcelEdit);
    }
  }, [hoverParcelId, hoverParcelElement]);

  useEffect(() => {
    if (map && latLng) {
      map.getView().animate({
        zoom: 19,
        duration: 4000,
        center: transform(latLng, "EPSG:4326", "EPSG:3857"),
      });
      getNearmapSurveyData();
      searchBarRef.current.style.display = "none";
    }
  }, []);

  useEffect(() => {
    initializeMap(mapElement, setMap, setLatLng, setRemoveEventKey);
  }, [latLng]);

  const coordinatesToLatLong = () => {
    const parcelData = [];
    for (let i = 0; i < polygonList.length; i++) {
      const coordinate = [];
      let coords = polygonList[i].data;
      for (let j = 0; j < coords.length; j++) {
        const singlePolygon = coords[j];
        const singlePolygonData = [];
        for (let k = 0; k < singlePolygon.length; k++) {
          let lonLat = toLonLat(singlePolygon[k]);
          singlePolygonData.push(lonLat);
        }
        coordinate.push(singlePolygonData);
      }
      parcelData.push(coordinate);
    }
    return parcelData;
  };


  const draftDiyParcel = () => {};

  const getNearmapSurveyData = () => {};

  useEffect(() => {
    if (map)
      if (nearmapImageDate === "satellite") {
        map.getLayers().forEach((layer) => {
          if (layer && layer.get("title") === "Tile Imagery") {
            map.removeLayer(layer);
          }
        });
      } else {
        map.getLayers().forEach((layer) => {
          if (
            layer &&
            layer.get("title") &&
            layer.get("title") === "Tile Imagery"
          )
            map.removeLayer(layer);
        });
        if (nearmapcoords.length)
          nearmapcoords.map((item) => {
            setNearmap(map, nearmapImageDate, item, tileGridLyr);
          });
      }
  }, [nearmapcoords, nearmapImageDate]);

  if (map) {
    const view = map.getView();

    view.on("propertychange", (event) => {
      if (event.key === "rotation") {
        const rotation = view.getRotation();
        const rotationInDegrees = (rotation * 180) / Math.PI;
        setDirection(rotationInDegrees);
      }
    });
  }

  const handleSelect = async (address) => {
    setValue(address, false);
    setAddress(address);
    const updatedLatLng = address.split(",");
    setCenter([parseFloat(updatedLatLng[1]), parseFloat(updatedLatLng[0])]);
  };

  const latLongHandler = () => {
    const regExp =
      /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
    if (regExp.test(value)) {
      handleSelect(value);
    }
    setValue("");
  };

  const updatedAddressHandler = () => {
    if (updatedAddress) {
      setAddress(updatedAddress);
      setUpdatedAddress("");
    }
  };

  // function which returns the current location of the user
  const getLocation = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = `Latitude: ${position.coords.latitude}, Longitude: ${position.coords.longitude}`;
          resolve(location);
        },
        (error) => {
          reject(error);
        }
      );
    });
  };

  // function which sets lat long
  const handleInput = (e) => {
    setValue(e.target.value);
  };
  

  // place order function
  const placeOrder = async () => {
    setOrderCreateLoading(true);
 
    const location = await getLocation();
    const response = await axios.get("https://api.ipify.org?format=json");
    const ipAddress = response.data.ip;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const system = navigator.platform;
    const browser = navigator.userAgent;
    
    try {
      const parcelData = coordinatesToLatLong();
      const response = await axios.post(
        `${EXPLORE_PLACE_ORDER_URL}`,
       
        { 
        
          address: address,
          lat: latLng?.[1],
          long: latLng?.[0],
          feature_ids: featureSelected?.map((item) => item?.id),
          "parcel": {
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": parcelData
            }
          },
          name: userData?.name,
          email: userData?.email,
          contact_number: userData?.contact,
          role: userData?.role,
          meta_data: {
            ip: ipAddress,
            location: location,
            timezone: timeZone,
            system: system,
            browser: browser,
          },
        },
        {
          headers: {
            "x-api-key": `${encodeJwtToken()}`,
            Accept: "application/json",
          },
        }
      ).then( data => {

        console.log(data, "data")
        if(data?.data?.success === true ) {
          setIsSuccessModalOpen(true);
          setDisableEverything(true)
        }
      });
      setOrderCreateLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
    setOrderCreateLoading(false);
  };

  const handleClick = () => {
    setStep(2);
    setIsPropertyConfirmed(true);

  };

  const getFeaturesList = (value) => {

    setFeatureLoading(true)
    axios.get(`${EXPLORE_FEATURES_LIST_URL}`,{
      headers:{
        "x-api-key":`${encodeJwtToken()}`,
        Accept:'application/json'
      }
    })
      .then(response => {
        console.log(response.data); // Handle the response data
        // let snowFeaturesNames = ["Turf", "Driving Lanes"]
        // let landscapeFeaturesNames = ["Soft Edge", "Mulch Beds"]

        setSnowFeaturesList(response?.data?.data?.filter((item) => item?.segment === "snow"));
        setlandscapeFeaturesList(response?.data?.data?.filter((item) => item?.segment === "landscape"));
        setFeatureLoading(false)
      })
      .catch(error => {
        console.error('Error:', error); // Handle any errors
        setFeatureLoading(false)
      });
  };

  useEffect(() => {
    getFeaturesList()
  }, [])

  const setProperty = () => {
    if (map) {
      map
        .getView()
        .animate(
          { zoom: 19 },
          { duration: 1000 },
          { center: transform(center, "EPSG:4326", "EPSG:3857") }
        );
      if (map.getLayers().getArray().length > 1) {
        map.getLayers().forEach((layer) => {
          if (layer.get("title") && layer.get("title") === "Marker") {
            map.removeLayer(layer);
          }
        });
      }
      map.addLayer(markerRef.current);
      const iconFeature = new Feature({
        geometry: new Point(transform(center, "EPSG:4326", "EPSG:3857")),
      });
      const iconStyle = new Style({
        image: new Icon({
          anchor: [0.5, 46],
          anchorXUnits: "fraction",
          anchorYUnits: "pixels",
          src: markerIcon,
        }),
      });
      iconFeature.setStyle(iconStyle);
      const vectorSource = new VectorSource({
        features: [iconFeature],
      });
      const vectorLayer = new VectorLayer({
        source: vectorSource,
      });
      markerRef.current.getLayers().push(vectorLayer);
    }
  };

  useEffect(() => {
    if (!isPropertyConfirmed) {
      setProperty();
    }

    // after the center is change run this use Effect
  }, [center]);

  if (map) {
    // on the click on any anywhere on the map set it as center
    if (!isPropertyConfirmed) {
      map.on("click", function (e) {
        setCenter(toLonLat(e.coordinate));
      });
    }
  }

  const prepareOrder = (
    <Box className="explore-confirm-property-container" >
      <Box sx={{background: "#b4e197", borderRadius: "8px",}}>
        <Box sx={{ display: "flex", alignItems: "center", padding: "3%", background: "#b4e196", borderRadius: "8px"}}>
          <Typography sx={{fontSize: "clamp(20px, 1vw, 1.3vw)", fontWeight: "600", color: '#064635'}} >Confirm Property</Typography>
        </Box>
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px", background: "rgba(255, 255, 255, 0.38)"}}>
          <Box sx={{display: "flex", gap: "5px", alignItems: "center"}}>
          <CottageIcon className="property-icon" sx={{color: "#054635"}} />
          <Typography sx={{fontSize: "clamp(13px, 1vw, 1vw)", fontWeight: "500"}}>
            {address ? address : "Lorem ipsum dolor sit amet, consectetur adipiscing elit"}
          </Typography>
          </Box>
          <IconButton size="small" className="ottermap-iconButton" onClick={() => {setAddress(null); setValue("")}} >
            <CreateIcon  fontSize="inherit" />
          </IconButton>
        </Box>
        <Box sx={{background: "rgba(255, 255, 255, 0.38)", padding: "3%"}} >
          <Typography sx={{fontSize: "clamp(13px, 1vw, 1vw)"}}>Incase the pin is on incorrect Property?</Typography>
          <Typography sx={{fontSize: "clamp(13px, 1vw, 1vw)"}}>If you can spot the property on your screen, just drop the pin with a click on it and Confirm Property.</Typography>
        </Box>
      </Box>
      <Box sx={{background: "#d1edbf", borderRadius: "8px", padding: "3%"}}>
        <Typography sx={{fontSize: "clamp(13px, 1vw, 1vw)"}}>
          If you cannot locate the property on the map, please search the
          property using Lat Long Coordinates.
          <br/>
          <span className="address-one-span">
            (Alternatively you can pick property coordinates using Google Maps)
          </span>
        </Typography>
          <Box sx={{display: "flex", justifyContent: "space-between", gap: "3%",  marginTop: "5%"}}>
          <Textarea
            sx={{width: "100%", fontSize: "clamp(13px, 1vw, 1vw) !important"}}
            type="text"
            value={value}
            onChange={handleInput}
            placeholder="Enter Lat Lang"
          />
          <Button
            className={!value.length ? "ottermap-button-disabled" : "ottermap-button"}
            disabled={!value.length}
            onClick={latLongHandler}
            sx={{paddingLeft: "1rem", paddingRight: "1rem", }}
          >
            Find
          </Button>
          </Box>
          {/* <Box sx={{display: "flex", justifyContent: "space-between", gap: "3%", marginTop: "3%"}}>
          <Textarea
            sx={{width: "100%", fontSize: "clamp(13px, 1vw, 1vw) !important"}}
            type="text"
            value={updatedAddress}
            onChange={(e) => setUpdatedAddress(e.target.value)}
            placeholder="Update Address"
          />
          <Button
            className="ottermap-button"
            disabled={!updatedAddress.length}
            onClick={updatedAddressHandler}
          >
            Save
          </Button>
        </Box> */}
      </Box>
      <Button sx={{marginBottom:  !isLargerThan820 && "15%"}} className="ottermap-button" onClick={handleClick}>
          <CheckIcon className="check-icon" /> <span>Confirm Property</span>

      </Button>
    </Box>
  );

  const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
    borderRadius: 3,
    position: "absolute",
    top: 8,
    left: "calc(50% - 15px)",
  }));

  const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
  }));

  console.log(step, "step", userData)
  
    return (
      !address ? 
        <div className="alternate-order-container">
          <Box sx={{ width: { xs: "90%", md: "85%", lg: "65%", xl: "55%" } }}>
            <PublicAddressSearch
              handleError={handleError}
              handleSuccess={handleSuccess}
            />
          </Box>
        </div>
      :
      <>
        <div className="ol-order-page">
          <div
            ref={mapElement}
            id="map-cont"
            className="main-ol-container diy-container"
          >
            {isSmallerThan820 && (
              <div className="mobile-draft-progress-main-container">
                <Global
                  styles={{
                    ".MuiDrawer-root > .MuiPaper-root": {
                      height: "40%",
                      overflow: "visible",
                    },
                    ".css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop": {
                      display: "none !important",
                    },
                    ".css-9emuhu-MuiPaper-root-MuiDrawer-paper": {
                      overflowY: "visible",
                    },
                  }}
                />
                <SwipeableDrawer
                  anchor="bottom"
                  open={isMobileDrawerOpen}
                  disableSwipeToOpen={false}
                  onOpen={() => setIsMobileDrawerOpen(true)}
                  onClose={() => setIsMobileDrawerOpen(false)}
                   swipeAreaWidth={56}
                  ModalProps={{
                    keepMounted: true,
                  }}
                >
                  <Box
                             sx={{
                                 right: 0,
                                 left: 0,
                                 position: 'absolute',
                                 visibility: 'visible',
                                 top: '-63px',
                                 height: "130%",
                                //  overflowY: "auto",
                                 borderTopLeftRadius: 8,
                                 borderTopRightRadius: 8,
                             }}
                         >
                  <Puller />
                  <Stepper  className="stepper-cont" activeStep={step - 1}>
                    {steps.map((label, index) => {
                      const stepProps = {};
                      const labelProps = {};
                      return (
                        <Step key={label} {...stepProps}>
                          <StepLabel {...labelProps}></StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                  <div
                    style={{
                      zIndex: "105",
                      height: "100%",
                      width: "100%",
                      position: "relative",
                      background: "white",
                      boxSizing: "border-box",
                      padding: "10px 3px",
                      overflowY: "scroll !important",
                    }}
                    className="mobile-flow-wrapper"
                  >
                    {step === 1 && prepareOrder}

                    {(address && step === 2) && (
                      <ExploreLeftBar
                      map={map}
                      setStep={setStep}
                      step={step}
                      markerRef={markerRef}
                      parcelRef={parcelRef}
                      oldAddress={oldAddress}
                      editPolygon={editPolygon}
                      polygonList={polygonList}
                      setZoomLayer={setZoomLayer}
                      searchBarRef={searchBarRef}
                      setOldAddress={setOldAddress}
                      handleSuccess={handleSuccess}
                      setParcelEdit={setParcelEdit}
                      setParcelStyle={setParcelStyle}
                      draftDiyParcel={draftDiyParcel}
                      setPolygonList={setPolygonList}
                      featureSelected={featureSelected}
                      setNearmapcoords={setNearmapcoords}
                      nearmapImageDate={nearmapImageDate}
                      setHoverParcelId={setHoverParcelId}
                      setFeatureSelected={setFeatureSelected}
                      setHoverParcelElement={setHoverParcelElement}
                      userData={userData}
                      setUserData={setUserData}
                      removeEventKey={removeEventKey}
                      />
                    )}

                    {(step === 3) && (
                      <ExploreFeaturesModal
                      handleSuccess={handleSuccess}
                setStep={setStep}
                step={step}
                featureSelected={featureSelected}
                setFeatureSelected={setFeatureSelected}
                snowFeatureSelected={snowFeatureSelected}
                snowFeaturesList={snowFeaturesList}
                landscapeFeatureSelected={landscapeFeatureSelected}
                landscapeFeaturesList={landscapeFeaturesList}
                setSnowFeatureSelected={setSnowFeatureSelected}
                setLandscapeFeatureSelected={setLandscapeFeatureSelected}
                      />
                    )}

                    {(step === 4 ) && (
                      <ExploreplaceOrderForm
                        handleSuccess={handleSuccess}
                        loading={orderCreateLoading}
                        _function={placeOrder}
                        setUserData={setUserData}
                        userData={userData}
                        disableEverything={disableEverything}
                        featureSelected={featureSelected}
                        polygonList={polygonList}
                        setStep={setStep}
                      />
                    )}
                  </div>
                  </Box>
                </SwipeableDrawer>
              </div>
            )}

            {isLargerThan820 && <ExploreProgressBar disableEverything={disableEverything} step={step} setStep={setStep} />}

            {step === 1 && isLargerThan820 && prepareOrder}

            {address && step === 2 && isLargerThan820 && (
              <ExploreLeftBar
                map={map}
                setStep={setStep}
                step={step}
                markerRef={markerRef}
                parcelRef={parcelRef}
                oldAddress={oldAddress}
                editPolygon={editPolygon}
                polygonList={polygonList}
                setZoomLayer={setZoomLayer}
                searchBarRef={searchBarRef}
                setOldAddress={setOldAddress}
                handleSuccess={handleSuccess}
                setParcelEdit={setParcelEdit}
                setParcelStyle={setParcelStyle}
                draftDiyParcel={draftDiyParcel}
                setPolygonList={setPolygonList}
                featureSelected={featureSelected}
                setNearmapcoords={setNearmapcoords}
                nearmapImageDate={nearmapImageDate}
                setHoverParcelId={setHoverParcelId}
                setFeatureSelected={setFeatureSelected}
                setHoverParcelElement={setHoverParcelElement}
                userData={userData}
                setUserData={setUserData}
                removeEventKey={removeEventKey}
              />
            )}

            {isLargerThan820 && step === 3 && (
              <ExploreFeaturesModal
                handleSuccess={handleSuccess}
                setStep={setStep}
                step={step}
                featureSelected={featureSelected}
                setFeatureSelected={setFeatureSelected}
                snowFeatureSelected={snowFeatureSelected}
                snowFeaturesList={snowFeaturesList}
                landscapeFeatureSelected={landscapeFeatureSelected}
                landscapeFeaturesList={landscapeFeaturesList}
                setSnowFeatureSelected={setSnowFeatureSelected}
                setLandscapeFeatureSelected={setLandscapeFeatureSelected}
              />
            )}
            {isLargerThan820 && step === 4 && (
              <ExploreplaceOrderForm
                handleSuccess={handleSuccess}
                loading={orderCreateLoading}
                _function={placeOrder}
                setUserData={setUserData}
                userData={userData}
                disableEverything={disableEverything}
                featureSelected={featureSelected}
                polygonList={polygonList}
                setStep={setStep}
              />
            )}

          </div>

          <div className="map-buttons hideOnScreenshot">
            <div className="map-layer-button">
              <LayersTypeContainer
                map={map}
                handleLayerTypeMap={handleLayerTypeMap}
                handleLayerTypeSatellite={handleLayerTypeSatellite}
              />
              <div className="map-rotation-buttons">
                <Tooltip title="Rotate map 90deg left" placement="top-start">
                  <Rotate90DegreesCcwIcon
                    className="map-control-icon"
                    onClick={() => rotateMap(map, -90)}
                  />
                </Tooltip>
                <Tooltip title="Rotate map 90deg right" placement="top-start">
                  <Rotate90DegreesCwIcon
                    className="map-control-icon"
                    onClick={() => rotateMap(map, 90)}
                  />
                </Tooltip>
              </div>
            </div>
            <div className="map-zoom-buttons">
              <Tooltip
                title="Use Alt/Option+Shift and Drag to rotate the map. Press this button to reset."
                placement="top"
              >
                <button onClick={() => resetRotation(map)}>
                  <NavigationIcon
                    className="map-control-icon"
                    sx={{ rotate: `${direction}deg` }}
                  />
                </button>
              </Tooltip>
              <button onClick={() => handleZoomIn(map)}>
                <ZoomInIcon className="map-control-icon" />
              </button>
              <button onClick={() => handleZoomOut(map)}>
                <ZoomOutIcon className="map-control-icon" />
              </button>
            </div>
          </div>

          <CustomModal icon={<CheckCircleTwoToneIcon sx={{color: "#89b48d", fontSize: "3rem"}} />} h="fit-content !important" maxH="fit-content !important" submitText="close" submit={() => setIsSuccessModalOpen(false)} title="Order created!" description="You order has been placed and final output will be shared on the mail." setOpen={setIsSuccessModalOpen} open={isSuccessModalOpen}/>

        </div>
      </>
    );
};

export default OpenLayersPage;
