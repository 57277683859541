import axios from 'axios';
import './MapLeftBar.css';
import { Tooltip } from '@mui/material';
import { useParams } from 'react-router-dom';
import { STATUS } from '../../utils/constants';
import { ORDER_URL } from '../../helper/ApiUrl';
import AuthToken from '../../context/AuthToken';
import AddressBar from '../AddressBar/AddressBar';
import { TOAST_TYPE } from '../../utils/constants';
import React, { useEffect, useState } from 'react';
import { useScreenType } from '../../utils/commonMethods';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import total_area_icon from '../../assets/total-area.svg';
import { FormControlLabel, FormGroup } from '@material-ui/core';
import { ReactComponent as Arrow } from '../../assets/close.svg';
import { ReactComponent as CheckIcon } from '../../assets/Check.svg';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import PropertyInformation from '../PropertyInformation/PropertyInformation';
import Checkbox from '@mui/material/Checkbox';

export default function FeatureSelection({
  step,
  status,
  setStep,
  address,
  resetData,
  isCloseBtn,
  toggleSlide,
  handleError,
  calculateTime,
  handleSuccess,
  toggleSlideBtn,
  navigateAddress,
  featureSelected,
  availableFeatures,
  setFeatureSelected,
  clearedSteps,
  setClearedSteps
}) {
  const params = useParams();
  const isMobile = useScreenType();
  const [snowFeatures, setSnowFeatures] = useState(false);
  const [landscapeFeatures, setLandscapeFeatures] = useState(false);

  useEffect(() => {
    const allSnowFeaturesSelected = availableFeatures
      .filter((feature) => feature.segment === 'snow')
      .every((snowFeature) =>
        featureSelected.some((selected) => selected.id === snowFeature.id)
      );
    if (allSnowFeaturesSelected) setSnowFeatures(true);
    const allLandscapeFeaturesSelected = availableFeatures
      .filter((feature) => feature.segment === 'landscape')
      .every((landscapeFeature) =>
        featureSelected.some((selected) => selected.id === landscapeFeature.id)
      );
    if (allLandscapeFeaturesSelected) setLandscapeFeatures(true);
  }, [featureSelected]);

  const featureData = {
    feature_ids: [],
  };

  const addFeatures = () => {
    if (featureSelected.length === 0) {
      handleSuccess(TOAST_TYPE.WARNING, 'Please select one or more feature');
      return false;
    }
    featureSelected.map((item) => {
      featureData.feature_ids.push(item.id);
    });

    axios({
      url: `${ORDER_URL}${params.id}/feature`,
      method: 'POST',
      data: featureData,
      headers: {
        Authorization: `Token ${AuthToken()}`,
      },
    })
      .then((res) => {
        if ('data' in res) {
          setStep(4);
          setClearedSteps([...clearedSteps, 3])
          handleSuccess(TOAST_TYPE.SUCCESS, 'Features added successfully');
        }
      })
      .catch((err) => {
        handleError(err);
      });
    calculateTime();
  };

  const checkCheckBox = (id) => {
    let flag = false;
    featureSelected.map((item) => {
      if (id === item.id) flag = true;
    });
    return flag;
  };

  const selectSnowFeatures = () => {
    let snowFeaturesArr = [];
    if (!snowFeatures) {
      availableFeatures.map((feature) => {
        if (feature.segment === 'snow' && !featureSelected.includes(feature)) snowFeaturesArr.push(feature);
      });
      setFeatureSelected([...featureSelected, ...snowFeaturesArr]);
      setSnowFeatures(true);
    } else {
      const updatedFeatureSelected = featureSelected.filter((selected) => {
        return !availableFeatures.some(
          (available) =>
            available.segment === 'snow' && available.id === selected.id
        );
      });
      setFeatureSelected(updatedFeatureSelected);
      setSnowFeatures(false);
    }
  };

  const selectLandscapeFeatures = () => {
    let landscapeFeaturesArr = [];
    if (!landscapeFeatures) {
      availableFeatures.map((feature) => {
        if (feature.segment === 'landscape' && !featureSelected.includes(feature)) landscapeFeaturesArr.push(feature);
      });
      setFeatureSelected([...featureSelected, ...landscapeFeaturesArr]);
      setLandscapeFeatures(true);
    } else {
      const updatedFeatureSelected = featureSelected.filter((selected) => {
        return !availableFeatures.some(
          (available) =>
            available.segment === 'landscape' && available.id === selected.id
        );
      });
      setFeatureSelected(updatedFeatureSelected);
      setLandscapeFeatures(false);
    }
  };

  return (
    <div className="property-container">
      <div className="property-inner-cont">
        {/* <PropertyInformation
          handleError={handleError}
          handleSuccess={handleSuccess}
        /> */}
        <div className="property-attribute-cont">
          <div className="close-btn-div">
            <div className="draft-status-back-button-div">
              <Tooltip title="Back">
                <button onClick={() => setStep(step - 1)}>
                  <ArrowBackIcon className="arrow-back-icon" />
                </button>
              </Tooltip>
               <span> Select Features</span>
            </div>
            <div
              style={
                status === STATUS.DRAFT || status === STATUS.IN_PROGRESS
                  ? {
                    height: '30%',
                  }
                  : null
              }
              className={`map-leftbar-close-btn ${isCloseBtn ? '' : 'slide-close-hidden'
                }`}
            >
              <button
                className="close-feature-container"
                onClick={() => {
                  toggleSlide();
                  toggleSlideBtn();
                }}
              >
                <Arrow />
              </button>
            </div>
          </div>
         
            <p className="instruction-for-the-feature">
              Select the features that you want us to measure for this order.
              You can know more about each feature through our{' '}
              <a
                className="feature-selection-link"
                href="https://ionian-walrus-f46.notion.site/Feature-Directory-2bfa53a786fa4115b007d73aa3bc4492"
                target="_blank"
                rel="noopener noreferrer"
              >
                feature directory.
              </a>
            </p>
          
          <div className="feature-info-container">
            <div className="snow-landscape-segment-cont">
              <FormGroup row={false}>
                <FormControlLabel
                  // key={id}
                  className="features-info-input"
                  control={
                    <Checkbox
                      size='small'
                      color="success"
                      value="Okay"
                      checked={snowFeatures}
                      onChange={() => selectSnowFeatures()}
                    />
                  }
                  label="Select Snow Features"
                />
              </FormGroup>
              <FormGroup row={false}>
                <FormControlLabel
                  // key={id}
                  className="features-info-input"
                  control={
                    <Checkbox
                      size='small'
                      color="success"
                      value="Okay"
                      checked={landscapeFeatures}
                      onChange={(e) => selectLandscapeFeatures()}
                    />
                  }
                  label="Select Landscape Features"
                />
              </FormGroup>
              <hr></hr>
            </div>
            <div className="features-container">
              <FormGroup row={false}>
                {availableFeatures?.filter((feature) => feature.creator === 'otter')?.map((item) => (
                    <FormControlLabel
                      key={item.id}
                      sx={{
                        // padding: '5px 0px',
                        // height: '20px !important',
                      }}
                      className="features-info-input"
                      control={
                        <Checkbox
                          sx={{ margin: "0px !important"}}
                          size='small'
                          color="success"
                          value={item.id}
                          checked={checkCheckBox(item.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFeatureSelected([...featureSelected, item]);
                            } else {
                              setFeatureSelected(
                                featureSelected.filter(
                                  (feature) => feature.id !== item.id
                                )
                              );
                            }
                          }}
                        />
                      }
                      label={item.name}
                    />
                  ))}
              </FormGroup>
            </div>
            <div className="total-area-container">
              <img src={total_area_icon} alt="total_area_icon" />
              Total features - {featureSelected.length}
            </div>
          </div>
        </div>
        <div className="property-function-button">
          <button className='ottermap-button' style={{width: "100%"}} onClick={addFeatures}>
            <CheckIcon className="check-icon" />{' '}
            <span>Confirm Selected Features</span>
          </button>
        </div>
      </div>
    </div>
  );
}
