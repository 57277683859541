import { faFileAlt, faFilePdf, faFileWord, faFileExcel, faFileAudio, faFileVideo, faFilePowerpoint } from "@fortawesome/free-regular-svg-icons";
import path from "path";
import axios from "axios";
import "./OrderNoteForm.css";
import { useEffect, useRef } from "react";
import Menu from "@mui/material/Menu";
import React, { useState } from "react";
import { Tooltip } from "@material-ui/core";
import { useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import AuthToken from "../../context/AuthToken";
import CloseIcon from "@mui/icons-material/Close";
import ImageIcon from "@mui/icons-material/Image";
import { TOAST_TYPE } from "../../utils/constants";
import MediaDialog from "../MediaDialog/MediaDialog";
import IconButton from '@mui/material/IconButton';
import VideocamIcon from "@mui/icons-material/Videocam";
import { MentionsInput } from "react-mentions";
import { ORDER_URL, LOGIN_URL } from "../../helper/ApiUrl";
import { useUserContext } from "../../context/UserContext";
import AttachmentIcon from "@mui/icons-material/Attachment";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import OrderNotesCommentSection from "../OrderNotesCommentSection/OrderNotesCommentSection";
import OutlinedInput from "@mui/material/OutlinedInput";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { encodeJwtToken } from "../../utils/EncodeJwt";

import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Mention from '@tiptap/extension-mention';
import TextAlign from '@tiptap/extension-text-align';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Underline from '@tiptap/extension-underline';
import Heading from '@tiptap/extension-heading';
import ListItem from '@tiptap/extension-list-item';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import TitleIcon from '@mui/icons-material/Title'; // For headings
import { Typography } from "@mui/material";
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PaletteIcon from '@mui/icons-material/Palette';
import OrderedList from '@tiptap/extension-ordered-list';
import BulletList from '@tiptap/extension-bullet-list';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color';
import TextColor from './TextColorPicker.jsx';
import { ChromePicker } from 'react-color'; // From react-color
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import Placeholder from '@tiptap/extension-placeholder'; 

const mentionStyles = {
  color: "wheat",
};

var toolbarOptions = [
  [{ size: [] }],
  [{ list: "ordered" }],
  [{ list: "bullet" }],
  [{ color: [] }],
];

const Toolbarmodule = {
  toolbar: toolbarOptions,
};

export default function OrderNoteForm({
  userAdded,
  deleteNote,
  handleError,
  setUserAdded,
  usersDetails,
  handleSuccess,
  orderNoteData,
  setDeleteNote,
  outerOrderNoteRef,
  toggleMapClickable,
  setOrderNoteLayerVisible,
  toggleOrderNoteMarkerVisible,
  setOrderNoteEditable,
  orderNoteEditable,
  setOrderNoteData,
  setOrderNotesModalShow,
}) {
  const token = AuthToken();
  const { id } = useParams();
  const url = window.location.href;
  const { userInfo } = useUserContext();
  const [users, setUsers] = useState([]);
  const prod = process.env.REACT_APP_PROD;
  const [dialogMedia, setDialogMedia] = useState();
  const [userAssigned, setUserAssigned] = useState();
  const [attachments, setAttachments] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [orderNoteTitle, setOrderNoteTitle] = useState();
  const [mentionedUsers, setMentionedUsers] = useState([]);
  const [userToBeAssigned, setUserToBeAssigned] = useState();
  const [addingOrderNote, setAddingOrderNote] = useState(false);
  const [mentionUserAnchor, setMentionUserAnchor] = useState(null);
  const [orderNoteDescription, setOrderNoteDescription] = useState();
  const [modifiedNoteTitle, setModifiedNoteTitle] = useState(" ");
  const [fontStyle, setFontStyle] = useState("h1");
  const [color, setColor] = useState("#000000");
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false); 
  const [Qdescription, QsetDescription] = useState("");
  const triggerTextRef = useRef(null);
  const prevContentRef = useRef('');
  console.log(users, "users")

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const containerStyle = {
    borderRadius: "5px",
    overflow: "hidden",
    // border: '1px solid #ccc',
    marginBottom: ".5rem",
  };

  console.log(Qdescription, "Qdescription");
  const editorStyle = {
    backgroundColor: "white",
    // padding: '10px',
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
  };


  // code for ReactQull
  const renderTitle = () => {
    const Tag = fontStyle;

    let styleData = <Tag style={{ color }}>{orderNoteTitle}</Tag>;
    // let styleData = <h1 style={{
    //   color: savedData.titleColor,
    //   fontSize: getFontSize(),
    // }}>{orderNoteTitle}</h1>;

    console.log(styleData, "styleData");

    setModifiedNoteTitle(styleData);
    setOrderNoteTitle(styleData);
  };

  // const handleColorChange = (color) => {
  //   setColor(color.hex);
  // };


  const mentionsInputStyles = {
    control: {
      backgroundColor: orderNoteEditable ? "#FFFFFF" : "#E4EFE9",
      padding: "0.5rem 1rem",
      fontSize: 14,
      // height: "4.5em",
      height: "1.8em",
      border: orderNoteEditable
        ? "1px solid #696969"
        : "1px solid rgb(174, 195, 155)",
      borderRadius: "13px",
    },
    suggestions: {
      list: {
        borderRadius: "10px",
        // backgroundColor: "white",
        boxShadow:
          "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
        color: "#000",
        fontSize: "0.8rem",
        width: "10rem",
      },
      item: {
        backgroundColor: "white",
        padding: "0.5rem 1rem",
        "&focused": {
          backgroundColor: "#eeeeee",
        },
        "&hovered": {
          backgroundColor: "#eeeeee",
        },
      },
    },
  };


  useEffect(() => {
    usersDetails.map((item, index) => {
      users.push({
        id: item.id,
        display: item.name,
      });
    });

    setOrderNoteTitle(orderNoteData.title);
    setOrderNoteDescription(orderNoteData.description);
    QsetDescription(orderNoteData?.meta_data?.Qdescription);
    if (orderNoteData.user_ids) {
      const obj = usersDetails.filter(
        (x) => x.id === orderNoteData.user_ids[0]
      );
      if (obj.length) setUserAssigned({ id: obj[0].id, name: obj[0].name });
    } else setUserAssigned();
    setAttachments(orderNoteData.attachments);
    if (!orderNoteData.hasOwnProperty("id")) {
      setAddingOrderNote(true);
      // setOrderNoteEditable(true);
    } else {
      setAddingOrderNote(false);
      // setOrderNoteEditable(false);
    }
  }, [orderNoteData]);

  useEffect(() => {
    let tempString = [];
    let temp = mentionedUsers?.map((item, index) => {
        tempString.push(`@[${item?.name}](${item?.id})`)
    })
  }, [mentionedUsers])

  const titleHandler = (e) => setOrderNoteTitle(e.target.value);
  const addOrderNote = (e) => {
    e.preventDefault();

    // HA
    renderTitle();
    // const Tag = fontStyle;
    //  <Tag style={{ color }}>{title}</Tag>;
    //

    var arr = "";

    if (orderNoteData.hasOwnProperty("id")) {
      let formData = new FormData();
      formData.append("title", orderNoteTitle);

      // Use a regular expression to match all strings starting with '@'
      const mentions = Qdescription.match(/@\w+/g);

      let tempMentionedUsers = users.filter((item, index) => {
        return mentions.includes(`@${item?.display}`)
      })

      let mentionedUserList = tempMentionedUsers?.map((item, index) => {
        return `@[${item?.display}](${item?.id})`
      })

      formData.append("description", mentionedUserList?.join(' '));

      let temp = {
        color: color,
        fontSize: fontStyle,
        Qdescription: Qdescription,
      };

      formData.append("meta_data", JSON.stringify(temp));

      if (attachments)
        formData.append("attachments", JSON.stringify(attachments));
      if (userToBeAssigned) {
        arr += `${String(userToBeAssigned.id)}`;
        formData.append("user_ids", arr);
      }

      axios({
        url: `${ORDER_URL}${id}/notes/${orderNoteData.id}`,
        method: "PUT",
        data: formData,
        headers: {
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => {
          if ("data" in res) {
            setUserAdded(!userAdded);
            handleSuccess(
              TOAST_TYPE.SUCCESS,
              "Order Note successfully updated"
            );
            setOrderNoteEditable(false);
            setOrderNoteData(null);
            // if (url.includes(prod)) {
            if (url.includes(process.env.REACT_APP_DEV)) {
              window.analytics.track("Order Note Updated", {
                order_note_id: orderNoteData.id,
                creator: orderNoteData.created_by,
              });
            }
          }
        })
        .catch((err) => {
          handleError(err);
        });
    } else {
      let formData = new FormData();
      formData.append("title", orderNoteTitle);

      let mentionedUserList = mentionedUsers?.map((item, index) => {
        return `@[${item?.name}](${item?.id})`
      })

      formData.append("description", mentionedUserList?.join(' '));

      formData.append("lat", orderNoteData.lat);
      formData.append("long", orderNoteData.long);

      // formData.append("title", modifiedNoteTitle); // HA
      // formData.append("color", color) ;//HA
      // formData.append("FontSize", fontStyle) ;//HA
      // console.log(formData,"formData")//HA

      let meta_data = {
        color: color,
        fontSize: fontStyle,
        Qdescription: Qdescription,
      };
      //  let metaData = [ color, fontStyle]

      formData.append("meta_data", JSON.stringify(meta_data));

      if (attachments)
        formData.append("attachments", JSON.stringify(attachments));
      if (userToBeAssigned) {
        arr += `${String(userToBeAssigned.id)}`;
        formData.append("user_ids", arr);
      }
      axios({
        url: `${ORDER_URL}${id}/notes`,
        method: "POST",
        data: formData,
        headers: {
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => {
          if ("data" in res) {
            setUserAdded(!userAdded);
            handleSuccess(TOAST_TYPE.SUCCESS, "Order Note successfully added");
            // if (url.includes(prod)) {
            if (url.includes(process.env.REACT_APP_DEV)) {
              window.analytics.track("Order Note Created", {
                order_note_id: orderNoteData.id,
                creator: orderNoteData.created_by,
              });
            }
          }
        })
        .catch((err) => {
          handleError(err);
        });
    }
    toggleOrderNoteMarkerVisible(false);
    setOrderNoteLayerVisible(false);
    toggleMapClickable(false);
    setOrderNoteTitle("");
    setOrderNoteDescription("");
  };

  const onCloseClick = (e) => {
    e.preventDefault();
    toggleOrderNoteMarkerVisible(false);
    setOrderNoteEditable(false);
    toggleMapClickable(false);
  };

  const tagUser = (id, display) => {
    const user = { id: id, name: display };
  
    if (id !== null && display !== null) {
      if (!mentionedUsers.some((mentionedUser) => mentionedUser.id === user.id)) {
        setMentionedUsers((prevMentionedUsers) => [...prevMentionedUsers, user]);
  
        // Get the current content of the editor as plain text
        const currentContent = editor.getText();
  
        // Find the last occurrence of "@"
        const lastAtIndex = currentContent.lastIndexOf('@');
  
        if (lastAtIndex !== -1) {
          // Find where the cursor is currently positioned
          const cursorPosition = editor.state.selection.from;
  
          // Delete everything typed after "@" up to the cursor position
          editor.commands.deleteRange({
            from: lastAtIndex, // Start from '@'
            to: cursorPosition, // Remove all typed text after '@'
          });
  
          // Insert the mention (with '@') and ensure no extra characters are added
          editor.commands.insertContent(` @${display}`);
  
          // Update Qdescription with the new content
          QsetDescription(editor.getHTML());
  
          // Optionally set the cursor to the end of the new mention
          const newCursorPosition = lastAtIndex + display.length + 1; // '@' + name length
          editor.commands.setTextSelection(newCursorPosition);
        }
      }
    }
  };
  

  const editor = useEditor({
    extensions: [
      StarterKit,
      OrderedList,
      BulletList,
      TextStyle, // Needed for color
      Color,     // Text color extension
      Bold,
      ListItem,
      Heading,
      TextColor,
      Placeholder.configure({
        placeholder: 'Start typing here or type @ to mention someone.', // Your placeholder text
        emptyEditorClass: 'is-editor-empty', // Optional: CSS class when the editor is empty
      }),
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      Mention.configure({
        HTMLAttributes: {
          class: 'mention',
        },
        suggestion: {
          items: (query) => {
            triggerTextRef.current = query?.query
            let temp = users?.filter((user) => {
              const displayName = user?.display;
              const searchQuery =  query?.query?.toLowerCase();
              
              return displayName?.toLowerCase().startsWith(searchQuery);
            });
            return query?.query === '' ? users : temp;
            
          },
          render: () => {
            let dropdown;

            return {
              onStart: (props) => {
                dropdown = document.createElement('div');
                dropdown.className = 'mention-dropdown';
                props.editor.view.dom.parentNode.appendChild(dropdown);

              },
              onUpdate: (props) => {
               
                const items = props.items.map((item) => 
                  `<div data-id="${item.id}" data-display="${item.display}">${item.display}</div>`
                ).join('');
                dropdown.innerHTML = `<div class="mention-list">${items}</div>`;
  
                // Add click event listeners to dropdown items
                dropdown.querySelectorAll('div').forEach((itemDiv) => {
                  itemDiv.addEventListener('click', () => {
                    const id = itemDiv.getAttribute('data-id');
                    const display = itemDiv.getAttribute('data-display');
                  
                    // Optionally, insert the mention text into the editor
                    if(display) {

                      tagUser(id, display);
                  
                    }
                    
                    // Remove the dropdown
                    dropdown.remove();
                  });
                });
              },
              onExit: () => {
                dropdown.remove();
              },
            };
          },
        },
      }),
    ],
    content: '',
    onUpdate: ({ editor }) => {
      const currentContent = editor.getHTML();
     // Update the state whenever the content changes
      QsetDescription(currentContent);
  
    },
  });

  const setTextColor = (color) => {
    editor.chain().focus().setColor(color).run();
  };

  const handleColorChange = (color) => {
    setColor(color.hex);
    editor.chain().focus().setColor(color.hex).run();
  };

  const setData = (e) => {
    let element = document.getElementById("attachment-loader");
    element.classList.remove("attachment-loader-hide");
    element.classList.add("attachment-loader-show");
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    axios({
      url: `${LOGIN_URL}file/upload?folder=notes`,
      method: "POST",
      data: formData,
      headers: {
        "x-api-key": `${encodeJwtToken()}`,
      },
    })
      .then((res) => {
        if ("data" in res) {
          if (!attachments) setAttachments([res.data.file_url]);
          else setAttachments([...attachments, res.data.file_url]);
          handleSuccess(TOAST_TYPE.SUCCESS, "File added successfully");
          element.classList.remove("attachment-loader-show");
          element.classList.add("attachment-loader-hide");
        }
      })
      .catch((err) => {
        handleError(err);
        element.classList.remove("attachment-loader-show");
        element.classList.add("attachment-loader-hide");
      });
  };

  const deleteAttachment = (item) => {
    var arr = attachments;
    const index = arr.indexOf(item);
    if (index !== -1) {
      arr.splice(index, 1);
      setAttachments([...arr]);
    }
  };

  const FileIcon = ({ url }) => {
    let extname = path.extname(url);
    let icon;
    switch (extname) {
      case ".pdf":
        icon = faFilePdf;
        break;
      case ".doc":
      case ".docx":
        icon = faFileWord;
        break;
      case ".ppt":
      case ".pptx":
        icon = faFilePowerpoint;
        break;
      case ".xls":
      case ".xlsx":
        icon = faFileExcel;
        break;
      case ".jpg":
      case ".jpeg":
      case ".png":
      case ".gif":
        return <img style={{ cursor: "pointer" }} src={url} alt={url} />;
      case ".mp3":
      case ".wav":
        icon = faFileAudio;
        break;
      case ".mp4":
      case ".mov":
        icon = faFileVideo;
        break;
      default:
        icon = faFileAlt;
        break;
    }
    return <FontAwesomeIcon className="doc-type-attachment-icon" icon={icon} />;
  };

  const handleOpenDialog = (item) => {
    let p = path.extname(item);
    if (
      p !== ".jpg" &&
      p !== ".jpeg" &&
      p !== ".png" &&
      p !== ".gif" &&
      p !== ".mp4" &&
      p !== ".mov"
    )
      window.open(item, "_blank");
    else {
      setDialogMedia(item);
      setOpenDialog(true);
    }
  };




// use effect which sets the description in case of edit
useEffect(() => {

  if (editor) {
    editor.commands.setContent(orderNoteData?.meta_data?.Qdescription);
    // setMentionedUsers(orderNoteData?.description)
    if (orderNoteData?.description) {
      const regex = /@\[([^)]+)\]\((\d+)\)/g;
      const matches = orderNoteData?.description.match(regex);
      if (matches) {
        const allUsers = matches.map((match) => {
          const userId = parseInt(match.match(/\((\d+)\)/)[1]);
          const userName = match.match(/\[([^)]+)\]/)[1];
          return { id: userId, name: userName };
        });
        setMentionedUsers(allUsers);
      } else {
        setMentionedUsers([]);
      }
    }
  }
}, [editor, orderNoteData]);


  if (!editor) {
    return null;
  }

  return (
    <>
      <div className="overlay-container">
        <div className="ordernote-infowindow-container" style={{minHeight: orderNoteData?.created_at && "350px", background: orderNoteData?.created_at && "white", boxShadow: orderNoteData?.created_at && "none"}}>
          <form onSubmit={addOrderNote} style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
            <>
            <div className="ordernote-title-input-container">
              <div className="ordernote-title-input">
                <OutlinedInput
                  sx={{
                    width: "100%",
                    fontSize: "clamp(13px, 1vw, 1vw) !important",
                  }}
                  type="text"
                  placeholder="Title"
                  required
                  style={
                    orderNoteEditable
                      ? { background: "#FFFFFF" }
                      : { background: "#E7FFD8", border: "1px solid #E7FFD8" }
                  }
                  disabled={!orderNoteEditable}
                  onChange={titleHandler}
                  value={orderNoteTitle}
                />
               
              </div>
            </div>

            <div className="ordernote-description-input-container">
              <div className="ordernote-description-input">
                {/* New code  ReactQUill */}

             <div className="editor-toolbar-wrapper">
               <div className="editor-toolbar">
               <div className="order-note-actions-cont">
              <input
                type="file"
                disabled={!orderNoteEditable}
                id="imgupload"
                style={{ display: "none" }}
                accept="image/*"
                onChange={setData}
              />

              <Tooltip placement="top" title={ orderNoteEditable ? "Add Image" : "Edit note to Add File" } >
                <label htmlFor="imgupload">
                  <ImageIcon sx={{ fontSize: "clamp(20px, 1vw, 2vw)" }} style={{ color: "grey" }} />
                </label>
              </Tooltip>

              <input
                type="file"
                disabled={!orderNoteEditable}
                id="vidupload"
                style={{ display: "none" }}
                accept="video/*"
                onChange={setData}
              />

              <Tooltip placement="top" title={ orderNoteEditable ? "Add Video" : "Edit note to Add File" } >
                <label htmlFor="vidupload">
                  <VideocamIcon
                    sx={{ fontSize: "clamp(25px, 0.80vw, 2.5vw)" }}
                    style={{ color: "grey" }}
                  />
                </label>
              </Tooltip>

              <input
                type="file"
                disabled={!orderNoteEditable}
                id="attachmentupload"
                style={{ display: "none" }}
                onChange={setData}
              />
              <Tooltip placement="top" title={ orderNoteEditable ? "Add Document" : "Edit note to Add File"}>
                <label htmlFor="attachmentupload">
                  <AttachmentIcon style={{color: "grey" }}/>
                </label>
              </Tooltip>

             
               </div>   
            <div style={{display: "inherit"}}>
      <IconButton  aria-label="bold" size="small" onClick={() => editor.chain().focus().toggleBold().run()}  className={editor.isActive('bold') ? 'option-active' : ''}>
        <FormatBoldIcon sx={{fontSize: "20px"}} />
      </IconButton>
      <IconButton  aria-label="paragraph" size="small" onClick={() => editor.chain().focus().setParagraph().run()} className={editor.isActive('paragraph') ? 'option-active' : ''}>
         <Typography sx={{fontSize: "15px", fontWeight: "500"}} >P</Typography> 
      </IconButton>
      <IconButton aria-label="h1" size="small" onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()} className={editor.isActive('heading', { level: 1 }) ? 'option-active' : ''}>
        <Typography sx={{fontSize: "15px", fontWeight: "500"}} >H1</Typography> 
      </IconButton>
      <IconButton aria-label="h2" size="small" onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()} className={editor.isActive('heading', { level: 2 }) ? 'option-active' : ''}>
        <Typography sx={{fontSize: "15px", fontWeight: "500"}} >H2</Typography> 
      </IconButton>
      <IconButton aria-label="bulletList" size="small" onClick={() => editor.chain().focus().toggleBulletList().run()}  className={editor.isActive('bulletList') ? 'option-active' : ''}>
          <FormatListBulletedIcon sx={{fontSize: "20px"}} />
        </IconButton>
        <IconButton aria-label="NumberedList" size="small"  onClick={() => editor.chain().focus().toggleOrderedList().run()}  className={editor.isActive('orderedList') ? 'option-active' : ''}>
          <FormatListNumberedIcon sx={{fontSize: "20px"}} />
        </IconButton>
        <div style={{position: "relative" }}>
        <IconButton aria-label="Color" size="small" style={{ color: color }}  onClick={() => setIsColorPickerOpen(!isColorPickerOpen)} >
          <FormatColorTextIcon  sx={{fontSize: "17px"}}/>
        </IconButton>
      
        {isColorPickerOpen && (
        <div style={{ position: 'absolute', zIndex: 9999, right: "0px"}}>
          <ChromePicker
            color={color}
            onChange={handleColorChange}
          />
        </div>
        )}
      </div>
            </div>
               </div>
               <EditorContent editor={editor} />
             </div>

             {/* <div style={{position: "relative", display: "flex", justifyContent: "start"}}>
             {mentionedUsers?.length > 0 && <Button
             id="basic-button"
             aria-controls={open ? 'basic-menu' : undefined}
             aria-haspopup="true"
             aria-expanded={open ? 'true' : undefined}
        variant="text"
        disableElevation
        // onClick={() =>setMentionedUsersModalOpen(true)}
        onClick={handleClick}
        sx={{color: "#7e9d92", textTransform: "none !important"}}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Mentioned
             </Button>}
                
               <Menu
                  // className="menu-cont-tag-users"
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{ "aria-labelledby": "basic-button"}}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                >
                  {mentionedUsers?.map((item, key) => {
                    return (
                      <MenuItem
                        onClick={() => {
                          setUserToBeAssigned(item);
                          // handleMentionUserAnchorClose();
                        }}
                        // className="menu-cont-mention-users-item"
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "start",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ marginLeft: "0.3em" }}>
                            <div>{item.name}</div>
                            <div>{item.email}</div>
                          </div>
                        </div>
                      </MenuItem>
                    );
                  })}
                </Menu>
              </div> */}
              </div>
            </div>
          
            <div className="attachments-cont">
              {attachments &&
                Array.isArray(attachments) &&
                attachments.map((item, key) => {
                  return (
                    <div
                      key={key}
                      onClick={() => handleOpenDialog(item)}
                      className="attachment-item-cont"
                    >
                      {orderNoteEditable && (
                        <CloseIcon
                          className="delete-attachment"
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteAttachment(item);
                          }}
                        />
                      )}
                      <FileIcon url={item} />
                    </div>
                  );
                })}
              <div id="attachment-loader" className="attachment-loader-hide">
                <CircularProgress color="success" />
              </div>
            </div>
            <MediaDialog
              dialogMedia={dialogMedia}
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
            />
            {orderNoteData.hasOwnProperty("id") && (
              <OrderNotesCommentSection
                orderNoteData={orderNoteData}
                usersDetails={usersDetails}
                orderNoteEditable={orderNoteEditable}
              />
            )}
            </>

            <div className="ordernote-buttons-container">
              <Button
                className="ottermap-button-outlined"
                onClick={onCloseClick}
              >
                Cancel
              </Button>
              {orderNoteEditable && (
                <Button className="ottermap-button" type="submit">
                  Submit
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}