import sign from "jwt-encode";

const secret = process.env.REACT_APP_AUTHTOKEN_SECRET_KEY;
// console.log(secret,"secret")

const EncodeJwt = (data) => {
        return sign(data, secret);
    };
export const encodeJwtToken=()=>{
    const payload = {
      req_time: new Date().toISOString()
    };
    return  EncodeJwt(payload);
    
  }

// export const EncodeJwt = (data) => {
//     return sign(data, secret);
// };