import axios from 'axios';
import AuthToken from '../../context/AuthToken';
import { ORDER_URL } from '../../helper/ApiUrl';
import {  encodeJwtToken } from '../../utils/EncodeJwt';

  // const encodeJwtToken=()=>{
  //   const payload = {
  //     req_time: new Date().toISOString()
  //   };
  //   return  EncodeJwt(payload);
    
  // }

const getLayersData = (
  id,
  viewId,
  searchParams,
  setResetLayer,
  setLayersData,
  setSearchParams,
  setUploadedLayer
) => {
  const token = AuthToken();

  axios
    .get(`${ORDER_URL}${id}/view/${viewId}/layer/info`, {
      headers: {
        "x-api-key": `${encodeJwtToken()}`,
        Accept: 'application/json',
      },
    })
    .then((response) => {
      setResetLayer([]);
      setUploadedLayer([]);

      // Retain all existing query parameters
      const newSearchParams = new URLSearchParams(searchParams);

        newSearchParams.set('view_id', viewId);
      

      setSearchParams(newSearchParams);
      setLayersData(response.data.data);
    })
    .catch((error) => {
      console.error(error.message);
    });
};

export default getLayersData;
