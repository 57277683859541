import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import './MobileDraftProgressPanel.css';
import React, { useState } from 'react';
import { Global } from '@emotion/react';
import Stepper from '@mui/material/Stepper';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import StepLabel from '@mui/material/StepLabel';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {STATUS} from './../../utils/constants.js';
const drawerBleeding = 56;
const steps = ['1', '2', '3', '4'];

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));

const MobileDraftProgressPanel = ({
    step,
    setStep,
    open,
    setOpen,
    parcelSelection,
    featureSelection,
    placeOrder,
    clearedSteps,
    setClearedSteps,
    status,
}) => {

    const handleStep = (label) => {
        let newLabel = Number(label);

        if(status === STATUS.IN_PROGRESS) {
            return;
        }
        if(newLabel > 1) {
            if(newLabel === 2) {
                if(clearedSteps.includes(2)) {
                    setStep(2)
                    setClearedSteps([1, 2])
                }
            }else if (newLabel === 3) {
                if(clearedSteps.includes(3)) {
                    setStep(3)
                    setClearedSteps([1, 2, 3])
                }
            }
        }else if (newLabel === 4) {

            if(clearedSteps.includes(4)) {
                setStep(4)
                setClearedSteps([1, 2, 3, 4])
            }
        }


      console.log("hello kitty")
    }


    return (
        <div className='mobile-draft-progress-main-container'>
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        height: '30%',
                        overflow: 'visible',
                    },
                    '.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop': {
                        display: "none !important"
                    },
                    '.css-9emuhu-MuiPaper-root-MuiDrawer-paper': {
                        overflowY: 'visible',
                    },
                    '.css-919eu4': {
                        display: "none !important"
                    }
                }}
            />
            <SwipeableDrawer
                anchor="bottom"
                open={open}
                disableSwipeToOpen={false}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                swipeAreaWidth={drawerBleeding}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <StyledBox
                    sx={{
                        right: 0,
                        left: 0,
                        position: 'absolute',
                        visibility: 'visible',
                        top: '-63px',
                        height: "130%",
                        overflowY: "auto",
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                    }}
                >
                    <Puller />
                    <Stepper
                        className='stepper-cont'
                        activeStep={step - 1}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            return (
                                <Step key={label} {...stepProps} onClick={() => handleStep(label)}>
                                    <StepLabel {...labelProps}></StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    {step === 2 ? parcelSelection : step === 3 ? featureSelection : step === 4 ? placeOrder : null}
                </StyledBox>
            </SwipeableDrawer>
        </div>
    )
}

export default MobileDraftProgressPanel