import Map from 'ol/Map';
import axios from 'axios';
import View from 'ol/View';
import { Feature } from 'ol';
import './OpenLayersPage.css';
import XYZ from 'ol/source/XYZ';
import { Style } from 'ol/style';
import Icon from 'ol/style/Icon';
import Point from 'ol/geom/Point';
import { toLonLat } from 'ol/proj';
import { transform } from 'ol/proj';
import TileLayer from 'ol/layer/Tile';
import LayerGroup from 'ol/layer/Group';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import { defaults } from 'ol/control/defaults';
import AuthToken from '../../context/AuthToken';
import '../OpenLayersIdPage/OpenLayersIdPage.css';
import markerIcon from '../../assets/location.svg';
import CreateIcon from '@mui/icons-material/Create';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { useLoader } from '../../context/LoaderProvider';
import { useScreenType } from '../../utils/commonMethods';
import { CREATE_PROPERTY_URL } from '../../helper/ApiUrl';
import MobileOpenLayersPage from './MobileOpenLayersPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useEffect, useRef, useState } from 'react';
import usePlacesAutocomplete from 'use-places-autocomplete';
import NavigationIcon from '@mui/icons-material/Navigation';
import rotateMap from '../../OpenLayers/OLControls/RotateMap';
import { useAddressContext } from '../../context/AddressContext';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import { ReactComponent as CheckIcon } from '../../assets/Check.svg';
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';
import AlternateOrderPage from '../AlternateOrderPage/AlternateOrderPage';
import resetRotation from '../../OpenLayers/OLControls/MapRotationControl';
import { ReactComponent as PropertyIcon } from '../../assets/Property.svg';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import LayersTypeContainer from '../../components/LayersTypeContainer/LayersTypeContainer';

const OpenLayersPage = ({ handleError, handleSuccess }) => {
  const markerRef = useRef();
  const [direction, setDirection] = useState();

  markerRef.current = new LayerGroup({
    title: 'Marker',
    layers: [],
  });
  const navigate = useNavigate();
  const mapElement = useRef();
  const isMobile = useScreenType();
  const [map, setMap] = useState();
  const { address, latLng, setAddress } = useAddressContext();
  const [updatedAddress, setUpdatedAddress] = useState('');
  const { startLoader, stopLoader } = useLoader();
  const [center, setCenter] = useState([0, 0]);
  const {
    value,
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: ['us', 'CA'] },
    },
  });

  const initializeMap = () => {
    if (address) {
      const initialMap = new Map({
        view: new View({
          zoom: 19,
        }),
        layers: [
          new TileLayer({
            source: new XYZ({

              url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}',

              crossOrigin: "Anonymous",
            }),
            visible: true,
            title: 'Satellite',
          }),
          new TileLayer({
            source: new XYZ({

              url: 'http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}',
              crossOrigin: "Anonymous",
            }),
            visible: false,
            title: 'Road',
          }),
        ],
        target: mapElement.current,
        keyboardEventTarget: document,
        controls: defaults({
          zoom: false,
          attribution: false,
          rotate: false,
        }),
      });
      setMap(initialMap);
      setCenter(latLng);
    }
  };

  useEffect(() => {
    initializeMap();
  }, [latLng]);

  const setProperty = () => {
    if (map) {
      map.getView().animate(
          { zoom: 19 },
          { duration: 1000 },
          { center: transform(center, 'EPSG:4326', 'EPSG:3857') }
        );
      if (map.getLayers().getArray().length > 1) {
        map.getLayers().forEach((layer) => {
          if (layer.get('title') && layer.get('title') === 'Marker') {
            map.removeLayer(layer);
          }
        });
      }
      map.addLayer(markerRef.current);
      const iconFeature = new Feature({
        geometry: new Point(transform(center, 'EPSG:4326', 'EPSG:3857')),
      });
      const iconStyle = new Style({
        image: new Icon({
          anchor: [0.5, 46],
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          src: markerIcon,
        }),
      });
      iconFeature.setStyle(iconStyle);
      const vectorSource = new VectorSource({
        features: [iconFeature],
      });
      const vectorLayer = new VectorLayer({
        source: vectorSource,
      });
      markerRef.current.getLayers().push(vectorLayer);
    }
  };

  useEffect(() => {
    setProperty();
  }, [center]);

  if (map) {
    map.on('click', function (e) {
      setCenter(toLonLat(e.coordinate));
    });
  }

  const handleClick = () => {
    startLoader();
    let formData = new FormData();
    formData.append('address', address);
    formData.append('lat', center[1]);
    formData.append('long', center[0]);
    axios({
      url: CREATE_PROPERTY_URL,
      method: 'POST',
      data: formData,
      headers: {
        Authorization: `Token ${AuthToken()}`,
      },
    })
      .then((res) => {
        if ('data' in res) {
          const order_id = res.data.data.order_id;
          stopLoader();
          navigate(`/order/${order_id}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleZoomIn = () => {
    map.getView().animate({
      zoom: map.getView().getZoom() + 1,
      duration: 250,
    });
  };

  const handleZoomOut = () => {
    map.getView().animate({
      zoom: map.getView().getZoom() - 1,
      duration: 250,
    });
  };

  const handleLayerTypeMap = () => {
    map.getLayers().forEach((layer) => {
      if (layer.get('title') && layer.get('title') === 'Satellite') {
        layer.values_.visible = false;
      } else if (layer.get('title') && layer.get('title') === 'Road') {
        layer.values_.visible = true;
      }
    });
    map.getView().animate({});
  };

  const handleLayerTypeSatellite = () => {
    map.getLayers().forEach((layer) => {
      if (layer.get('title') && layer.get('title') === 'Road') {
        layer.values_.visible = false;
      } else if (layer.get('title') && layer.get('title') === 'Satellite') {
        layer.values_.visible = true;
      }
    });
    map.getView().animate({});
  };

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    setAddress(address);
    const updatedLatLng = address.split(',');
    setCenter([parseFloat(updatedLatLng[1]), parseFloat(updatedLatLng[0])]);
  };

  const latLongHandler = () => {
    const regExp =
      /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
    if (regExp.test(value)) {
      handleSelect(value);
    }
    setValue('');
  };

  const updatedAddressHandler = () => {
    if (updatedAddress) {
      setAddress(updatedAddress);
      setUpdatedAddress('');
    }
  };

  if (map) {
    const view = map.getView();

    view.on('propertychange', (event) => {
      if (event.key === 'rotation') {
        const rotation = view.getRotation();
        const rotationInDegrees = (rotation * 180) / Math.PI;
        setDirection(rotationInDegrees);
      }
    });
  }

  const prepareOrder = (
    <div className="confirm-property-container">
      <div className="confirm-property-tips">
        <div className="property-tips-one">Confirm Property</div>
        <div className="property-tips-two">
          <PropertyIcon className="property-icon" />
          <p>
            {address
              ? address
              : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'}
          </p>
          <CreateIcon
            onClick={() => setAddress(null)}
            className="create-icon"
          />
        </div>
        <div className="property-tips-three">
          <p>Incase the pin is on incorrect Property?</p>
          <p>
            If you can spot the property on your screen, just drop the pin with
            a click on it and Confirm Address
          </p>
        </div>
      </div>
      <div className="confirm-property-address">
        <div className="property-address-one">
          If you cannot locate the property on the map, please search the
          property using Lat Long Coordinates.
          <span className="address-one-span">
            (Alternatively you can pick property coordinates using Google Maps)
          </span>
        </div>
        <div className="property-address-two">
          <input
            type="text"
            value={value}
            onChange={handleInput}
            placeholder="Enter Lat Lang"
          />
          <button
            className={value.length && 'find-button'}
            disabled={!value.length}
            onClick={latLongHandler}
          >
            Find
          </button>
          <input
            type="text"
            value={updatedAddress}
            onChange={(e) => setUpdatedAddress(e.target.value)}
            placeholder="Update Address"
          />
          <button
            className={updatedAddress.length && 'save-button'}
            disabled={!updatedAddress.length}
            onClick={updatedAddressHandler}
          >
            Save
          </button>
        </div>
      </div>
      <div className="confirm-property-button">
        <button className='ottermap-button' style={{width: "100%"}} onClick={handleClick}>
          <CheckIcon className="check-icon" /> <span>Confirm Property</span>
        </button>
      </div>
    </div>
  );

  if (!address)
    return (
      <AlternateOrderPage
        handleError={handleError}
        handleSuccess={handleSuccess}
      />
    );
    
  else
    return (
      <>
        <div className="ol-order-page">
          {isMobile ? (
            <MobileOpenLayersPage step={1} prepareOrder={prepareOrder} />
          ) : (
            <>
              <ProgressBar step={1} />
              {prepareOrder}
            </>
          )}
          <div ref={mapElement} className="main-ol-container">
            {isMobile ? (
              <div className="backToDashboard-mobile-container">
                <button
                  className="backToDashboard-mobile-button"
                  onClick={() => navigate(`/home`)}
                >
                  <ArrowBackIcon />
                </button>
                <div className="mobile-address-container">
                  <span>{address}</span>
                </div>
              </div>
            ) : null}
          </div>

          <div className="map-buttons hideOnScreenshot">
            <div className="map-layer-button">
              <LayersTypeContainer
                map={map}
                handleLayerTypeMap={handleLayerTypeMap}
                handleLayerTypeSatellite={handleLayerTypeSatellite}
              />
              <div className="map-rotation-buttons">
                <Tooltip title="Rotate map 90deg left" placement="top-start">
                  <Rotate90DegreesCcwIcon
                    className="map-control-icon"
                    onClick={() => rotateMap(map, -90)}
                  />
                </Tooltip>
                <Tooltip title="Rotate map 90deg right" placement="top-start">
                  <Rotate90DegreesCwIcon
                    className="map-control-icon"
                    onClick={() => rotateMap(map, 90)}
                  />
                </Tooltip>
              </div>
            </div>
            <div className="map-zoom-buttons">
              <Tooltip
                title="Use Alt/Option+Shift and Drag to rotate the map. Press this button to reset."
                placement="top"
              >
                <button onClick={() => resetRotation(map)}>
                  <NavigationIcon
                    className="map-control-icon"
                    sx={{ rotate: `${direction}deg` }}
                  />
                </button>
              </Tooltip>
              <button onClick={() => handleZoomIn(map)}>
                <ZoomInIcon className="map-control-icon" />
              </button>
              <button onClick={() => handleZoomOut(map)}>
                <ZoomOutIcon className="map-control-icon" />
              </button>
            </div>
          </div>
        </div>
      </>
    );
};

export default OpenLayersPage;
