import axios from "axios";
import AuthToken from "../../context/AuthToken";
import { ORDER_URL } from "../../helper/ApiUrl";
import { encodeJwtToken } from "../../utils/EncodeJwt";

const updateLayerData = (id, viewId, layerId, data, featureId) => {
  const token = AuthToken();
  axios
    .put(
      `${ORDER_URL}${id}/view/${viewId}/layer/${layerId}/layer-data/${featureId}`,
      data,
      {
        headers: {
          // Authorization: `Token ${token}`,
          "x-api-key":`${encodeJwtToken()}`,
          Accept: "application/json",
        },
      }
    )
    .then((response) => {
      // console.log(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default updateLayerData;
