import './OrdersTable.css';
import Menu from '@mui/material/Menu';
import { useNavigate } from 'react-router-dom';
import React, { useRef, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Tooltip from '@mui/material/Tooltip';
import Skeleton from 'react-loading-skeleton';
import MenuItem from '@mui/material/MenuItem';
import NoteIcon from '@mui/icons-material/Note';
import Accordion from '@mui/material/Accordion';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterListIcon from '@mui/icons-material/FilterList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfiniteScroll from 'react-infinite-scroll-component';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { STATUS_VAL, TOAST_TYPE } from '../../utils/constants';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { Button, Checkbox } from '@mui/material';
import OutlinedInput from '@mui/material/TextField';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import useMediaQuery from '@mui/material/useMediaQuery';
import PopUpBox from './../PopUpBox/PopUpBox.jsx';


export default function OrdersMobileTable({
  tagRef,
  orderTags,
  removeTagOnClick,
  suggestionRef,
  tagName,
  addTagOnEnter,
  setTagName,
  finalTagArray,
  handleSearch,
  addTagOnClick,
  allTags,
  orderList,
  usersDetails,
  userId,
  setUserId,
  handleUserFilter,
  setMinArea,
  setMaxArea,
  handleAreaFilter,
  setPageNumber,
  pageNumber,
  pageCount,
  setActionId,
  setActionStatus,
  options,
  actionStatus,
  handleSuccess,
  setOrderID,
  activeTag,
  setActiveTag,
  IconWithText,
  status,
  setStatus,
  deleteDraftOrder,
  handleStatusFilter,
  clearAllFilter
}) {
  const navigate = useNavigate();
  const isSmallerThan820px = useMediaQuery('(max-width:820px)');
  const [anchorEm, setAnchorEm] = useState();
  const open = Boolean(anchorEm);
  const deleteOrderRef = useRef();
  const deleteOrderInnerRef = useRef();
  const [expanded, setExpanded] = useState(false);
  const [filterAnchor, setFilterAnchor] = useState(null);

  const handleAccordianChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const filterOpen = Boolean(filterAnchor);

  const handleFocus = () => {
    suggestionRef.current.style.display = 'block';
  };

  const handleClose = () => {
    setAnchorEm(null);
  };

  return (
    <div className="orders-mobile-dashboard-table">
      {/* <div ref={deleteOrderRef} className="no-layers-info-container">
        <div
          ref={deleteOrderInnerRef}
          className="delete-layer-inner-modal"
          style={{ width: '70%', left: '50%' }}
        >
          <div className="view-modal-top">
            <span
              onClick={() => {
                deleteOrderRef.current.style.opacity = 0;
                deleteOrderRef.current.style.pointerEvents = 'none';
              }}
            >
              X
            </span>
          </div>
          <div className="no-layer-mid-container">
            <p>
              Are you sure? You want to delete this order! This action can't be
              undone.
            </p>
          </div>
          <div className="view-modal-bttns">
            <button
              onClick={() => {
                deleteOrderRef.current.style.opacity = 0;
                deleteOrderRef.current.style.pointerEvents = 'none';
              }}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                deleteDraftOrder();
                deleteOrderRef.current.style.opacity = 0;
                deleteOrderRef.current.style.pointerEvents = 'none';
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </div> */}

      <PopUpBox
        //imageSrc={}
        title="Delete order?"
        message="Are you sure? You want to delete this order! This action can't be undone."
        additionalMsg ="Only DIY and Draft orders can be deleted."
        leftBtnText="Cancel"
        rightBtnText="Delete"
        onLeftBtnfunc={() => {document.querySelector(".wrapperPopUpBox").style.display = "none"}}
        onRightBtnfunc={ () => {deleteDraftOrder(); document.querySelector(".wrapperPopUpBox").style.display = "block" }}
      /> 
      {!orderList ? (
        <Skeleton
          count={10}
          style={{
            marginBottom: '33px',
          }}
        />
      ) : orderList.length < 1 ? (
        <div className="alternate-table-container">
          <h3>No property found :(</h3>
          <button onClick={() => navigate('/order')}>Place Order</button>
        </div>
      ) : (
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button className='ottermap-button-outlined' sx={{border: "none !important"}} onClick={clearAllFilter}>
              <FilterListOffIcon className="clear-filter-icon" />
              Clear Filters
            </Button> 
            <div
              style={{ width: 'fit-content', cursor: "pointer" }}
              onClick={(e) => setFilterAnchor(e.currentTarget)}
              className="filter-sort-cont"
            >
              <FilterAltIcon className="filter-sort-icon" />
              <span>Filters</span>
            </div>

            <Drawer
              id="filter-accordian-div"
              anchor="left"
              open={filterOpen}
              onClose={() => setFilterAnchor(null)}
            >
              <h3 style={{ marginLeft: '0.5em' }}>Filter By</h3>
              <div style={{ width: '280px', background: 'white' }}>
                <Accordion
                  expanded={expanded === 'panel1'}
                  onChange={handleAccordianChange('panel1')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    style={{ margin: '0' }}
                  >
                    Status
                  </AccordionSummary>
                  <AccordionDetails sx={{ backgroundColor: "#E8F6E0 !important"}}>
                    <div>
                      <div
                        className="order-status-filter-one"
                        // style={{ margin: '0.5em 0' }}
                        onClick={() => {
                          if (status.includes('draft')) {
                            setStatus(status.filter((e) => e !== 'draft'));
                          } else {
                            setStatus([...status, 'draft']);
                          }
                        }}
                      >
                        <Checkbox
                          // style={{ marginRight: '1em' }}
                          name="draft"
                          size='small'
                          color='success'
                          checked={status.includes('draft') ? true : false}
                          onChange={() => {
                            if (status.includes('draft')) {
                              setStatus(status.filter((e) => e !== 'draft'));
                            } else {
                              setStatus([...status, 'draft']);
                            }
                          }}
                        />
                        <span style={{fontSize: "14px"}}>Draft</span>
                      </div>
                      <div
                        className="order-status-filter-two"
                           size='small'
                          color='success'
                        onClick={() => {
                          if (status.includes('in_progress')) {
                            setStatus(
                              status.filter((e) => e !== 'in_progress')
                            );
                          } else {
                            setStatus([...status, 'in_progress']);
                          }
                        }}
                      >
                        <Checkbox
                             size='small'
                          color='success'
                          name="in_progress"
                          checked={
                            status.includes('in_progress') ? true : false
                          }
                          onChange={() => {
                            if (status.includes('in_progress')) {
                              setStatus(
                                status.filter((e) => e !== 'in_progress')
                              );
                            } else {
                              setStatus([...status, 'in_progress']);
                            }
                          }}
                        />
                        <span style={{fontSize: "14px"}}>Inprogress</span>
                      </div>
                      <div
                        className="order-status-filter-three"
                        onClick={() => {
                          if (status.includes('measurement_completed')) {
                            setStatus(
                              status.filter(
                                (e) => e !== 'measurement_completed'
                              )
                            );
                          } else {
                            setStatus([...status, 'measurement_completed']);
                          }
                        }}
                      >
                        <Checkbox
                            size='small'
                          color='success'
                          name="measurement_completed"
                          checked={
                            status.includes('measurement_completed')
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            if (status.includes('measurement_completed')) {
                              setStatus(
                                status.filter(
                                  (e) => e !== 'measurement_completed'
                                )
                              );
                            } else {
                              setStatus([...status, 'measurement_completed']);
                            }
                          }}
                        />
                        <span style={{fontSize: "14px"}}>Completed</span>
                      </div>
                      <div
                        className="order-status-filter-three"
                        onClick={() => {
                          if (status.includes('diy')) {
                            setStatus(status.filter((e) => e !== 'diy'));
                          } else {
                            setStatus([...status, 'diy']);
                          }
                        }}
                      >
                        <Checkbox
                          size='small'
                          color='success'
                          name="diy"
                          checked={status.includes('diy') ? true : false}
                          onChange={(e) => {
                            if (status.includes('diy')) {
                              setStatus(status.filter((e) => e !== 'diy'));
                            } else {
                              setStatus([...status, 'diy']);
                            }
                          }}
                        />
                        <span style={{fontSize: "14px"}}>DIY</span>
                      </div>
                      <Button
                        style={{ marginTop: '1em' }}
                        className='ottermap-button'
                        onClick={() => {
                          if (status.length > 0) {
                            handleStatusFilter();
                            setFilterAnchor(null);
                          }
                        }}
                      >
                        ✔ Apply
                      </Button>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === 'panel2'}
                  onChange={handleAccordianChange('panel2')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                  >
                    Created By
                  </AccordionSummary>
                  <AccordionDetails  sx={{ backgroundColor: "#E8F6E0 !important", padding: "10px"}}>
                    <div style={{ maxHeight: '8em', overflowY: 'auto' }}>
                      {usersDetails &&
                        usersDetails.map((user) => {
                          return (
                            <div
                              className="order-status-filter-one"
                              onClick={() => {
                                if (userId.includes(`${user.id}`)) {
                                  setUserId(
                                    userId.filter((e) => e !== `${user.id}`)
                                  );
                                } else {
                                  setUserId([...userId, `${user.id}`]);
                                }
                              }}
                            >
                              <Checkbox
                                checked={
                                  userId.includes(`${user.id}`) ? true : false
                                }
                                name={user.id}
                                size="small"
                                color='success'
                                onChange={(e) => {
                                  if (userId.includes(`${user.id}`)) {
                                    setUserId(
                                      userId.filter((e) => e !== `${user.id}`)
                                    );
                                  } else {
                                    setUserId([...userId, `${user.id}`]);
                                  }
                                }}
                              />
                              <span style={{fontSize: "14px"}}>{user.name}</span>
                            </div>
                          );
                        })}
                    </div>
                    <Button
                      className="ottermap-button"
                      style={{ marginTop: '1em' }}
                      onClick={() => {
                        handleUserFilter();
                        setFilterAnchor(null);
                      }}
                    >
                      ✔ Apply
                    </Button>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === 'panel3'}
                  onChange={handleAccordianChange('panel3')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                  >
                    Area (acres)
                  </AccordionSummary>
                  <AccordionDetails  >
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: "column",
                        gap: "10px",
                        justifyContent: 'start',
                      }}
                    >
                        <OutlinedInput
                          sx={{ width: '100%' }}
                          type="text"
                          onChange={(e) => setMinArea(e.target.value)}
                          placeholder="Min area"
                        />
                      <span>to</span>
                        <OutlinedInput
                          sx={{ width: '100%' }}
                          type="text"
                          onChange={(e) => setMaxArea(e.target.value)}
                          placeholder="Max area"
                        />
                    </div>
                    <Button
                      style={{ marginTop: '1em' }}
                      className='ottermap-button'
                      onClick={() => {
                        handleAreaFilter();
                        setFilterAnchor(null);
                      }}
                    >
                      ✔ Apply
                    </Button>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Drawer>

            {/* <div className="filter-sort-cont">
              <FilterListIcon />
              <span>Sort</span>
            </div> */}
          </div>
          <div id="scrollableDiv">
            <InfiniteScroll
              dataLength={orderList.length}
              next={() => setPageNumber(pageNumber + 1)}
              hasMore={pageNumber === pageCount ? false : true}
              loader={
                <h5 style={{ textAlign: 'center' }}>Fetching more Orders...</h5>
              }
              endMessage={
                <h5 style={{ textAlign: 'center' }}>You have reached end</h5>
              }
              scrollableTarget="scrollableDiv"
            >
              {orderList.map((order) => {
                return (
                  <a
                    key={order.id}
                    style={{ textDecoration: 'none' }}
                    href={`/order/${order.order_id}`}
                  >
                    <div className="db-order-item">
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <span>ID: {order.id}</span>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <div
                            className={
                              STATUS_VAL[order.status] === 'DRAFT'
                                ? 'draft-tag-color order-cell'
                                : STATUS_VAL[order.status] === 'IN PROGRESS'
                                ? 'inprogress-tag-color order-cell'
                                : STATUS_VAL[order.status] ===
                                  'MEASUREMENT COMPLETED'
                                ? 'completed-tag-color order-cell'
                                : STATUS_VAL[order.status] === 'DIY'
                                ? 'diy-tag-color order-cell'
                                : 'order-cell'
                            }
                          >
                            {STATUS_VAL[order.status] ===
                            'MEASUREMENT COMPLETED'
                              ? 'COMPLETED'
                              : STATUS_VAL[order.status]}
                          </div>
                          <div style={{ color: '#ffffff' }}>
                            <IconButton
                              style={{ color: 'white' }}
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? 'long-menu' : undefined}
                              aria-expanded={open ? 'true' : undefined}
                              aria-haspopup="true"
                              onClick={(e) => {
                                e.preventDefault();
                                setActionId(order.order_id);
                                setActionStatus(order.status);
                                setAnchorEm(e.currentTarget);
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              id="long-menu"
                              MenuListProps={{
                                'aria-labelledby': 'long-button',
                              }}
                              anchorEl={anchorEm}
                              open={open}
                              onClose={handleClose}
                              PaperProps={{
                                sx: {
                                  boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2)', // Custom box-shadow
                                },
                                style: {
                                  maxHeight: 48 * 4.5,
                                  // width: '20ch',
                                },
                              }}
                            >
                              {options.map((option) => (
                                <MenuItem
                                  
                                  // disabled={
                                  //   actionStatus === 'draft' ? false : true
                                  // }
                                  key={option}
                                  onClick={() => {
                                    handleClose();
                                    if (actionStatus === 'draft' || actionStatus === 'diy') {
                                      // deleteOrderRef.current.style.opacity = 1;
                                      // deleteOrderRef.current.style.pointerEvents =
                                      //   'auto';
                                      document.querySelector(".wrapperPopUpBox").style.display = "block";
                                    } else {
                                      handleSuccess(
                                        TOAST_TYPE.WARNING,
                                        'You can only delete draft Orders!'
                                      );
                                    }
                                  }}
                                  selected={option === 'Pyxis'}
                                  className="order-action-menu-item"
                                >
                                  {option}
                                </MenuItem>
                              ))}
                            </Menu>
                          </div>
                        </div>
                      </div>
                      <div>
                        {order.address.length < 40 ? (
                          <h3 style={{margin: "0px"}}>{order.address}</h3>
                        ) : (
                          <h3 style={{margin: "0px"}}>{order.address.slice(0, 40)}...</h3>
                        )}
                      </div>
                      <div >
                        Area: {order.area} acres
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div>Creator: {order.creator}</div>
                        <div>{order.created_at.slice(0, 10)}</div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            color: '#ffffff',
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '0.8em',
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            setOrderID(order.order_id);
                            tagRef.current.style.display = 'block';
                          }}
                        >
                          <span style={{ marginRight: '0.5em' }}>Add Tags</span>
                          <LocalOfferOutlinedIcon
                            style={{ color: '#f9f490', height: '0.8em' }}
                            className="tag-icon"
                          />
                          {order.notes > 0 ? (
                            <IconWithText
                              text={order.notes}
                              iconName={
                                <NoteIcon
                                  sx={{
                                    color: `#1B9C85`,
                                  }}
                                />
                              }
                            />
                          ) : null}
                        </div>
                        <div
                          style={{
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            width: 'auto',
                          }}
                          className="address-div-one-bottom"
                        >
                          {order.tags &&
                            order.tags.map((tag, index) => {
                              if (index < 2) {
                                return (
                                  <span
                                    key={index}
                                    style={{
                                      fontWeight: 600,
                                      color: '#06372a',
                                      marginTop: '5px',
                                      background: '#f9f490',
                                      width: '4em',
                                      padding: '0.3em 0.5em',
                                      textAlign: 'center',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      margin: '0 0.2em 0 0',
                                      textOverflow: 'ellipsis',
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setActiveTag(!activeTag);
                                      setOrderID(order.order_id);
                                      tagRef.current.style.display = 'block';
                                    }}
                                  >
                                    {tag}
                                  </span>
                                );
                              }
                            })}
                          {order.tags && order.tags.length > 2 ? (
                            <div
                              className="extra-tags-count"
                              onClick={(e) => {
                                e.preventDefault();
                                setActiveTag(!activeTag);
                                setOrderID(order.order_id);
                                tagRef.current.style.display = 'block';
                              }}
                            >
                              +{order.tags.length - 2}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </a>
                );
              })}
            </InfiniteScroll>
          </div>
        </div>
      )}
    </div>
  );
}
