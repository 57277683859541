import axios from 'axios';
import '../Project/Project.css';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import AuthToken from '../../context/AuthToken';
import ProgressBar from '../Project/ProgressBar';
import ClearIcon from '@mui/icons-material/Clear';
import 'react-loading-skeleton/dist/skeleton.css';
import IconButton from '@mui/material/IconButton';
import { useSearchParams } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { useRef, useState, useEffect } from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import { FormControlLabel, FormGroup } from '@material-ui/core';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { MOLE_ORDER_URL, FETCH_FEATURE_URL } from '../../helper/ApiUrl';
import AddAttachment from '../../components/AddAttachment/AddAttachment';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ORDER_STATUS, TOAST_TYPE, OPS_STATUS } from '../../utils/constants';
import Checkbox from '@mui/material/Checkbox';


import Pagination from "../../components/Pagination/Pagination";
import { encodeJwtToken } from '../../utils/EncodeJwt';


const UploadBulkProject = ({ handleSuccess, handleError }) => {
  const keys = ['name'];
  const dateRef = useRef();
  const innerRef = useRef();
  const outerRef = useRef();
  const outerStatusRef = useRef();
  const innerStatusRef = useRef();
  const loaderRef = useRef();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState();
  const [anchorEl2, setAnchorEl2] = useState();
  const [anchorEl3, setAnchorEl3] = useState();
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);
  const [query, setQuery] = useState('');
  const [action, setAction] = useState('');
  const [actionStatus, setActionStatus] = useState('');
  const [orderId, setOrderId] = useState('');
  const [openDate, setOpenDate] = useState(false);
  const [projectList, setProjectList] = useState();
  const [isCreated, setIsCreated] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [projectName, setProjectName] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const end_Date = searchParams.get('end_date') || '';
  const start_Date = searchParams.get('start_date') || '';
  const [snowFeatures, setSnowFeatures] = useState(false);
  const [featureSelected, setFeatureSelected] = useState([]);
  const [endDate, setEndDate] = useState({ endDate: end_Date });
  const [availableFeatures, setAvailableFeatures] = useState([]);
  const [projectDescription, setProjectDescription] = useState('');
  const [landscapeFeatures, setLandscapeFeatures] = useState(false);
  const [startDate, setStartDate] = useState({ startDate: start_Date });

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl2(null);
    setAnchorEl3(null);
  };

  const search = (data) => {
    return data.filter((item) =>
      keys.some((key) => item[key].toLowerCase().includes(query))
    );
  };

  const getAvailableFeatures = () => {
    axios({
      url: FETCH_FEATURE_URL,
      method: 'GET',
      headers: {
        Authorization: `Token ${AuthToken()}`,
      },
    })
      .then((res) => {
        if ('data' in res) {
          const data = res.data.data;
          setAvailableFeatures([...data]);
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };

  useEffect(() => {
    getAvailableFeatures();
  }, []);

  const checkCheckBox = (id) => {
    let flag = false;
    featureSelected.map((item) => {
      if (id === item.id) flag = true;
    });
    return flag;
  };

  useEffect(() => {
    if (featureSelected?.length > 0) {
      const allSnowFeaturesSelected = availableFeatures
        .filter((feature) => feature.segment === 'snow')
        .every((snowFeature) =>
          featureSelected.some((selected) => selected.id === snowFeature.id)
        );
      if (allSnowFeaturesSelected) setSnowFeatures(true);
      const allLandscapeFeaturesSelected = availableFeatures
        .filter((feature) => feature.segment === 'landscape')
        .every((landscapeFeature) =>
          featureSelected.some(
            (selected) => selected.id === landscapeFeature.id
          )
        );
      if (allLandscapeFeaturesSelected) setLandscapeFeatures(true);
    }
  }, [featureSelected]);

  const selectSnowFeatures = () => {
    let snowFeaturesArr = [];
    if (!snowFeatures) {
      availableFeatures.map((feature) => {
        if (feature.segment === 'snow' && !featureSelected.includes(feature)) snowFeaturesArr.push(feature);
      });
      setFeatureSelected([...featureSelected, ...snowFeaturesArr]);
      setSnowFeatures(true);
    } else {
      const updatedFeatureSelected = featureSelected.filter((selected) => {
        return !availableFeatures.some(
          (available) =>
            available.segment === 'snow' && available.id === selected.id
        );
      });
      setFeatureSelected(updatedFeatureSelected);
      setSnowFeatures(false);
    }
  };

  const selectLandscapeFeatures = () => {
    let landscapeFeaturesArr = [];
    if (!landscapeFeatures) {
      availableFeatures.map((feature) => {
        if (feature.segment === 'landscape' && !featureSelected.includes(feature)) landscapeFeaturesArr.push(feature);
      });
      setFeatureSelected([...featureSelected, ...landscapeFeaturesArr]);
      setLandscapeFeatures(true);
    } else {
      const updatedFeatureSelected = featureSelected.filter((selected) => {
        return !availableFeatures.some(
          (available) =>
            available.segment === 'landscape' && available.id === selected.id
        );
      });
      setFeatureSelected(updatedFeatureSelected);
      setLandscapeFeatures(false);
    }
  };

  const getProjectList = () => {
    axios({
      url: `${MOLE_ORDER_URL}?all=true`,
      method: 'GET',
      headers: {
        Authorization: `Token ${AuthToken()}`,
      },
    })
      .then((res) => {
        setProjectList(res.data.data);
        setRowCount(res.data.count); // for pagination
        setPageCount(res.data.total_pages);// for pagination
      })
      .catch((err) => {
        handleError(err);
      });
  };

  useEffect(() => {
    getProjectList();
  }, [isCreated]);

  const createProject = () => {
    if (
      !projectName ||
      !projectDescription ||
      !(featureSelected && featureSelected.length > 0)
    ) {
      let errorMessage = 'Please fill in the following fields:';
      let isFirstEmptyField = true;

      if (!projectName) {
        errorMessage += isFirstEmptyField ? ' Project Name' : ', Project Name';
        isFirstEmptyField = false;
      }

      if (!projectDescription) {
        errorMessage += isFirstEmptyField
          ? ' Project Description'
          : ', Project Description';
        isFirstEmptyField = false;
      }

      if (!(featureSelected && featureSelected.length > 0)) {
        errorMessage += isFirstEmptyField
          ? ' Feature Selection'
          : ', Feature Selection';
        isFirstEmptyField = false;
      }

      handleSuccess(TOAST_TYPE.WARNING, errorMessage);
      return;
    }

    const featureIds = featureSelected.map((item) => item.id) || [];

    const projectData = {
      name: projectName,
      feature_ids: featureIds,
      description: projectDescription,
    };

    axios({
      url: MOLE_ORDER_URL,
      method: 'POST',
      data: projectData,
      headers: {
        Authorization: `Token ${AuthToken()}`,
      },
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setProjectName('');
          setSnowFeatures(false);
          setFeatureSelected([]);
          setIsCreated(!isCreated);
          setProjectDescription('');
          setLandscapeFeatures(false);
          navigate(`/project/${res.data.data.id}`);
        } else {
          handleError(TOAST_TYPE.ERROR, 'Something went wrong!');
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const updateProject = () => {
    if (projectName && projectDescription && featureSelected?.length > 0) {
      const featureIds = [];

      featureSelected.map((item) => {
        featureIds.push(item.id);
      });

      const projectData = {
        name: projectName,
        // feature_ids: featureIds,
        description: projectDescription,
      };

      axios({
        url: MOLE_ORDER_URL,
        method: 'PATCH',
        data: projectData,
        headers: {
          Authorization: `Token ${AuthToken()}`,
        },
      })
        .then((res) => {
          if (res.status === 200 || 201) {
            setProjectName('');
            setSnowFeatures(false);
            setFeatureSelected([]);
            setIsCreated(!isCreated);
            setProjectDescription('');
            setLandscapeFeatures(false);
            outerRef.current.style.opacity = 0;
            outerRef.current.style.pointerEvents = 'none';
            handleSuccess(
              TOAST_TYPE.SUCCESS,
              'Bulk project updated successfully!'
            );
          } else {
            handleError(TOAST_TYPE.ERROR, 'Something went wrong!');
          }
        })
        .catch((err) => {
          handleError(err);
        });
    } else {
      handleSuccess(TOAST_TYPE.WARNING, 'Please select all given fields!');
    }
  };

  const handleAction = () => {
    if (action === 'create') {
      createProject();
    } else if (action === 'edit') {
      updateProject();
    }
  };

  const handleClickOutSide = (e) => {
    if (dateRef.current) if (!dateRef.current.contains(e.target)) setOpenDate(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutSide, true);
  }, []);

  const handleDateFilter = () => {
    if (startDate.startDate || endDate.endDate) {
      const newList = projectList.filter((item) => {
        const objDate = new Date(item.created_by);

        // Extract year, month, and day from the item's date
        const itemYear = objDate.getFullYear();
        const itemMonth = objDate.getMonth() + 1; // Months are zero-based
        const itemDay = objDate.getDate();
        const itemDateString = `${itemYear}-${itemMonth
          .toString()
          .padStart(2, '0')}-${itemDay.toString().padStart(2, '0')}`;

        const start = new Date(startDate.startDate);
        const end = new Date(endDate.endDate);

        // Extract year, month, and day from the start and end dates
        const startYear = start.getFullYear();
        const startMonth = start.getMonth() + 1; // Months are zero-based
        const startDay = start.getDate();
        const startDateString = `${startYear}-${startMonth
          .toString()
          .padStart(2, '0')}-${startDay.toString().padStart(2, '0')}`;

        const endYear = end.getFullYear();
        const endMonth = end.getMonth() + 1; // Months are zero-based
        const endDay = end.getDate();
        const endDateString = `${endYear}-${endMonth
          .toString()
          .padStart(2, '0')}-${endDay.toString().padStart(2, '0')}`;

        return (
          itemDateString >= startDateString && itemDateString <= endDateString
        );
      });

      setProjectList(newList);
      searchParams.set(
        'start_date',
        startDate.startDate ? startDate.startDate : null
      );
      searchParams.set('end_date', endDate.endDate ? endDate.endDate : null);
      setSearchParams(searchParams);
    }
    setOpenDate(false);
  };

  const clearDateFilter = () => {
    setStartDate({ startDate: '' });
    setEndDate({ endDate: '' });
    searchParams.delete('start_date');
    searchParams.delete('end_date');
    setSearchParams(searchParams);
    setIsCreated(!isCreated);
  };

  const LinearProgressWithLabel = (props) => {
    return (
      <Box
        sx={{
          width: '70%',
          display: 'flex',
          alignItems: 'start',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ minWidth: 35, marginBottom: '1%', color: '#06372a' }}>
          <Typography variant="body2">
            {`${Math.round(props.value)}%`} Completed
          </Typography>
        </Box>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress color="inherit" variant="determinate" {...props} />
        </Box>
      </Box>
    );
  };

  const downloadExcel = () => {
    axios({
      url: `${MOLE_ORDER_URL}/${orderId}/export?is_carto_user=true`,
      method: 'GET',
      responseType: 'arraybuffer', // Set the response type to arraybuffer
      headers: {
        // Authorization: `Token ${AuthToken()}`,
        "x-api-key": `${encodeJwtToken()}`,
      },
    })
      .then((res) => {
        const blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.download = `${projectName.toLowerCase()}.xlsx`;

        // Append the link to the body
        document.body.appendChild(link);

        // Trigger a click on the link
        link.click();

        // Clean up by removing the link from the DOM
        document.body.removeChild(link);
        loaderRef.current.style.opacity = 0;
      })
      .catch((err) => {
        handleError(err);
        loaderRef.current.style.opacity = 0;
      });
  };

  const requestImage = () => {
    axios({
      url: `${MOLE_ORDER_URL}/${orderId}/image-request`,
      method: 'POST',
      headers: {
        Authorization: `Token ${AuthToken()}`,
      },
    })
      .then((res) => {
        handleSuccess(TOAST_TYPE.SUCCESS, res?.data?.msg);
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const handleStatusUpdate = (status) => {
    if (status)
      axios({
        url: `${MOLE_ORDER_URL}/${orderId}`,
        method: 'PATCH',
        data: { "ops_status": status },
        headers: {
          Authorization: `Token ${AuthToken()}`,
        },
      })
        .then((res) => {
          getProjectList();
          handleSuccess(
            TOAST_TYPE.SUCCESS,
            `Order marked as ${status}`
          );
        })
        .catch((err) => {
          handleError(err);
        });
  }



  // pagination start
  const [rowCount, setRowCount] = useState(0);
  
  const pageNum = searchParams.get("page") || 1;

  const [pageCount, setPageCount] = useState(0);
  const [selectedIds, setSelectedIds] = useState([]);
  const [inputValue, setInputValue] = useState("10");
  const [invalidInput, setInvalidInput] = useState(false);
  const [currentPage, setCurrentPage] = useState(pageNum);
  const [jumpToInput, setJumpToInput] = useState(currentPage);


  const [activeTag, setActiveTag] = useState(false);








  const handleKeyDown = (event) => {
    const input = event.target.value;
    if (event.key === "Enter") {
      event.preventDefault();
      if (input) {
        if (input < 0) {
          setJumpToInput(1);
          setCurrentPage(1);
        } else if (input > pageCount) {
          setJumpToInput(pageCount);
          setCurrentPage(pageCount);
        } else setCurrentPage(input);
      }
    }
  };


  const handlePageChange = (data) => {
    console.log(data, "handle page change data")
    let currentPage = data.selected + 1;
    setCurrentPage(currentPage);
    searchParams.set("page", currentPage);
    setSelectedIds([]);
    setActiveTag(!activeTag);
    setSearchParams(searchParams);

    // const tableContainer = document.getElementById("yourTableContainerId");

    const tableContainer = document.querySelector(".project-list-container");
    if (tableContainer) {
      tableContainer.scrollTop = 0;
    }
  };


  const fetchAllBulkProject =()=>{
    
  // const defaultParams = {
  //   all: false,
  //   // name: '',
  //   // status: '',
  //   // ops_status: '',
  //   // start_date: '',
  //   // end_date: '',
  //   // created_by_id: '',
  //   // updated_by_id: '',
  //   // p: 1,
  //   // psz: 10,
  // };

 
  // const queryParams = { ...defaultParams };

  // Construct query string from parameters
  // const queryString = Object.keys(queryParams)
  //   .map(key => `${key}=${encodeURIComponent(queryParams[key])}`)
  //   .join('&');

  // axios({
  //   // url: `${MOLE_ORDER_URL}?${queryString}`,
  //   url: `${MOLE_ORDER_URL}?all`,
  //   method: "GET",
  //   headers: {
  //     Authorization: `Token ${AuthToken()}`,
  //   },
  // })
  axios({
    // url: `${MOLE_ORDER_URL}?${queryString}`,


    url: `${MOLE_ORDER_URL}?all=true&p=${currentPage}&psz=${inputValue}`, 

    method: "GET",
    headers: {
      Authorization: `Token ${AuthToken()}`,
      Accept: 'application/json',
    },
  })
  
    .then((res) => {
      setProjectList(res.data.data);

      setRowCount(res.data.count); // for pagination
      setPageCount(res.data.total_pages); // for pagination

      console.log(res.data.data, "All respons data data");
      console.log(res.data.total_orders, "total order response data data");
      console.log(res.data.data, "MOLE_ORDER_URL in bulk order");
    })
    .catch((err) => {
      handleError(err);
    });
};


  
  

  useEffect(()=>{
    fetchAllBulkProject()
  },[currentPage,inputValue])
 
   const UploadeBulkProjectorderPage = true
  // pagination end

  return (
    <div style={{ height:" 100%"}} >


    <div className="project-main-container" style={{ height:" 85%"}}>
      <div className="project-title-container">
        <h2>Projects</h2>
      </div>
      <div className="project-filter-button-container">
        <div className="project-filter-left-container">
          <div className="project-search-container">
            <input
              type="text"
              placeholder="Search by Name"
              onChange={(e) => setQuery(e.target.value)}
            />
            <SearchOutlinedIcon className="orders-search-icon" />
          </div>
          <div
            class="project-date-select"
            onClick={() => setOpenDate(!openDate)}
            ref={dateRef}
          >
            <span>Created On</span>
            {openDate ? (
              <KeyboardArrowUpIcon className="order-filter-icon" />
            ) : (
              <KeyboardArrowDownIcon className="order-filter-icon" />
            )}
            {openDate ?
              <div onClick={e => e.stopPropagation()} className="project-date-filter order-date-filter">
                <h4>Date</h4>
                <div className="order-date-filter-one">
                  <span>From</span>
                  <input
                    type="date"
                    value={startDate.startDate}
                    onChange={(event) =>
                      setStartDate({ startDate: event.target.value })
                    }
                  />
                </div>
                <div className="order-date-filter-two">
                  <span>To</span>
                  <input
                    type="date"
                    value={endDate.endDate}
                    onChange={(event) => setEndDate({ endDate: event.target.value })}
                  />
                </div>
                <hr />
                <button onClick={handleDateFilter}>✔ Apply</button>
              </div> : null
            }
          </div>
          {start_Date || end_Date ? (
            <div className="project-clear-filter">
              <span>Created On.</span>
              <ClearIcon
                className="close-filter-icon"
                onClick={clearDateFilter}
              />
            </div>
          ) : null}
        </div>

      </div>
      <div className="project-list-container">
        {!projectList ? (
          <Skeleton
            count={4}
            style={{
              height: '25%',
              marginBottom: '33px',
            }}
          />
        ) : projectList?.length < 1 ? (
          <div className="no-bulk-project-container">
            <h2>No Bulk Project is Created</h2>
            <p>Click below button to create bulk project</p>
            <button
              onClick={() => {
                setAction('create');
                outerRef.current.style.opacity = 1;
                outerRef.current.style.pointerEvents = 'auto';
              }}
            >
              Create Bulk Project
            </button>
          </div>
        ) : (
          search(projectList).map((project, index) => {
            return (
              <div className="project-list-item" key={project?.id}>
                <div className="project-list-item-info">
                  <p>
                    <span>Project ID</span>: {project?.id}
                  
                    

                  </p>
                  <p>
                    <span>Project Name</span>: {project?.name}
                  </p>

                  <p>
                    <span>Features</span>: {''}
                    {project?.feature_data.map((feature, index) => {
                      return (
                        <>
                          {feature.name}
                          {index < project?.feature_data?.length - 1 ? (
                            <span>{','} </span>
                          ) : null}
                        </>
                      );
                    })}
                  </p>
                  {project?.description && (
                    <p>
                      <span>Description</span>: {project?.description}
                    </p>
                  )}
                </div>
                <div style={{ width: '25%', marginRight: '20%' }}>
                  {project.status === ORDER_STATUS.DRAFT ? (
                    <div className="project-list-item-progres-bar">
                      <ProgressBar
                        draft={project?.draft_orders}
                        total={project?.total_orders}
                        inProgress={project?.in_progress}
                        completed={project?.completed_orders}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="project-list-item-action">
                  <div className="project-list-item-action-one">
                    <p>{project?.created_at.slice(0, 10)}</p>
                    {(project.status === ORDER_STATUS.DRAFT ||
                      project.status === 'completed') && (
                        <div>
                          <IconButton
                            aria-label="more"
                            sx={{ padding: 0 }}
                            aria-controls={open ? 'long-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={(e) => {
                              setOrderId(project?.id);
                              project?.ops_status === OPS_STATUS.STARTED ?
                                setAnchorEl(e.currentTarget) : project?.ops_status === OPS_STATUS.NOT_STARTED ?
                                  setAnchorEl2(e.currentTarget) : setAnchorEl3(e.currentTarget);
                              setProjectName(project?.name);
                            }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          {project?.ops_status === OPS_STATUS.STARTED ?
                            <Menu
                              id="long-menu"
                              MenuListProps={{
                                'aria-labelledby': 'long-button',
                              }}
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              PaperProps={{
                                style: {
                                  maxHeight: 48 * 4.5,
                                  width: '20ch',
                                },
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  handleClose();
                                  downloadExcel();
                                  loaderRef.current.style.opacity = 1;
                                }}
                                className="order-action-menu-item"
                              >
                                Download as Excel
                              </MenuItem>
                              <MenuItem
                                // className="order-action-menu-item"
                                onClick={() => {
                                  setAnchorEl(null);
                                  setActionStatus(project?.ops_status);
                                  outerStatusRef.current.style.opacity = 1;
                                  outerStatusRef.current.style.pointerEvents = 'auto';
                                }}
                              >
                                Mark as Completed
                              </MenuItem>
                            </Menu> : project?.ops_status === OPS_STATUS.NOT_STARTED ?
                              <Menu
                                id="long-menu"
                                MenuListProps={{
                                  'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl2}
                                open={open2}
                                onClose={handleClose}
                                PaperProps={{
                                  style: {
                                    maxHeight: 48 * 4.5,
                                    width: '20ch',
                                  },
                                }}
                              >
                                <MenuItem
                                  onClick={() => {
                                    handleClose();
                                    downloadExcel();
                                    loaderRef.current.style.opacity = 1;
                                  }}
                                  className="order-action-menu-item"
                                >
                                  Download as Excel
                                </MenuItem>
                                <MenuItem
                                  className="order-action-menu-item"
                                  onClick={() => {
                                    setActionStatus(project?.ops_status);
                                    setAnchorEl2(null);
                                    outerStatusRef.current.style.opacity = 1;
                                    outerStatusRef.current.style.pointerEvents = 'auto';
                                  }}
                                >
                                  Mark as Started
                                </MenuItem>
                              </Menu> :
                              <Menu
                                id="long-menu"
                                MenuListProps={{
                                  'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl3}
                                open={open3}
                                onClose={handleClose}
                                PaperProps={{
                                  style: {
                                    maxHeight: 48 * 4.5,
                                    width: '20ch',
                                  },
                                }}
                              >
                                <MenuItem
                                  onClick={() => {
                                    handleClose();
                                    downloadExcel();
                                    loaderRef.current.style.opacity = 1;
                                  }}
                                  className="order-action-menu-item"
                                >
                                  Download as Excel
                                </MenuItem>
                              </Menu>}
                        </div>
                      )}
                  </div>
                  <div className="project-list-item-action-buttons">
                    <a
                      href={`/upload/order/bulk-orders/${project?.id}`}
                      style={{ width: '100%' }}
                    >
                      <button style={{ width: '100%', padding: '4% 10%' }}>
                        View Project
                      </button>
                    </a>
                  </div>
                  <div className="project-list-item-status">
                    {project?.status[0].toUpperCase() +
                      project?.status.slice(1)}
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
      <div className="create-project-outer-container" ref={outerRef}>
        <div className="create-project-inner-container" ref={innerRef}>
          <div className="raise-feedback-inner-top">
            <span
              onClick={() => {
                setProjectName('');
                setFeatureSelected([]);
                setSnowFeatures(false);
                setProjectDescription('');
                setLandscapeFeatures(false);
                outerRef.current.style.opacity = 0;
                outerRef.current.style.pointerEvents = 'none';
              }}
            >
              X
            </span>
          </div>
          <div className="create-project-inner-mid">
            <div className="create-project-name">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                value={projectName}
                placeholder="Enter a name for bulk property upto 15 Character Name"
                onChange={(e) => setProjectName(e.target.value)}
              />
            </div>
            <div className="create-project-description">
              <label htmlFor="description">Description</label>
              <textarea
                name="description"
                value={projectDescription}
                placeholder="Enter Description"
                onChange={(e) => setProjectDescription(e.target.value)}
              />
            </div>
            {action === 'create' && (
              <div className="create-project-features">
                <label htmlFor="features">Features</label>
                <div className="create-project-features-list">
                  <div className="feature-info-container create-project-feature-info-container">
                    <div className="snow-landscape-segment-cont">
                      <FormGroup row={false}>
                        <FormControlLabel
                          className="features-info-input"
                          control={
                            <Checkbox
                              size='small'
                              color="success"
                              value="Okay"
                              checked={snowFeatures}
                              onChange={() => {
                                if (action === 'create') {
                                  selectSnowFeatures();
                                }
                              }}
                            />
                          }
                          label="Select Snow Features"
                        />
                      </FormGroup>
                      <FormGroup row={false}>
                        <FormControlLabel
                          className="features-info-input"
                          control={
                            <Checkbox
                              size='small'
                              color="success"
                              value="Okay"
                              checked={landscapeFeatures}
                              onChange={(e) => {
                                if (action === 'create') {
                                  selectLandscapeFeatures();
                                }
                              }}
                            />
                          }
                          label="Select Landscape Features"
                        />
                      </FormGroup>
                      <hr></hr>
                    </div>
                    <div className="features-container">
                      <FormGroup row={false}>
                        {availableFeatures?.filter((feature) => feature.creator === 'otter')?.map((item) => (
                            <FormControlLabel
                              key={item.id}
                              className="features-info-input"
                              control={
                                <Checkbox
                                   size='small'
                                  color="success"
                                  value={item.id}
                                  checked={checkCheckBox(item.id)}
                                  onChange={(e) => {
                                    if (action === 'create') {
                                      if (e.target.checked) {
                                        setFeatureSelected([...featureSelected, item]);
                                      } else {
                                        setFeatureSelected(
                                          featureSelected.filter(
                                            (feature) => feature.id !== item.id
                                          )
                                        );
                                      }
                                    }
                                  }}
                                />
                              }
                              label={item.name}
                            />
                          ))}
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="create-project-inner-bottom">
            <div>
              <button
                onClick={() => {
                  setProjectName('');
                  setFeatureSelected([]);
                  setSnowFeatures(false);
                  setProjectDescription('');
                  setLandscapeFeatures(false);
                  outerRef.current.style.opacity = 0;
                  outerRef.current.style.pointerEvents = 'none';
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  handleAction();
                }}
              >
                {action === 'create' ? 'Create' : 'Update'}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div ref={loaderRef} className="loader_outer-container">
        <div className="main_loader_container">
          <span className="bulk_loader"></span>
        </div>
      </div>
      <div ref={outerStatusRef} className="review-and-submit-warning-outer">
        <div
          ref={innerStatusRef}
          className={
            actionStatus === OPS_STATUS.NOT_STARTED
              ? 'review-and-submit-warning-inner-b'
              : 'review-and-submit-warning-inner'
          }
        >
          <div className="raise-feedback-inner-top">
            <span
              onClick={() => {
                outerStatusRef.current.style.opacity = 0;
                outerStatusRef.current.style.pointerEvents = 'none';
              }}
            >
              X
            </span>
          </div>
          <div className="review-and-submit-warning-inner-mid">
            {actionStatus === OPS_STATUS.STARTED ? (
              <p>Confirm marking order as completed?</p>
            ) : actionStatus === OPS_STATUS.NOT_STARTED ? (
              <div>
                <p>Confirm marking order as started?</p>
                {/* <span>
                  Note: Orders with unclear location or Parcel will be emailed
                  for your confirmation.
                </span> */}
              </div>
            ) : (
              <p>Are you sure, you want to proceed?</p>
            )}
          </div>
          <div className="view-modal-bttns">
            <button
              onClick={() => {
                outerStatusRef.current.style.opacity = 0;
                outerStatusRef.current.style.pointerEvents = 'none';
              }}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                handleStatusUpdate(actionStatus === OPS_STATUS.STARTED ? "completed" : actionStatus === OPS_STATUS.NOT_STARTED ? "started" : null)
                outerStatusRef.current.style.opacity = 0;
                outerStatusRef.current.style.pointerEvents = 'none';
              }}
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
    </div>

    <div  style={{ height:" 15%"}} >
    <Pagination
        invalidInput={invalidInput}
        jumpToInput={jumpToInput}
        setJumpToInput={setJumpToInput}
        handleKeyDown={handleKeyDown}
        handlePageChange={handlePageChange}
        pageCount={pageCount}
        rowCount={rowCount}
        currentPage={currentPage}
        inputValue={inputValue}
        setInputValue={setInputValue}

        UploadeBulkProjectorderPage={UploadeBulkProjectorderPage}
      />
      </div>
    
    </div>
  );
};

export default UploadBulkProject;
