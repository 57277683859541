import {
  faFileAlt,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFilePowerpoint,
} from "@fortawesome/free-regular-svg-icons";
import path, { relative } from "path";
import React, { useState, useRef } from "react";
import "./OrderNoteModal.css";
import moment from "moment-timezone";
import Avatar from "@mui/material/Avatar";
import { Tooltip } from "@material-ui/core";
import { Typography, Box } from "@mui/material";
import NoteIcon from "@mui/icons-material/Note";
import { useScreenType } from "../../utils/commonMethods";
import { useUserContext } from "../../context/UserContext";
import { useLayerContext } from "../../context/LayerContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import hoverEffect from "../../OpenLayers/OLInteractions/HoverInteraction";
import CommentFormat from "../OrderNotesCommentSection/CommentFormat";
import { unByKey } from "ol/Observable";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import OrderNoteForm from './../OrderNoteForm/OrderNoteForm.jsx';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { format, formatDistanceToNow } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CustomModal from './../CustomModal/CustomModal.jsx';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import { TRUE } from "ol/functions.js";
import DeleteWarningModal from './../DeleteWarningModal/DeleteWarningModal.jsx';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import ReportIcon from '@mui/icons-material/Report';
import axios from "axios";
import { ORDER_URL, LOGIN_URL } from "../../helper/ApiUrl";
import { useParams } from "react-router-dom";
import AuthToken from "../../context/AuthToken";
import { TOAST_TYPE } from "../../utils/constants";
import useMediaQuery from '@mui/material/useMediaQuery';
import Drawer from '@mui/material/Drawer';
import OrderNotesMaker from './../../assets/orderNotesMarker.svg';

export default function OrderNoteModal({
  hex2rgba,
  hoverElem,
  notesModal,
  hoverMapKey,
  notesButton,
  mapClickable,
  toggleSlideBtn,
  setHoverMapKey,
  orderNotesLayer,
  setOrderNoteData,
  toggleMapClickable,
  setOrderNotesModalShow,
  orderNotesModalShow,
  setOrderNoteLayerVisible,
  toggleOrderNoteMarkerVisible,
  userAdded,
  deleteNote,
  handleError,
  setUserAdded,
  usersDetails,
  handleSuccess,
  orderNoteData,
  setDeleteNote,
  outerOrderNoteRef
}) {

  const token = AuthToken();
  const isMobile = useScreenType();
  const url = window.location.href;
  const { olMap } = useLayerContext();
  const userTimezone = moment.tz.guess();
  const [orderNoteEditable, setOrderNoteEditable] = useState(false);
  const [editableData, setEditableData] = useState(null)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletableData, setDeletableData] = useState(null)
  const [creatingNote, setCreatingNote] = useState(false)
  console.log(editableData, "editableData in note modal")
  const { id } = useParams();

  const [savedQData, setSavedQData] = useState([]);

  const addMoreOrderNotes = () => {
    setOrderNoteEditable(false)
    toggleMapClickable(true);
    // setCreatingNote()
    // if (isMobile) setOrderNotesModalShow(false);
  };

  const deleteOrderNote = () => {
    axios({
      url: `${ORDER_URL}${id}/notes/${deletableData.id}`,
      method: "DELETE",
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => {
        if ("data" in res) {
          handleSuccess(TOAST_TYPE.SUCCESS, "Order Note successfully deleted");
          // if (url.includes(prod)) {
          if (url.includes(process.env.REACT_APP_DEV)) {
            window.analytics.track("Order Note Deleted");
          }
        }
      })
      .catch((err) => {
        handleError(err);
      });
    setDeleteNote(false);
    toggleOrderNoteMarkerVisible(false);
    setOrderNoteLayerVisible(false);
    setDeleteModalOpen(false)
    // setOrderNoteTitle("");
    // setOrderNoteDescription("");
  };

console.log(editableData,  orderNoteEditable, "editableData")

  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="order-notes-main-container">
      {!isMobile && <div className="order-notes-main-head">
        <div className="notes-slide-close-btn">
          <Tooltip title="Close">
            <button
              onClick={() => {
                // toggleSlide();
                unByKey(hoverMapKey);
                toggleSlideBtn();
                toggleMapClickable(false);
                setOrderNotesModalShow(false);
                setOrderNoteLayerVisible(false);
                toggleOrderNoteMarkerVisible(false);
                notesModal.current.style.display = "none";
                notesButton.current.style.display = "block";
                hoverEffect(
                  olMap,
                  hex2rgba,
                  hoverMapKey,
                  setHoverMapKey,
                  hoverElem
                );
              }}
            >
              <KeyboardDoubleArrowRightIcon />
            </button>
          </Tooltip>
        </div>
        <div className="order-notes-modal-heading">
          <span>Add Order Notes</span>
          <div className="feedback-modal-head-2">(in the map)</div>
        </div>
      </div>}
      <Button onClick={addMoreOrderNotes} variant={mapClickable ? "contained" : "outlined"} className={mapClickable ? "ottermap-button" : "ottermap-button-outlined"} sx={{ marginTop: "10px", width: isMobile ? "fit-content" : "100%", alignSelf: "end"}} color="success" startIcon={<AddCircleOutlineIcon className="icon" />}>
           Add more order notes
      </Button>
      <div className="order-notes-list-container" id="scrollable-notes-container">
      {/* <div
        onClick={addMoreOrderNotes}
        style={{ width: "auto" }}
        className={`add-feedback-button add-order-notes-btn ${
          mapClickable ? "add-note-clicked" : ""
        }`}
      >
        <AddCircleOutlineIcon className="icon" />
        <span style={{ textAlign: "center" }}>Add more order notes</span>
      </div> */}


        {(orderNoteData && !orderNoteData?.created_at) && 
        <OrderNoteForm
         userAdded={userAdded}
         deleteNote={deleteNote}
         handleError={handleError}
         setUserAdded={setUserAdded}
         usersDetails={usersDetails}
         handleSuccess={handleSuccess}
         orderNoteData={orderNoteData}
         setDeleteNote={setDeleteNote}
         outerOrderNoteRef={outerOrderNoteRef}
         toggleMapClickable={toggleMapClickable}
         setOrderNoteLayerVisible={setOrderNoteLayerVisible}
         toggleOrderNoteMarkerVisible={toggleOrderNoteMarkerVisible}
         orderNoteEditable={true}
         setOrderNoteEditable={setOrderNoteEditable}
         setOrderNotesModalShow={setOrderNotesModalShow}
       />}
     
        {orderNotesLayer && orderNotesLayer.map((item, index) => (
           <NotesCard setDeletableData={setDeletableData} deleteOrderNote={deleteOrderNote} item={item} index={index} setOrderNoteData={setOrderNoteData} setDeleteModalOpen={setDeleteModalOpen} outerOrderNoteRef={outerOrderNoteRef} setOrderNoteEditable={setOrderNoteEditable} orderNoteEditable={orderNoteEditable} setEditableData={setEditableData} deleteModalOpen={deleteModalOpen} />
        ))}
       
      </div>
      
          <Dialog
           open={orderNoteEditable}
          //  TransitionComponent={Transition}
           keepMounted
           onClose={() => setOrderNoteEditable(false)}
           PaperProps={{
             sx: dialogStyles,
           }}
           aria-describedby="alert-dialog-slide-description"
         >
           <Box sx={{display: "flex", width: "100%", justifyContent: "flex-end"}}><IconButton sx={{marginRight: "5px"}} size="small" onClick={() => setOrderNoteEditable(false)}><CloseIcon fontSize="inherit"  /></IconButton></Box>
           <DialogTitle sx={{textAlign: "start", fontSize: "clamp(20px, 1.5vw, 1.5vw)", padding: "0px 20px"}}>
           {/* {icon} */}
           <Typography sx={{ fontSize: "clamp(20px, 1.5vw, 1.5vw)", fontWeight: "800"}}>Edit note</Typography>
           </DialogTitle>
           <DialogContent sx={{padding: "0px !important"}} >
             <DialogContentText sx={{textAlign: "center",fontSize: "clamp(14px, 1vw, 1vw)"}} id="alert-dialog-slide-description">
            {( orderNoteEditable && editableData?.created_at) && 
             <OrderNoteForm
         userAdded={userAdded}
         deleteNote={deleteNote}
         handleError={handleError}
         setUserAdded={setUserAdded}
         usersDetails={usersDetails}
         handleSuccess={handleSuccess}
         orderNoteData={editableData}
         setDeleteNote={setDeleteNote}
         outerOrderNoteRef={outerOrderNoteRef}
         toggleMapClickable={toggleMapClickable}
         setOrderNoteLayerVisible={setOrderNoteLayerVisible}
         toggleOrderNoteMarkerVisible={toggleOrderNoteMarkerVisible}
         orderNoteEditable={true}
         setOrderNoteEditable={setOrderNoteEditable}
         setOrderNoteData={setEditableData}
       />}
             </DialogContentText>
           </DialogContent>
           {/* <DialogActions sx={{display: "flex", justifyContent: "center"}}>
             {cancelText && <Button variant="outlined" className='ottermap-button-outlined' onClick={cancel ? cancel : handleClose}>{cancelText}</Button>}
             {submitText && <Button variant="contained" className='ottermap-button' onClick={submit}>{submitText}</Button>}
           </DialogActions> */}
         </Dialog>

         <DeleteWarningModal icon={<ReportIcon className="property-icon" sx={{ color: "#054635"}} />} cancelText="Cancel" submitText="Delete" submit={() => deleteOrderNote()} title="Confirmation" description="Are you sure you want to delete this Note?" setOpen={setDeleteModalOpen} open={deleteModalOpen}/>

          
      
    </div>
  );
}


export const NoteDetailsCard = ({item, index, setOrderNoteData, outerOrderNoteRef, orderNoteEditable, setOrderNoteEditable, setEditableData}) => {
  const [anchorEl, setAnchorEl] = useState(null);


  console.log(item, index, "helloKitty")
  let tag = item.meta_data?.fontSize;
  {/* if(item.meta_data?.Qdescription){
    setSavedQData(JSON.parse(item.meta_data?.Qdescription))
   } */}
 
  const color = item.meta_data?.color || "black";
  const fontSize = item.meta_data?.fontSize || "p";
  
  const styles = {
    h1: { fontSize: "2em", fontWeight: "bold", margin: "0.67em 0" },
    h2: { fontSize: "1.5em", fontWeight: "bold", margin: "0.75em 0" },
    h3: {
      fontSize: "1.17em",
      fontWeight: "bold",
      margin: "0.83em 0",
    },
    h4: { fontSize: "1em", fontWeight: "bold", margin: "1.12em 0" },
    h5: { fontSize: "0.83em", fontWeight: "bold", margin: "1.5em 0" },
    h6: {
      fontSize: "0.67em",
      fontWeight: "bold",
      margin: "1.67em 0",
    },
    p: { fontSize: "1em", fontWeight: "normal", margin: "1em 0" },
  };
  const style = { ...styles[fontSize], color };
  {
    /* console.log(Tag , "Fonstsize tag ") */
  }
  const TagComponent = tag;

  {
    /* console.log(item,"item", typeof(item),"typeof Item") */
  }
  // const userLocalDate = moment.utc(item.created_at).tz(userTimezone).format(" MMM DD, YYYY");
  const userLocalTime = formatTime(item?.created_at);
  const IconWithText = ({ text, iconName }) => {
    return (
      <Box position="relative" display="inline-block">
        {iconName}
        <Typography
          variant="body1"
          component="span"
          sx={{
            top: "50%",
            left: "50%",
            width: "100%",
            color: "white",
            fontSize: "0.7rem",
            textAlign: "center",
            position: "absolute",
            transform: "translate(-50%, -50%)",
          }}
        >
          {text}
        </Typography>
      </Box>
    );
  };
  const FileIcon = ({ url }) => {
    let extname = path.extname(url);
    let icon;
    switch (extname) {
      case ".pdf":
        icon = faFilePdf;
        break;
      case ".doc":
      case ".docx":
        icon = faFileWord;
        break;
      case ".ppt":
      case ".pptx":
        icon = faFilePowerpoint;
        break;
      case ".xls":
      case ".xlsx":
        icon = faFileExcel;
        break;
      case ".jpg":
      case ".jpeg":
      case ".png":
      case ".gif":
        return <img src={url} alt={url} />;
      case ".mp3":
      case ".wav":
        icon = faFileAudio;
        break;
      case ".mp4":
      case ".mov":
        icon = faFileVideo;
        break;
      default:
        icon = faFileAlt;
        break;
    }
    return <FontAwesomeIcon className="doc-type-attachment-icon" icon={icon} />;
  };

  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const editOrderNote = () => {
    setOrderNoteEditable(true);
    handleClose();
  };
  const handleViewMoreOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div
      key={index}
      className="order-notes-list-item"
      onClick={() => {
        setOrderNoteData(item);
      }}
    >
      <div className="order-notes-list-item-top">
        <div className="notes-list-item-top-one">
          {/* <Avatar
            sx={{
              width: "25px",
              height: "25px",
              fontSize: "0.9rem",
              bgcolor: `#1B9C85`,
            }}
          >
            {item.created_by.name.charAt(0).toUpperCase()}
          </Avatar> */}
          <div style={{display :"flex", alignItems: "center" }}>
          {index !== undefined && index !== null ? <IconWithText 
            text={index + 1}
            iconName={ <NoteIcon sx={{ color: `#1B9C85`, width: "22px", height: "22px", marginTop: "2px"}} /> }
           />
          :
          <IconWithText
            text=""
            iconName={ <NoteIcon sx={{ color: `#1B9C85`,  width: "22px", height: "22px", marginTop: "2px"}} /> }
           />
          }
          <div style={{marginleft: "3px"}}>{item.created_by.name}</div>
          {/* <div className="notes-date"><p>{userLocalDate}</p></div> */}
          <div className="notes-date"><p>{userLocalTime}</p></div>
          </div>
          {index !== undefined && index !== null ? <MoreVertIcon
                        
                        className="more-vert-icon"
                        onClick={e => {handleViewMoreOptions(e); e.stopPropagation()}}
          /> : null}
           {index !== undefined && index !== null ? <Menu
                      className="menu-cont"
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        className="menu-cont-users-item"
                        onClick={e => {editOrderNote(e); setEditableData(item); e.stopPropagation()}}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        className="menu-cont-users-item"
                        onClick={(e) => {
                          e.stopPropagation()
                          handleClose();
                          // outerOrderNoteRef.current.style.opacity = 1;
                          // outerOrderNoteRef.current.style.pointerEvents = "auto";
                        }}
                      >
                        Delete
                      </MenuItem>
           </Menu> : null}
        </div>
        <div className="notes-list-item-top-two">
         
        </div>
      </div>
      <div className="order-notes-list-item-mid">
        {/* <p>{item.title}</p> */}
        {/* {console.log(typeof(item.title),"item.title")} */}
        <div className="Note__Titles" >
          {item.title}
        </div>
        <span>
       
        {/* {savedQData} */}
        <div dangerouslySetInnerHTML={{ __html: item.meta_data?.Qdescription }} />
          {/* <CommentFormat comment={item.description} /> */}
         
        </span>
      </div>
      {index !== undefined && index !== null ?<div className="order-notes-list-item-bottom">
        <div className="attachments-cont">
          {item.attachments && Array.isArray(item.attachments) && item.attachments.map((attachment, key) => {
              return (
                <Tooltip title={path.basename(attachment)}>
                  <div key={key} className="attachment-item-cont">
                    <FileIcon
                      className="attachment-item"
                      url={attachment}
                    />
                  </div>
                </Tooltip>
              );
            })}
        </div>
      </div> : null}
    
    </div>
  );
}

export const NotesCard = ({item, index, setOrderNoteData, outerOrderNoteRef, orderNoteEditable, setOrderNoteEditable, setEditableData, setDeleteModalOpen, deleteModalOpen, setDeletableData, deleteOrderNote}) => {
  const [anchorEl, setAnchorEl] = useState(null);


  console.log(item, index, "helloKitty")
  let tag = item.meta_data?.fontSize;
  {/* if(item.meta_data?.Qdescription){
    setSavedQData(JSON.parse(item.meta_data?.Qdescription))
   } */}
 
  const color = item.meta_data?.color || "black";
  const fontSize = item.meta_data?.fontSize || "p";
  
  const styles = {
    h1: { fontSize: "2em", fontWeight: "bold", margin: "0.67em 0" },
    h2: { fontSize: "1.5em", fontWeight: "bold", margin: "0.75em 0" },
    h3: {
      fontSize: "1.17em",
      fontWeight: "bold",
      margin: "0.83em 0",
    },
    h4: { fontSize: "1em", fontWeight: "bold", margin: "1.12em 0" },
    h5: { fontSize: "0.83em", fontWeight: "bold", margin: "1.5em 0" },
    h6: {
      fontSize: "0.67em",
      fontWeight: "bold",
      margin: "1.67em 0",
    },
    p: { fontSize: "1em", fontWeight: "normal", margin: "1em 0" },
  };
  const style = { ...styles[fontSize], color };
  {
    /* console.log(Tag , "Fonstsize tag ") */
  }
  const TagComponent = tag;

  {
    /* console.log(item,"item", typeof(item),"typeof Item") */
  }
  // const userLocalDate = moment.utc(item.created_at).tz(userTimezone).format(" MMM DD, YYYY");
  const userLocalTime = formatTime(item?.created_at);
  const IconWithText = ({ text, iconName }) => {
    return (
      <Box position="relative" display="inline-block">
        {iconName}
        <Typography
          variant="body1"
          component="span"
          sx={{
            top: "50%",
            left: "50%",
            width: "100%",
            color: "black",
            fontSize: "0.7rem",
            textAlign: "center",
            position: "absolute",
            transform: "translate(-50%, -50%)",
          }}
        >
          {text}
        </Typography>
      </Box>
    );
  };
  const FileIcon = ({ url }) => {
    let extname = path.extname(url);
    let icon;
    switch (extname) {
      case ".pdf":
        icon = faFilePdf;
        break;
      case ".doc":
      case ".docx":
        icon = faFileWord;
        break;
      case ".ppt":
      case ".pptx":
        icon = faFilePowerpoint;
        break;
      case ".xls":
      case ".xlsx":
        icon = faFileExcel;
        break;
      case ".jpg":
      case ".jpeg":
      case ".png":
      case ".gif":
        return <img src={url} alt={url} />;
      case ".mp3":
      case ".wav":
        icon = faFileAudio;
        break;
      case ".mp4":
      case ".mov":
        icon = faFileVideo;
        break;
      default:
        icon = faFileAlt;
        break;
    }
    return <FontAwesomeIcon className="doc-type-attachment-icon" icon={icon} />;
  };

  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const editOrderNote = () => {
    setOrderNoteEditable(true);
    handleClose();
  };
  const handleViewMoreOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  console.log(item, "itemInModal")
  return (
    <Accordion sx={{boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px", marginTop: "10px", borderRadius: "5px"}}>
    <AccordionSummary
      sx={{paddingLeft: "5px", paddingRight: "2px", margin: "0px"}}
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1-content"
      id="panel1-header"
    >
       <div className="notes-list-item-top-one">
          <div style={{display: "flex", flexDirection: "column"}}>
          <div style={{display :"flex", alignItems: "center" }}>
          {index !== undefined && index !== null ? <IconWithText
            text={index + 1}
            iconName={ <img style={{color: `#1B9C85`, width: "25px", height: "25px", marginTop: "2px"}} src={OrderNotesMaker} />  }
           />
          :
          <IconWithText
            text=""
            iconName={<img style={{color: `#1B9C85`, width: "22px", height: "22px", marginTop: "2px"}} src={OrderNotesMaker} />   }
           />
          }
          <p style={{marginleft: "3px", margin: "0px", fontSize: "13px"}}>{item.created_by.name}</p>
          {/* <div className="notes-date"><p>{userLocalTime}</p></div> */}
          </div>
          <div className="Note__Titles" >
            {item.title}
          </div>
          </div>
          <IconButton onClick={(e) => { e.stopPropagation(); setOrderNoteData(item)}} aria-label="delete" size="large">
               <CenterFocusStrongIcon />
          </IconButton>
        </div>
    </AccordionSummary>
    <AccordionDetails sx={{padding: "0px !important", borderTop: "1px solid #e7e7e7"}}>
         <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px", paddingRight: "10px"}}>
          <div className="notes-date"><p>{userLocalTime}</p></div>
          {index !== undefined && index !== null ? <MoreVertIcon className="more-vert-icon" onClick={e => {handleViewMoreOptions(e); e.stopPropagation()}}/> : null}
         </div>
           {index !== undefined && index !== null ? <Menu
                      className="menu-cont"
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        className="menu-cont-users-item"
                        onClick={e => {editOrderNote(e); setEditableData(item); e.stopPropagation()}}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        className="menu-cont-users-item"
                        onClick={(e) => {
                          e.stopPropagation()
                          setDeleteModalOpen(true)
                          handleClose();
                          setDeletableData(item)
                          // outerOrderNoteRef.current.style.opacity = 1;
                          // outerOrderNoteRef.current.style.pointerEvents = "auto";
                        }}
                      >
                        Delete
                      </MenuItem>
           </Menu> : null}
          {/* <DeleteWarningModal icon={<ReportIcon className="property-icon" sx={{ color: "#054635"}} />} cancelText="Cancel" submitText="Delete" submit={() => deleteOrderNote()} title="Confirmation" description="Are you sure you want to delete this Note?" setOpen={setDeleteModalOpen} open={deleteModalOpen}/> */}

    <div
      key={index}
      className="order-notes-list-item"

    >
      {/* <div className="order-notes-list-item-top">
       
        <div className="notes-list-item-top-two">
         
        </div>
      </div> */}
      <div className="order-notes-list-item-mid">
        {/* <p>{item.title}</p> */}
        {/* {console.log(typeof(item.title),"item.title")} */}
        {/* <div className="Note__Titles" >
          {item.title}
        </div> */}
        <span>
       
        {/* {savedQData} */}
        <div dangerouslySetInnerHTML={{ __html: item.meta_data?.Qdescription }} />
          {/* <CommentFormat comment={item.description} /> */}
         
        </span>
      </div>
      {index !== undefined && index !== null ?<div className="order-notes-list-item-bottom">
        <div className="attachments-cont">
          {item.attachments && Array.isArray(item.attachments) && item.attachments.map((attachment, key) => {
              return (
                <Tooltip title={path.basename(attachment)}>
                  <div key={key} className="attachment-item-cont">
                    <FileIcon
                      className="attachment-item"
                      url={attachment}
                    />
                  </div>
                </Tooltip>
              );
            })}
        </div>
      </div> : null}
    
    </div>
    </AccordionDetails>
  </Accordion>

  );
}

function formatTime(createdAt) {
  // Parse the date string to a Date object
  const time = new Date(Date.parse(createdAt));
  const now = new Date();

  if (isNaN(time)) {
    throw new Error('Invalid date format');
  }

  const timeDifference = now - time;

  if (timeDifference < 24 * 60 * 60 * 1000) {
    // Less than 24 hours, return relative time (e.g., "2 hours ago")
    return formatDistanceToNow(time, { addSuffix: true });
  } else {
    // More than 24 hours, return date and time (e.g., "2024-08-29 08:20:27")
    return format(time, 'dd-MM-yyyy HH:mm');
  }
}

const dialogStyles = {
  width: `400px !important`,
  minWidth:  `300px !important`,
  // maxWidth: `30vw !important`,
  height: `fit-content !important`,
  minHeight:  `200px !important`,
  // maxHeight: `fit-content !important`,
  borderRadius: "15px !important",
  '@media (min-width: 2560px)': {
    minWidth: `25vw !important`,
    // Optionally, you can uncomment the following styles
    // maxWidth: '60vw !important',
    // maxHeight: '30vh !important',
  },
};


