import React from 'react';
import './InfoBox.css'; 

const InfoBox = ({ folderInfo, fileInfo, onClose }) => {
    const folderInfor = folderInfo
    console.log(folderInfor,"folderInfo")
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2>Folder Information</h2>
          <button onClick={onClose} className="close-button">  <box-icon name='x'></box-icon></button>
        </div>
        <div className="modal-body">
          {
            folderInfo && 
            <>
            {/* <h3>Folder Information</h3> */}
          <p><strong>Folder Name:</strong> {folderInfo?.folder_name || 'N/A'}</p>
          <p><strong>Folder ID:</strong> {folderInfo?.folder_id}</p>
          {/* <p><strong>Generated At:</strong> {folderInfo?.generated_at || 'N/A'}</p> */}
          <p><strong>Folder Size:</strong> {folderInfo?.folder_size}</p>
            </>
          }

         {
            fileInfo && <>
            <h3>File Information</h3>
          <p><strong>File Name:</strong> {fileInfo?.file_name}</p>
          {/* <p><strong>File URL:</strong> <a href={fileInfo?.file_url} target="_blank" rel="noopener noreferrer">{fileInfo.file_url}</a></p> */}
          <p><strong>File Size:</strong> {fileInfo?.file_size}</p>
            </>
         }
        </div>
        {/* <div className="modal-footer">
          <button onClick={onClose} className="close-modal-button">Close</button>
        </div> */}
      </div>
    </div>
  );
};

export default InfoBox;
