import axios from 'axios';
import './OrderIdShare.css';
import { Point } from 'ol/geom';
import Feature from 'ol/Feature';
import Icon from 'ol/style/Icon';
import { transform } from 'ol/proj';
import LayerGroup from 'ol/layer/Group';
import VectorLayer from 'ol/layer/Vector';
import Tooltip from '@mui/material/Tooltip';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import { STATUS } from '../../utils/constants';                   
import AuthToken from '../../context/AuthToken';
import '../OpenLayersIdPage/OpenLayersIdPage.css';
import markerIcon from '../../assets/location.svg';
import '../../components/MapLeftBar/MapLeftBar.css';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { useLoader } from '../../context/LoaderProvider';
import React, { useEffect, useState, useRef } from 'react';
import NavigationIcon from '@mui/icons-material/Navigation';
import Accordian from '../../components/Accordian/Accordian';
import { useLayerContext } from '../../context/LayerContext';
import { useParams, useSearchParams } from 'react-router-dom';
import rotateMap from '../../OpenLayers/OLControls/RotateMap';
import getHdImage from '../../OpenLayers/OLFeatures/GetHDImage';
import { ReactComponent as Arrow } from '../../assets/close.svg';
import { ORDER_URL, ORDER_SHARE_URL,EXPLORE_SHARE_URL,EXPLORE_VIEW_URL,EXPLORE_IMAGE_URL } from '../../helper/ApiUrl';
import { totalFeatureMeasurement } from '../../utils/OrderHelper';
import { ReactComponent as SiteLogo } from '../../assets/logo.svg';
import setHDImage from '../../OpenLayers/OLFeatures/SetHDImageMap';
import renderParcel from '../../OpenLayers/OLFeatures/RenderParcel';
import getLayerData from '../../OpenLayers/OLFeatures/GetLayerData';
import handleZoomIn from '../../OpenLayers/OLControls/ZoomInControl';
import initializeMap from '../../OpenLayers/OLFeatures/InitializeMap';
import getLayersData from '../../OpenLayers/OLFeatures/GetLayersData';
import handleZoomOut from '../../OpenLayers/OLControls/ZoomOutControl';
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';
import resetRotation from '../../OpenLayers/OLControls/MapRotationControl';
import { ReactComponent as PropertyIcon } from '../../assets/Property.svg';
import hoverEffect from '../../OpenLayers/OLInteractions/HoverInteraction';
import handleLayerTypeMap from '../../OpenLayers/OLControls/MapViewControl';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import renderOrderLayer from '../../OpenLayers/OLFeatures/RenderOrderLayer';
import ParcelAccordian from '../../components/ParcelAccordian/ParcelAccordian';
import latLongToCoordinates from '../../OpenLayers/OLFeatures/LatlatLongToCoord';
import handleOrderVisiblity from '../../OpenLayers/OLFeatures/HandleOrderVisibility';
import handleLayerTypeSatellite from '../../OpenLayers/OLControls/SatelliteViewControl';
import LayersTypeContainer from '../../components/LayersTypeContainer/LayersTypeContainer';
import { unByKey } from 'ol/Observable';

import { encodeJwtToken } from '../../utils/EncodeJwt';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import OttermapLogo from './../../assets/logo.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { Global } from "@emotion/react";

const OrderIdShare = ({ handleSuccess, handleError }) => {
  const isSmallerThan600 = useMediaQuery('(max-width:600px)');
  const isSmallerThan820 = useMediaQuery('(max-width:820px)');
  const token = AuthToken();
  const navigate = useNavigate()
  const orderRef = useRef();
  const parcelRef = useRef();
  const markerRef = useRef();
  const { id } = useParams();
  const mapElement = useRef();
  const toolbarRef = useRef();
  const [map, setMap] = useState();
  const nearmapImageRef = useRef();
  const feedbackMarkerRef = useRef();
  const [step, setStep] = useState(2);
  const tempFeedbackMarkerRef = useRef();
  const { setOlMap } = useLayerContext();
  const [viewId, setViewId] = useState(null);
  const [isOpen, setIsOpen] = useState(true);
  const [status, setStatus] = useState(null);
  const [isActive, setIsActive] = useState();
  const [direction, setDirection] = useState();
  const [hoverElem, setHoverElem] = useState();
  const [imageDate, setImageDate] = useState();
  const [center, setCenter] = useState([0, 0]);
  const [address, setAddress] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [orderNote, setOrderNote] = useState('');
  const [selected, setSelected] = useState(null);
  const { startLoader, stopLoader } = useLoader();
  const [resetLayer, setResetLayer] = useState([]);
  const [layersCount, setLayersCount] = useState();
  const [zoomLayer, setZoomLayer] = useState(true);
  const [layersData, setLayersData] = useState([]);
  const [modalShow, setModalShow] = useState(null);
  const [hoverMapKey, setHoverMapKey] = useState();
  const [isPreview, setIsPreview] = useState(false);
  const [isOpenBtn, setIsOpenBtn] = useState(false);
  const [isCloseBtn, setIsCloseBtn] = useState(true);
  const [imageBound, setImageBound] = useState(null);
  const [coordinates, setCoordinates] = useState([]);
  const [polygonList, setPolygonList] = useState([]);
  const [placedTime, setPlacedTime] = useState(null);
  const [circleRadius, setCircleRadius] = useState(4);
  const [oneFeature, setOneFeature] = useState(false);
  const [previewStatus, setPreviewStatus] = useState();
  const [orderActive, setOrderActive] = useState(true);
  const [downloadData, setDownloadData] = useState([]);
  const [nearmapcoords, setNearmapcoords] = useState([]);
  const [uploadedLayer, setUploadedLayer] = useState([]);
  const [imagePresent, setImagePresent] = useState(false);
  const [selectedLayers, setSelectedLayers] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const view_id = searchParams.get('view_id');
  const ism = searchParams.get('ism');
  const elem = document.getElementById('popup-cont');
  const [contextInteraction, isContextInteraction] = useState();
  const [nearmapDisabled, setNearmapDisabled] = useState();
  const [nearmapLoader, setNearmapLoader] = useState(true);
  const [nearMapActive, setNearMapActive] = useState(false);
  const [hoverOrderFeatureId, setHoverOrderFeatureId] = useState();
  const [nearmapImageDate, setNearmapImageDate] = useState('');
  const [nearmapSurveyData, setNearmapSurveyData] = useState();
  const [layersRestrict, setLayersRestrict] = useState([]);
  const [navigateAddress, setNavigateAddress] = useState(null);
  const [showNearmapImage, toggleNearmapImage] = useState(false);
  const [measurementUnit, changeMeasurementUnit] = useState('sqft');
  const [outerViewName, setOuterViewName] = useState('Default View');
  const [hoverOrderFeatureElement, setHoverOrderFeatureElement] = useState();
  const [multipleContextInteraction, setMultipleContextInteraction] =
    useState(false);

// below these two function getHdImage is also used by other 
    const getHdImages = (
      id,
      setIsActive,
      toggleNearmapImage,
      setImagePresent,
      setImageUrl,
      setImageBound,
      setNearmapLoader,
      setNearmapDisabled
    ) => {
      axios
        .get(`${EXPLORE_IMAGE_URL}${id}/image`, {
          headers: {
            "x-api-key":`${encodeJwtToken()}`,
            Accept: 'application/json',
          },
        })
        .then((response) => {
          const data = response.data.data;
          if (data.length) {
            toggleNearmapImage(true);
            setImagePresent(true);
            data.map((item) => {
              setIsActive(item.is_active);
              setImageUrl(item.url);
              setImageBound([
                item.min_long,
                item.min_lat,
                item.max_long,
                item.max_lat,
              ]);
            });
          } else {
            setNearmapLoader(false);
            setNearmapDisabled(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };  
    
     

  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(true);

  const handleAccordianToggle = (num) => {
    if (selected === num) {
      return setSelected(null);
    }
    setSelected(num);
  };

  parcelRef.current = new LayerGroup({
    title: 'Parcel',
    layers: [],
  });

  orderRef.current = new LayerGroup({
    title: 'Order',
    layers: [],
  });

  feedbackMarkerRef.current = new LayerGroup({
    title: 'FeedbackMarker',
    layers: [],
  });

  markerRef.current = new LayerGroup({
    title: 'Marker',
    layers: [],
  });

  nearmapImageRef.current = new LayerGroup({
    title: 'NearmapImage',
    layers: [],
  });

  tempFeedbackMarkerRef.current = new LayerGroup({
    title: 'TempFeedbackMarker',
    layers: [],
  });

  const [parcelStyle, setParcelStyle] = useState([
    new Style({
      stroke: new Stroke({
        color: 'red',
        width: 2,
      }),
      fill: new Fill({
        color: 'rgb(255, 255, 255,0.2)',
      }),
    }),
  ]);

  useEffect(() => {
    if (map) {
      unByKey(hoverMapKey);
      map
        .getView()
        .animate(
          { duration: 1000 },
          { center: transform(center, 'EPSG:4326', 'EPSG:3857') }
        );
      hoverEffect(map, hex2rgba, hoverMapKey, setHoverMapKey, hoverElem);
      const iconFeature = new Feature({
        geometry: new Point(transform(center, 'EPSG:4326', 'EPSG:3857')),
      });
      const iconStyle = new Style({
        image: new Icon({
          anchor: [0.5, 46],
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          src: markerIcon,
        }),
      });
      iconFeature.setStyle(iconStyle);
      const vectorSource = new VectorSource({
        features: [iconFeature],
      });
      const vectorLayer = new VectorLayer({
        source: vectorSource,
      });
      markerRef.current.getLayers().push(vectorLayer);
      markerRef.current.setZIndex(2);
      map.addLayer(markerRef.current);
    }
  }, [center]);

  const getParcelData = () => {
    axios
      .get(`${EXPLORE_SHARE_URL}${id}`, {
        headers: {
          "x-api-key":`${encodeJwtToken()}`,
          Accept: 'application/json',
        },
      })
    
      .then((response) => {
        const data = response.data.data;
        setStatus(data.status);
        setPreviewStatus(data.preview_data);
        setPlacedTime(data.order_placed_time);
        setAddress(data.address);
        setImageDate(data.imagery_date);
        setNearmapImageDate(data.imagery_date);
        setNavigateAddress({
          prev_data: data.prev_data,
          next_data: data.next_data,
        });
        if (data.status !== STATUS.MEASUREMENT_COMPLETED) stopLoader();
        if (data.status === STATUS.MEASUREMENT_COMPLETED) getViewsData();
        setOrderNote(data.order_note);
        if (!data.parcel_confirm) {
          setStep(2);
        } else {
          setStep(3);
        }
        setCenter([data.long, data.lat]);
        setCoordinates(data.parcel_data.geometry.coordinates);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  useEffect(() => {
    if (map)
      if (
        nearmapImageDate === 'SATELLITE' ||
        nearmapImageDate === 'satellite'
      ) {
        map.getLayers().forEach((layer) => {
          if (layer && layer.get('title') === 'NearmapImage') {
            map.removeLayer(layer);
          }
        });
      }
    getHdImages(
      id,
      setIsActive,
      toggleNearmapImage,
      setImagePresent,
      setImageUrl,
      setImageBound,
      setNearmapLoader,
      setNearmapDisabled
    );
  }, [nearmapImageDate]);

  const getViewsData = () => {
    axios
      .get(`${EXPLORE_VIEW_URL}${id}/view`, {
        headers: {
          
         "x-api-key":`${encodeJwtToken()}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        const viewData = response.data.data;
        const findView = viewData.find((view) => view.id == view_id);
        if (view_id && findView) {
          const view = viewData.filter((view) => view.id == view_id);
          setViewId(view[0].id);
          setOuterViewName(view[0].name);
        } else {
          setViewId(response.data.data[0].id);
          setOuterViewName(response.data.data[0].name);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  useEffect(() => {
   
    setHoverElem(document.getElementById('popup-cont'));
    initializeMap(mapElement, setMap);
    getViewsData();
    getParcelData();
  }, []);

  useEffect(() => {
    setLayersCount(layersData.length);
    layersData.forEach((layer) => {
      const layerId = layer.id;
      getLayerData(
        id,
        viewId,
        layerId,
        stopLoader,
        setResetLayer,
        setDownloadData,
        setUploadedLayer
      );
    });
  }, [layersData]);

  useEffect(() => {
    getLayersData(
      id,
      viewId,
      searchParams,
      setResetLayer,
      setLayersData,
      setSearchParams,
      setUploadedLayer
    );
  }, [viewId]);

  useEffect(() => {
    latLongToCoordinates(coordinates, setPolygonList);
  }, [coordinates]);

  useEffect(() => {
    if (map && imageUrl && imageBound)
      setHDImage(
        map,
        imageUrl,
        imageBound,
        nearmapImageRef,
        setNearMapActive,
        setNearmapLoader,
        setNearmapDisabled
      );
  }, [imageUrl, imageBound]);

  useEffect(() => {
    if (map)
      map.getLayers().forEach(function (layer) {
        if (layer && layer.get('title') == 'NearmapImage') {
          if (nearMapActive) layer.setVisible(true);
          else layer.setVisible(false);
        }
      });
  }, [nearMapActive]);

  useEffect(() => {
    if (map) {
      renderParcel(
        map,
        status,
        zoomLayer,
        parcelRef,
        polygonList,
        parcelStyle,
        setNearmapcoords
      );
    }
  }, [polygonList, parcelStyle]);

  useEffect(() => {
    if (map) {
      setOlMap(map);
      map.getLayers().forEach((layer) => {
        if (layer && layer.get('title') && layer.get('title') === 'Order') {
          map.getLayers().pop(layer);
        }
      });
      if (
        status === STATUS.DIY ||
        status === STATUS.IN_PROGRESS ||
        status === STATUS.MEASUREMENT_COMPLETED
      ) {
        renderOrderLayer(map, hex2rgba, orderRef, uploadedLayer);
      }
      stopLoader();
    }
  }, [uploadedLayer, layersData]);

  const hex2rgba = (hex, alpha) => {
    if (hex) {
      const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
      return `rgba(${r},${g},${b},${alpha})`;
    }
  };

  useEffect(() => {
    handleOrderVisiblity(map, uploadedLayer, orderActive, setUploadedLayer);
  }, [orderActive]);

  const toggleSlide = () => {
    setIsOpen(!isOpen);
  };

  const toggleSlideBtn = () => {
    setIsOpenBtn(!isOpenBtn);
    setIsCloseBtn(!isCloseBtn);
  };

  const result = polygonList.reduce(
    (total, currentValue) =>
      (total = parseFloat(total) + parseFloat(currentValue.measurement)),
    0
  );

  const handleBookAppointment = () => {
    const url = "https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ16Fihli8kz5hv8934dNyMHcVK4gpGYNydXEcxXIzQF693JwT7tMC_-znACprSYr25BjtU2psrb"
    window.open(url, '_blank')
  }

  // useeffect to disable the right click even on the page
  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    // Add event listener to disable right-click
    document.addEventListener('contextmenu', handleContextMenu);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);


  const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: grey[500],
    borderRadius: 3,
    position: "absolute",
    top: 4,
    left: "calc(50% - 15px)",
    zIndex: 999,
  }));


  if (map) {
    const view = map.getView();

    view.on('propertychange', (event) => {
      if (event.key === 'rotation') {
        const rotation = view.getRotation();
        const rotationInDegrees = (rotation * 180) / Math.PI;
        setDirection(rotationInDegrees);
      }
    });
  }


  return (
    <div className="ol-order-id-share-page">
      <div ref={mapElement} id="map-cont" className="main-ol-container">
       {ism === "true" && <Box sx={{minHeight: "3.1rem" , background: "#519259", display : "flex", alignItems: "center", justifyContent:  isSmallerThan600 ? "start" : "center", width: "100%"}}>
        <Box className="share-header-buttons" sx={{ padding: "0.5% 1%", display: "flex", gap: isSmallerThan600 ? "8px" : "1rem", background: "transparent", borderRadius: "8px", width: "fit-content"}}>
<Button 
  sx={{ minWidth: "fit-content", width: "10vw" }} 
  onClick={() => window.open("/signup", "_blank")}
  className="ottermap-button-light"
>
  Sign up
</Button>
           <Button sx={{minWidth: "fit-content" ,width: "10vw"}} onClick={() => handleBookAppointment()}  className="ottermap-button-outlined-light">Book a call</Button>
        </Box>
       </Box>}

        <div id="popup-cont" className="popup"></div>
        <div className="map-buttons" style={{bottom: isSmallerThan600 && "10%"}}>
          <div className="map-layer-button">
            <LayersTypeContainer
              map={map}
              handleLayerTypeMap={handleLayerTypeMap}
              handleLayerTypeSatellite={handleLayerTypeSatellite}
            />
            <div className="map-rotation-buttons">
              <Tooltip title="Rotate map 90deg left" placement="top-start">
                <Rotate90DegreesCcwIcon
                  className="map-control-icon"
                  onClick={() => rotateMap(map, -90)}
                />
              </Tooltip>
              <Tooltip title="Rotate map 90deg right" placement="top-start">
                <Rotate90DegreesCwIcon
                  className="map-control-icon"
                  onClick={() => rotateMap(map, 90)}
                />
              </Tooltip>
            </div>
          </div>
          <div className="map-zoom-buttons">
            <Tooltip
              title="Use Alt/Option+Shift+Drag to rotate the map. Press this button to reset."
              placement="top"
            >
              <button onClick={() => resetRotation(map)}>
                <NavigationIcon
                  className="map-control-icon"
                  sx={{ rotate: `${direction}deg` }}
                />
              </button>
            </Tooltip>
            <button onClick={() => handleZoomIn(map)}>
              <ZoomInIcon className="map-control-icon" />
            </button>
            <button onClick={() => handleZoomOut(map)}>
              <ZoomOutIcon className="map-control-icon" />
            </button>
          </div>
        </div>
      </div>

      <div className="order-container share-order-container">
    
        <div className="right-side-logo-div">
          <SiteLogo className="right-side-logo" />
        </div>
        {status ? (
          !isSmallerThan600
          ? 
          <div style={{top: ism === "true" ? "10%" : "3%"}} className="order-share-leftbar-main-container">
            <div
              className={`map-leftbar-open-btn ${isOpenBtn ? '' : 'slide-open-hidden'
                }`}
            >
              <button
                className="open-feature-container"
                onClick={() => {
                  toggleSlide();
                  toggleSlideBtn();
                }}
              >
                <Arrow style={{ rotate: '180deg' }} />
              </button>
            </div>
            <div className={`slide-content ${isOpen ? '' : 'slide-hidden'}`}>
              <div className="property-inner-cont">
                <div
                  className="property-address-container-main"
                  style={{
                    width: '94%',
                    padding: '10px',
                    fontSize: '0.7rem',
                    borderRadius: '8px',
                    background: '#519259',
                  }}
                >
                  
                  <Tooltip title={address} placement='top'>
      <div className="property-address-container-main">
        <PropertyIcon className="property-icon" />
        <p>{address ?? ""}</p>
      </div>
    </Tooltip>
                  {/* <PropertyIcon className="property-icon" />
                  <p>{address}</p> */}
                </div>
                <div
                  className="feature-visibility-container"
                  style={{
                    background: 'transparent',
                    padding: '5px 0 0 0',
                  }}
                >
                  <div className="close-btn-div" style={{ margintop: '1%' }}>
                    <span>Measurement Panel:</span>
                    <div
                      style={
                        status === STATUS.DRAFT || status === STATUS.IN_PROGRESS
                          ? {
                            height: '30%',
                          }
                          : null
                      }
                      id="map-leftbar-close-btn"
                      className={`map-leftbar-close-btn ${isCloseBtn ? '' : 'slide-close-hidden'
                        }`}
                    >
                      <button
                        className="close-feature-container"
                        onClick={() => {
                          toggleSlide();
                          toggleSlideBtn();
                        }}
                      >
                        <Arrow />
                      </button>
                    </div>
                  </div>
                  <div className="measurement-panel-header">
                    <div>Features</div>
                    <div className="measurement-panel-header-right">
                      Measurement
                      <select
                        defaultValue="sqft"
                        onClick={(e) => changeMeasurementUnit(e.target.value)}
                        name="units"
                        id="units"
                      >
                        <option value="acres">acres</option>
                        <option value="sqft">sqft</option>
                      </select>
                    </div>
                  </div>
                  <div className="new-toggle-feature-mc">
                    <div
                      className="toggle-all-layers-mc"
                      style={{ width: '55%',  }}
                    >
                      <div className="mc-checkbox" style={{marginLeft: "0px"}}>
                        <input
                          style={{margin: "0px"}}
                          type="checkbox"
                          checked={orderActive || oneFeature}
                          onChange={() => {
                            if (oneFeature) {
                              setOneFeature(false);
                            }
                            setOrderActive(!orderActive);
                          }}
                          className="toggle-all-layers-checkbox"
                        />
                      </div>
                      <span>All Layers</span>
                    </div>
                    <Tooltip
                      className="toggle-all-layers-mc"
                      title={
                        nearmapDisabled === true ? 'Image not present' : ''
                      }
                    >
                      <div className="toggle-all-layers">
                        {nearmapLoader ? (
                          <div className="nearmap-loader"></div>
                        ) : (
                          <div className="mc-checkbox">
                            <input
                              disabled={nearmapDisabled}
                              type="checkbox"
                              checked={nearMapActive}
                              onChange={() => {
                                setNearMapActive(!nearMapActive);
                              }}
                            />
                          </div>
                        )}
                        <span>HD Image</span>
                      </div>
                    </Tooltip>
                  </div>
                  <div className="measurement-panel-data">
                    <ParcelAccordian
                      number={-1}
                      result={result}
                      path={polygonList}
                      selected={selected}
                      setPath={setPolygonList}
                      orderActive={orderActive}
                      setSelected={setSelected}
                      measurementUnit={measurementUnit}
                    />
                    {uploadedLayer.map((item, index) => {
                      return (
                        <Accordian
                          elem={elem}
                          key={index}
                          number={index}
                          viewId={viewId}
                          name={item.name}
                          data={item.data}
                          type={item.type}
                          layerId={item.id}
                          hex2rgba={hex2rgba}
                          selected={selected}
                          modalShow={modalShow}
                          isPreview={isPreview}
                          visible={item.visible}
                          toolbarRef={toolbarRef}
                          hoverMapKey={hoverMapKey}
                          setSelected={setSelected}
                          layersCount={layersCount}
                          handleError={handleError}
                          fillColor={item.fillColor}
                          setZoomLayer={setZoomLayer}
                          setModalShow={setModalShow}
                          outerViewName={outerViewName}
                          uploadedLayer={uploadedLayer}
                          handleSuccess={handleSuccess}
                          setOneFeature={setOneFeature}
                          fillOpacity={item.fillOpacity}
                          selectedLayers={selectedLayers}
                          setLayersCount={setLayersCount}
                          setHoverMapKey={setHoverMapKey}
                          otterfeatureId={item.featureId}
                          strokeWeight={item.strokeWeight}
                          measurementUnit={measurementUnit}
                          setUploadedLayer={setUploadedLayer}
                          setSelectedLayers={setSelectedLayers}
                          setLayersRestrict={setLayersRestrict}
                          isContextInteraction={isContextInteraction}
                          handleAccordianToggle={handleAccordianToggle}
                          setHoverOrderFeatureId={setHoverOrderFeatureId}
                          featureMeasurement={totalFeatureMeasurement(
                            item.data
                          )}
                          setHoverOrderFeatureElement={
                            setHoverOrderFeatureElement
                          }
                          setMultipleContextInteraction={
                            setMultipleContextInteraction
                          }
                        />
                      );
                    })}
                  </div>
                  <div className='share-Powered-by-box'>
                    <p>Powered by</p>
                    <img src={OttermapLogo} alt="ottermap logo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          : 
          <div className="mobile-draft-progress-main-container">
          <Global
            styles={{
              ".MuiDrawer-root > .MuiPaper-root": {
                height: "40%",
                overflow: "visible",
              },
              ".css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop": {
                display: "none !important",
              },
              ".css-9emuhu-MuiPaper-root-MuiDrawer-paper": {
                overflowY: "visible",
              },
            }}
          />
          <SwipeableDrawer
          anchor="bottom"
          open={isMobileDrawerOpen}
          disableSwipeToOpen={false}
          onOpen={() => setIsMobileDrawerOpen(true)}
          onClose={() => setIsMobileDrawerOpen(false)}
           swipeAreaWidth={56}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Box
                     sx={{
                         right: 0,
                         left: 0,
                         position: 'absolute',
                         visibility: 'visible',
                         top: '-63px',
                         height: "130%",
                        //  overflowY: "auto",
                         borderTopLeftRadius: 8,
                         borderTopRightRadius: 8,
                     }}
                 >
          <Puller />
          <div
            style={{
              zIndex: "105",
              height: "100%",
              width: "100%",
              position: "relative",
              background: "white",
              boxSizing: "border-box",
              padding: "10px 3px",
              overflowY: "scroll !important",
              paddingTop: "20px"
            }}
            className="mobile-flow-wrapper"
          >
              <div className="share-property-inner-cont">
                <div
                  className="property-address-container-main"
                  style={{
                    width: '94%',
                    padding: '10px',
                    fontSize: '0.7rem',
                    borderRadius: '8px',
                    background: '#519259',
                  }}
                >
                  
                  <Tooltip title={address} placement='top'>
                   <div className="share-property-address-container-main">
                     <PropertyIcon className="property-icon" />
                     <p>{address ?? ""}</p>
                   </div>
                  </Tooltip>
                  {/* <PropertyIcon className="property-icon" />
                  <p>{address}</p> */}
                </div>
                <div
                  className="feature-visibility-container"
                  style={{
                    background: 'transparent',
                    padding: '5px 0 0 0',
                  }}
                >
                  <div className="close-btn-div" style={{ marginTop: isSmallerThan600 ? "20px" : '1%', marginBottom: isSmallerThan600 && "20px" }}>
                    <span>Measurement Panel:</span>
                    {!isSmallerThan600 && <div
                      style={
                        status === STATUS.DRAFT || status === STATUS.IN_PROGRESS
                          ? {
                            height: '30%',
                          }
                          : null
                      }
                      id="map-leftbar-close-btn"
                      className={`map-leftbar-close-btn ${isCloseBtn ? '' : 'slide-close-hidden'
                        }`}
                    >
                      <button
                        className="close-feature-container"
                        onClick={() => {
                          toggleSlide();
                          toggleSlideBtn();
                        }}
                      >
                        <Arrow />
                      </button>
                    </div>}
                  </div>
                  <div className="measurement-panel-header">
                    <div>Features</div>
                    <div className="measurement-panel-header-right">
                      Measurement
                      <select
                        defaultValue="sqft"
                        onClick={(e) => changeMeasurementUnit(e.target.value)}
                        name="units"
                        id="units"
                      >
                        <option value="acres">acres</option>
                        <option value="sqft">sqft</option>
                      </select>
                    </div>
                  </div>
                  <div className="new-toggle-feature-mc">
                    <div
                      className="toggle-all-layers-mc"
                      style={{ width: '55%',  }}
                    >
                      <div className="mc-checkbox" style={{marginLeft: "0px", marginRight: isSmallerThan600 && "10px"}}>
                        <input
                          style={{margin: "0px"}}
                          type="checkbox"
                          checked={orderActive || oneFeature}
                          onChange={() => {
                            if (oneFeature) {
                              setOneFeature(false);
                            }
                            setOrderActive(!orderActive);
                          }}
                          className="toggle-all-layers-checkbox"
                        />
                      </div>
                      <span>All Layers</span>
                    </div>
                    <Tooltip
                      className="toggle-all-layers-mc"
                      title={
                        nearmapDisabled === true ? 'Image not present' : ''
                      }
                    >
                      <div className="toggle-all-layers">
                        {nearmapLoader ? (
                          <div className="nearmap-loader"></div>
                        ) : (
                          <div className="mc-checkbox">
                            <input
                              disabled={nearmapDisabled}
                              type="checkbox"
                              checked={nearMapActive}
                              onChange={() => {
                                setNearMapActive(!nearMapActive);
                              }}
                            />
                          </div>
                        )}
                        <span>HD Image</span>
                      </div>
                    </Tooltip>
                  </div>
                  <div className="measurement-panel-data">
                    <ParcelAccordian
                      number={-1}
                      result={result}
                      path={polygonList}
                      selected={selected}
                      setPath={setPolygonList}
                      orderActive={orderActive}
                      setSelected={setSelected}
                      measurementUnit={measurementUnit}
                    />
                    {uploadedLayer.map((item, index) => {
                      return (
                        <Accordian
                          elem={elem}
                          key={index}
                          number={index}
                          viewId={viewId}
                          name={item.name}
                          data={item.data}
                          type={item.type}
                          layerId={item.id}
                          hex2rgba={hex2rgba}
                          selected={selected}
                          modalShow={modalShow}
                          isPreview={isPreview}
                          visible={item.visible}
                          toolbarRef={toolbarRef}
                          hoverMapKey={hoverMapKey}
                          setSelected={setSelected}
                          layersCount={layersCount}
                          handleError={handleError}
                          fillColor={item.fillColor}
                          setZoomLayer={setZoomLayer}
                          setModalShow={setModalShow}
                          outerViewName={outerViewName}
                          uploadedLayer={uploadedLayer}
                          handleSuccess={handleSuccess}
                          setOneFeature={setOneFeature}
                          fillOpacity={item.fillOpacity}
                          selectedLayers={selectedLayers}
                          setLayersCount={setLayersCount}
                          setHoverMapKey={setHoverMapKey}
                          otterfeatureId={item.featureId}
                          strokeWeight={item.strokeWeight}
                          measurementUnit={measurementUnit}
                          setUploadedLayer={setUploadedLayer}
                          setSelectedLayers={setSelectedLayers}
                          setLayersRestrict={setLayersRestrict}
                          isContextInteraction={isContextInteraction}
                          handleAccordianToggle={handleAccordianToggle}
                          setHoverOrderFeatureId={setHoverOrderFeatureId}
                          featureMeasurement={totalFeatureMeasurement(
                            item.data
                          )}
                          setHoverOrderFeatureElement={
                            setHoverOrderFeatureElement
                          }
                          setMultipleContextInteraction={
                            setMultipleContextInteraction
                          }
                        />
                      );
                    })}
                  </div>
                  <div className='share-Powered-by-box'>
                    <p>Powered by</p>
                    <img src={OttermapLogo} alt="ottermap logo" />
                  </div>
                </div>
              </div>
          </div>
          </Box>
          </SwipeableDrawer>
          </div>
        ) : null}
        
      </div>
    </div>
  );
};

export default OrderIdShare;
