import axios from "axios";
import "./MapLeftBar.css";
import "./ParcelSelection.css";
import { toLonLat } from "ol/proj";
import { useParams } from "react-router-dom";
import { STATUS } from "../../utils/constants";
import AuthToken from "../../context/AuthToken";
import { ORDER_URL } from "../../helper/ApiUrl";
import AddressBar from "../AddressBar/AddressBar";
import { TOAST_TYPE } from "../../utils/constants";
import React, { useEffect, useCallback } from "react";
import { useScreenType } from "../../utils/commonMethods";
import total_area_icon from "../../assets/total-area.svg";
import edit_parcel_icon from "../../assets/edit-parcel.svg";
import draw_parcel_icon from "../../assets/draw-parcel.svg";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import parcelStyle from "../../OpenLayers/OLStyles/ParcelStyle";
import PolygonCheckBox from "../PolygonCheckBox/PolygonCheckbox";
import { ReactComponent as Arrow } from "../../assets/close.svg";
import { ReactComponent as CheckIcon } from "../../assets/Check.svg";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import cutPolygon from "../../OpenLayers/OLInteractions/CutInteraction";
import drawPolygon from "../../OpenLayers/OLInteractions/DrawInteraction";
import PropertyInformation from "../PropertyInformation/PropertyInformation";
import removeInteraction from "../../OpenLayers/OLFeatures/EscRemoveInteraction";
import removeAllInteraction from "../../OpenLayers/OLFeatures/RemoveInteraction";
import Tooltip from "@mui/material/Tooltip";
import NewPolygonCheckBox from "../PolygonCheckBox/NewPolygoncheckbox";
import CropPortraitIcon from '@mui/icons-material/CropPortrait';

export default function ParcelSelection({
  map,
  path,
  result,
  status,
  address,
  setPath,
  setOpen,
  setStep,
  resetData,
  parcelRef,
  isCloseBtn,
  parcelData,
  editPolygon,
  toggleSlide,
  handleError,
  setZoomLayer,
  setParcelEdit,
  handleSuccess,
  setParcelStyle,
  toggleSlideBtn,
  setPolygonList,
  navigateAddress,
  setHoverParcelId,
  setNearmapcoords,
  setHoverParcelElement,
  isDrawActive,
  isEditActive,
  isCutActive,
  setIsDrawActive,
  setIsEditActive,
  setIsCutActive,
  clearedSteps,
  setClearedSteps
}) {
  let polygonList = path;
  const params = useParams();
  const isMobile = useScreenType();

  const coordinatesToLatLong = () => {
    parcelData = [];
    for (let i = 0; i < path.length; i++) {
      const coordinate = [];
      let coords = path[i].data;
      for (let j = 0; j < coords.length; j++) {
        const singlePolygon = coords[j];
        const singlePolygonData = [];
        for (let k = 0; k < singlePolygon.length; k++) {
          let lonLat = toLonLat(singlePolygon[k]);
          singlePolygonData.push(lonLat);
        }
        coordinate.push(singlePolygonData);
      }
      parcelData.push(coordinate);
    }
    return parcelData;
  };

  const confirmParcel = () => {
    setParcelEdit(false);
    setIsDrawActive(false)
    setIsCutActive(false)
    setIsEditActive(false)
    document.body.classList.remove("cut-cursor");
    document.body.classList.remove("edit-cursor");
    document.body.classList.remove("split-cursor");
    document.body.classList.remove("crosshair-cursor");
    if (!path.length)
      handleSuccess(TOAST_TYPE.WARNING, "Draw one or more parcel");
    else {
      removeAllInteraction(map);
      parcelData = coordinatesToLatLong();
      let data = {
        parcel: {
          geometry: {
            type: "Polygon",
            coordinates: parcelData,
          },
        },
      };
      axios({
        url: `${ORDER_URL}${params.id}`,
        method: "PUT",
        data: data,
        headers: {
          Authorization: `Token ${AuthToken()}`,
        },
      })
        .then((res) => {
          if ("data" in res) {
            setParcelStyle(parcelStyle);
            setStep(3);
            setClearedSteps([...clearedSteps, 2])
            handleSuccess(
              TOAST_TYPE.SUCCESS,
              "Property boundary confirmed  successfully"
            );
          }
        })
        .catch((err) => {
          handleError(err);
        });
    }
  };

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setIsDrawActive(false)
      setIsCutActive(false)
      setIsEditActive(false)
      removeAllInteraction(map);
      document.body.classList.remove("cut-cursor");
      document.body.classList.remove("edit-cursor");
      document.body.classList.remove("split-cursor");
      document.body.classList.remove("crosshair-cursor");
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  // reactivate 30-5-2024
  useEffect(() => {
    console.log(polygonList, "In useEffect Polygon List parcelselection.jsx ");
  }, [polygonList]);


  const reActivateFunctionality = ( polygonList) => {
    // setPolygonList([]);
    console.log(isDrawActive, "isDrawActive in Reactivate function");
    console.log(polygonList, "after reactivate Polygon List ");
    console.log("after reactivate Polygon List ");

    if (isDrawActive) {
      // removeAllInteraction(map);

      setOpen(true);
      setIsDrawActive(true);
      setIsEditActive(false);
      setIsCutActive(false);
      drawPolygon(
        map,
        parcelRef,
        polygonList,
        setZoomLayer,
        setParcelEdit,
        setPolygonList,
        setParcelStyle,
        handleSuccess
      );
    } 
    else if (isEditActive) {
      setOpen(false);
      editPolygon(polygonList);
    }
     else if (isCutActive) {
      cutPolygon(
        map,

        polygonList,
        setZoomLayer,
        setParcelEdit,
        setPolygonList,
        setParcelStyle,
        handleSuccess
      );
    }
  };

  
  // useEffect(() => {
  //   reActivateFunctionality()
    
  // }, [isDrawActive, isEditActive, isCutActive ,polygonList]);

  return (
    <div className="property-container-no-progress">
      <div className="property-inner-cont">
        {/* {!isMobile && (
          <AddressBar
            address={address}
            navigateAddress={navigateAddress}
            resetData={resetData}
          />
        )} */}
        {/* <PropertyInformation
          handleError={handleError}
          handleSuccess={handleSuccess}
        /> */}
        <div className="property-attribute-cont">
          <div className="close-btn-div">
             <span>Modify Parcel</span>
            <div
              style={
                status === STATUS.DRAFT || status === STATUS.IN_PROGRESS
                  ? {
                      height: "30%",
                    }
                  : null
              }
              className={`map-leftbar-close-btn ${
                isCloseBtn ? "" : "slide-close-hidden"
              }`}
            >
              <button
                className="close-feature-container"
                onClick={() => {
                  toggleSlide();
                  toggleSlideBtn();
                }}
              >
                <Arrow />
              </button>
            </div>
          </div>
          
            <p className="instruction-for-the-feature">
              Please note that you can add multiple Parcels/ AOI on single order.
            </p>
          <div className="draw-edit-button-cont">
            <Tooltip title="Draw Polygon" placement="top">
              <button
                onClick={() => {
                  //  HA
                  removeAllInteraction(map); // Added line
                  setOpen(true);
                  drawPolygon( map, parcelRef, polygonList, setZoomLayer, setParcelEdit, setPolygonList, setParcelStyle, handleSuccess);
                  setIsDrawActive(true);
                  setIsEditActive(false);
                  setIsCutActive(false);
                }}
                className={ isDrawActive ? "draw-parcel-button-active" : "draw-parcel-button"}
              >
                <img src={draw_parcel_icon} alt="draw_parcel_icon" />
                Draw
              </button>
            </Tooltip>
            <Tooltip title="Modify Polygon" placement="top">
              <button
                onClick={() => {
                  removeAllInteraction(map); // Added line
                  setOpen(false);
                  editPolygon();
                  setIsEditActive(true);
                  setIsDrawActive(false);
                  setIsCutActive(false);
                }}
                className={ isEditActive ? "edit-parcel-button-active" : "edit-parcel-button" }
              >
                <img src={edit_parcel_icon} alt="edit_parcel_icon" />
                Edit
              </button>
            </Tooltip>
            <Tooltip title="Cut Polygon" placement="top">
              <button
                onClick={() => {
                  removeAllInteraction(map); // Added line
                  setOpen(false);
                  cutPolygon( map, polygonList, setZoomLayer, setParcelEdit, setPolygonList, setParcelStyle, handleSuccess);
                  setIsDrawActive(false);
                  setIsCutActive(true);
                  setIsEditActive(false);
                }}
                className={ isCutActive ? "cut-parcel-button-active" : "cut-parcel-button" }
              >
                <ContentCutIcon className="cut-parcel-icon" /> Cut
              </button>
            </Tooltip>
          </div>
          <div className="parcel-id-container">
            {path?.length > 0 && <div className="parcel-ids">
              {path.map((poly, index) => (
                <PolygonCheckBox
                  map={map}
                  data={poly}
                  path={path}
                  id={poly.id}
                  index={index}
                  key={poly.id}
                  setPath={setPath}
                  setHoverParcelId={setHoverParcelId}
                  setHoverParcelElement={setHoverParcelElement}
                  // ha
                  polygonList={polygonList}
                  setPolygonList={setPolygonList}
                  reActivateFunctionality={reActivateFunctionality}
                  //june 07 2024
                  isDrawActive={isDrawActive}
                  isEditActive={isEditActive}
                  isCutActive={isCutActive}
                  setIsDrawActive={setIsDrawActive}
                  setIsEditActive={setIsEditActive}
                  setIsCutActive={setIsCutActive}
                  setClearedSteps={setClearedSteps}
                  clearedSteps={clearedSteps}


                />
              ))}
            </div>}
            <div className="total-area-container">
              <img src={total_area_icon} alt="total_area_icon" />
              Total area {result.toFixed(2)} acre
            </div>
          </div>
        </div>
        <div className="property-function-button">
          <button className="ottermap-button" style={{width: "100%"}} onClick={confirmParcel}>
            <CheckIcon className="check-icon" /> <span>Confirm Boundary</span>
          </button>
        </div>
      </div>
    </div>
  );
}
