import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import AuthToken from '../../context/AuthToken';
import { LINE_CUT_URL } from '../../helper/ApiUrl';
import calculateLineArea from '../DIYFeatures/CalculateLineArea';

const cutLineString = (
  id,
  name,
  backEndData,
  uploadedLayer,
  setUploadedLayer
) => {
  const token = AuthToken();

  axios
    .post(LINE_CUT_URL, backEndData, {
      headers: {
        // Authorization: `Token ${token}`,
        Accept: 'application/json',
      },
    })
    .then((response) => {
      const newLine = JSON.parse(response.data.data);
      console.log(newLine);
      uploadedLayer.map((layer) => {
        if (layer.name === name) {
          layer.data = layer.data.filter((feature) => feature.id != id);
        }
      });
      if (newLine.features[0].geometry) {
        newLine.features.map((item) => {
          const frontEndId = uuidv4();
          uploadedLayer.map((layer) => {
            if (layer.name === name) {
              const cutData = {
                id: frontEndId,
                visible: true,
                data: item.geometry.coordinates,
                measurement: calculateLineArea(item.geometry.coordinates),
              };
              layer.data.push(cutData);
            }
          });
        });
      }
      setUploadedLayer([...uploadedLayer]);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default cutLineString;
