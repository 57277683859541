import axios from "axios";
import "./OrderNotesCommentSection.css";
import CommentFormat from "./CommentFormat";
import { useParams } from "react-router-dom";
import SendIcon from '@mui/icons-material/Send';
import { ORDER_URL } from "../../helper/ApiUrl";
import AuthToken from "../../context/AuthToken";
import React, { useEffect, useState } from "react";
import { MentionsInput, Mention } from 'react-mentions';
import { useScreenType } from "../../utils/commonMethods";
import { useUserContext } from "../../context/UserContext";

const mentionStyles = {
    color: 'wheat',
};

export default function OrderNotesCommentSection({ orderNoteData, usersDetails, orderNoteEditable }) {

    const token = AuthToken();
    const { id } = useParams();
    const isMobile = useScreenType();
    const { userInfo } = useUserContext();
    const [users, setUsers] = useState([]);
    const [comments, setComments] = useState();
    const [sendComment, setSendComment] = useState(false);
    const [commentAdded, setCommentAdded] = useState(false);
    const [currentComment, setCurrentComment] = useState("");

    const mentionsInputStyles = {
        control: {
            backgroundColor: 'white',
            fontSize: 14,
            borderRadius: "8px"
        },
        suggestions: {
            list: {
                backgroundColor: 'white',
                color: '#000',
                fontSize: '0.8rem',
                width: '10rem',
            },
            item: {
                padding: '0.5rem 1rem',
                '&focused': {
                    backgroundColor: '#ffffff',
                },
            },
        },
    }

    var elem = document.getElementById('comments-data-container');
    if (elem) elem.scroll({ top: elem.scrollHeight });

    const addComment = () => {
        if (currentComment) {
            setComments([...comments, { comment: currentComment, created_by: userInfo.name }]);
            setCurrentComment("");
            let formData = new FormData();
            formData.append("comment", currentComment);
            axios({
                url: `${ORDER_URL}${id}/notes/${orderNoteData.id}/comment`,
                method: "POST",
                data: formData,
                headers: {
                    Authorization: `Token ${token}`,
                },
            })
                .then((res) => {
                    if ("data" in res) {
                        setCommentAdded(true);
                        setSendComment(false);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    useEffect(() => {
        usersDetails.map((item, index) => {
            users.push({
                id: item.id,
                display: item.name
            });
        });
        axios({
            url: `${ORDER_URL}${id}/notes/${orderNoteData.id}/comment`,
            method: "GET",
            headers: {
                Authorization: `Token ${token}`,
            },
        })
            .then((res) => {
                if ("data" in res) {
                    setComments(res.data.data);
                    setCommentAdded(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [commentAdded, orderNoteData]);

    useEffect(() => {
        const commentInputSection = document.getElementById('comment-input-section');
        if (commentInputSection) {
            commentInputSection.addEventListener('keydown', (e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    setSendComment(true);
                }
            });
        }
    }, [])

    useEffect(() => {
        if (sendComment) addComment();
    }, [sendComment])

    return (
        <div className="order-note-comments-main-cont">
            {comments ?
                (comments.length > 0 ?
                    <div id="comments-data-container" style={{ background: "#FFFFFF" }} className="comments-data-cont">
                        {comments.map((item, index) => {
                            return (
                                <div key={index} className="comment-data">
                                    <div className="user-initial-comm">{item.created_by === userInfo.name ? "You" : item.created_by} commented</div>
                                    <CommentFormat comment={item.comment} />
                                </div>
                            );
                        })}
                    </div> : null
                )
                : <div style={{ textAlign: "center" }}>Loading Comments...</div>
            }
            <div className="add-comment-cont">
                <MentionsInput
                    style={mentionsInputStyles}
                    id="comment-input-section"
                    className="comment-input-section"
                    value={currentComment}
                    onChange={((e) => setCurrentComment(e.target.value))}
                    type="text"
                    placeholder="Type to add comments">
                    <Mention
                        style={mentionStyles}
                        trigger='@'
                        data={users}
                    />
                </MentionsInput>
                <SendIcon id="add-comment-button" onClick={addComment} style={{ cursor: "pointer" }} />
            </div>
        </div>
    );
}