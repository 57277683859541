import {
  DIY_PARCEL_URL,
  DIY_NEARMAP_DATE,
  REMOVE_HOLES_URL,
} from '../../helper/ApiUrl';
import axios from 'axios';
import './DiyOrderPage.css';
import { toLonLat } from 'ol/proj';
import { transform } from 'ol/proj';
import LayerGroup from 'ol/layer/Group';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import { Fill, Stroke, Style } from 'ol/style';
import { Select, Modify } from 'ol/interaction';
import AuthToken from '../../context/AuthToken';
import { TOAST_TYPE } from '../../utils/constants';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { altShiftKeysOnly } from 'ol/events/condition';
import React, { useEffect, useRef, useState } from 'react';
import NavigationIcon from '@mui/icons-material/Navigation';
import rotateMap from '../../OpenLayers/OLControls/RotateMap';
import setNearmap from '../../OpenLayers/OLFeatures/SetNearmap';
import selectedStyle from '../../OpenLayers/OLStyles/EditStyle';
import DiyLeftBar from '../../components/DiyLeftBar/DiyLeftBar';
import { useAddressContext } from '../../context/AddressContext';
import renderParcel from '../../OpenLayers/OLFeatures/RenderParcel';
import handleZoomIn from '../../OpenLayers/OLControls/ZoomInControl';
import hoverOnParcel from '../../OpenLayers/OLFeatures/HoverOnParcel';
import calculateArea from '../../OpenLayers/OLFeatures/CalculateArea';
import handleZoomOut from '../../OpenLayers/OLControls/ZoomOutControl';
import AddressSearch from '../../components/AddressSearch/AddressSearch';
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';
import initializeMap from '../../OpenLayers/DIYFeatures/InitializeDiyMap';
import resetRotation from '../../OpenLayers/OLControls/MapRotationControl';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import initializeMarker from '../../OpenLayers/OLFeatures/InitializeMarker';
import handleLayerTypeMap from '../../OpenLayers/OLControls/MapViewControl';
import ImageryDropdown from '../../components/ImageryDropdown/ImageryDropdown';
import removeAllInteraction from '../../OpenLayers/OLFeatures/RemoveInteraction';
import handleLayerTypeSatellite from '../../OpenLayers/OLControls/SatelliteViewControl';
import LayersTypeContainer from '../../components/LayersTypeContainer/LayersTypeContainer';

import { encodeJwtToken } from '../../utils/EncodeJwt';

import useMediaQuery from '@mui/material/useMediaQuery';


const DiyOrderPage = ({ handleError, handleSuccess }) => {
  const tileGridLyr = useRef();
  const token = AuthToken();
  const markerRef = useRef();
  const parcelRef = useRef();
  const mapElement = useRef();
  const navigate = useNavigate();
  const searchBarRef = useState();
  const [map, setMap] = useState();
  const [popup, showPopup] = useState(false);
  const [status, setStatus] = useState(null);
  const [oldAddress, setOldAddress] = useState(null);
  const [hoverElem, setHoverElem] = useState();
  const [direction, setDirection] = useState();
  const { address, latLng, setLatLng } = useAddressContext();
  const [hoverMapKey, setHoverMapKey] = useState();
  const [zoomLayer, setZoomLayer] = useState(true);
  const [polygonList, setPolygonList] = useState([]);
  const [parcelEdit, setParcelEdit] = useState(false);
  const [hoverParcelId, setHoverParcelId] = useState();
  const [nearmapcoords, setNearmapcoords] = useState([]);
  const [featureSelected, setFeatureSelected] = useState([]);
  const [nearmapImageDate, setNearmapImageDate] = useState('');
  const [nearmapSurveyData, setNearmapSurveyData] = useState([]);
  const [hoverParcelElement, setHoverParcelElement] = useState();
  const [selectedHoverFeature, setSelectedHoverFeature] = useState();
  const [removeEventKey, setRemoveEventKey] = useState();
  const isSmallerThan820px = useMediaQuery('(max-width:820px)');
  const [hoverData, setHoverData] = useState({
    id: null,
    name: null,
    measurement: null,
    measurement_type: null,
  });

  markerRef.current = new LayerGroup({
    title: 'Marker',
    layers: [],
  });

  parcelRef.current = new LayerGroup({
    title: 'Parcel',
    layers: [],
  });

  tileGridLyr.current = new LayerGroup({
    title: 'Tile Imagery',
    layers: [],
  });

  const [parcelStyle, setParcelStyle] = useState([
    new Style({
      stroke: new Stroke({
        color: 'red',
        width: 2,
      }),
      fill: new Fill({
        color: 'rgb(255, 255, 255,0.2)',
      }),
    }),
  ]);

  const select = new Select({
    wrapX: false,
    style: selectedStyle,
    condition: altShiftKeysOnly,
  });

  const modify = new Modify({
    features: select.getFeatures(),
  });

  const addEditInteraction = () => {
    document.getElementsByClassName('edit-parcel-button-active')[0].click();
  };

  //////////// --------- All the logic that depends on the updated polygonList is moved inside the setPolygonList functional update form ---/
const updateThePolygonList = (List) => {
  setPolygonList(List);
};

  const editPolygon = (List) => {
    console.log(List, " in DIY MOFIFYEND polygonlist in edit parcel");
    
  if (List && List.length > 0) {
    updateThePolygonList(List);
  }

    setZoomLayer(false);
    setParcelEdit(true);
    removeAllInteraction(map);
    map.addInteraction(select);
    map.addInteraction(modify);
    // document.body.classList.remove("cut-cursor");
    // document.body.classList.remove('split-cursor');
    // document.body.classList.remove('crosshair-cursor');
    // document.body.classList.add('edit-cursor');

    // const mapContainer = document.getElementById('map-cont');
    const mapContainer = document.querySelector('.ol-viewport');

    mapContainer.classList.remove('crosshair-cursor');
    mapContainer.classList.remove('split-cursor');
    mapContainer.classList.remove('cut-cursor');
    mapContainer.classList.add('edit-cursor');
    // let mapContainer = document.getElementById('map-cont');
    // mapContainer.classList.add('edit-cursor');


    map.getLayers().forEach((layer) => {
      if (layer.get('title') && layer.get('title') === 'Parcel') {
        var childLayers = layer.getLayers().getArray();
        var length = childLayers.length;
        for (var i = 0; i < length; i++) {
          var lay = childLayers[i];
          lay.getSource().forEachFeature(function (feature) {
            select.getFeatures().push(feature);
          });
        }
      }
    });

    modify.on('modifyend', function (e) {
      const id = e.features.getArray()[0].getId();
      var coords = e.features.getArray()[0].getGeometry().getCoordinates();

      // if (coords.length > 1) {
      //   const data = { parcel: coords };
      //   axios({
      //     url: `${REMOVE_HOLES_URL}`,
      //     method: 'post',
      //     timeout: 8000,
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //     data: data,
      //   })
      //     .then((res) => {
      //       let updatedPolygon = polygonList.map((item) => {
      //         if (item.id === id) {
      //           return {
      //             ...item,
      //             data: res.data.data,
      //             measurement: calculateArea(coords),
      //           };
      //         }
      //         return item;
      //       });
      //       setPolygonList(updatedPolygon);
      //       setParcelStyle(selectedStyle);
      //       removeAllInteraction(map);
      //       addEditInteraction();
      //     })
      //     .catch((err) => console.error(err));
      // }

      // let updatedPolygon = polygonList.map((item) => {
      //   if (item.id === id) {
      //     return {
      //       ...item,
      //       data: coords,
      //       measurement: calculateArea(coords),
      //     };
      //   }
      //   return item;
      // });
      // setPolygonList(updatedPolygon);
      // setParcelStyle(selectedStyle);
      // removeAllInteraction(map);
      // addEditInteraction();
      if (coords.length > 1) {
        console.log("this if block run ");
        const data = { parcel: coords };
  
        axios({
          url: `${REMOVE_HOLES_URL}`,
          method: 'post',
          timeout: 8000,
          headers: {
            "x-api-key":`${encodeJwtToken()}`,
            'Content-Type': 'application/json',
          },
          data: data,
        })
        .then((res) => {
          setPolygonList((prevPolygonList) => {
            let updatedPolygon = prevPolygonList.map((item) => {
              if (item.id === id) {
                return {
                  ...item,
                  data: res.data.data,
                  measurement: calculateArea(coords),
                };
              }
              return item;
            });
            console.log(updatedPolygon, "updatedPolygon in editpolygon openlayer");
            return updatedPolygon;
          });
          setParcelStyle(selectedStyle);
          removeAllInteraction(map);
          addEditInteraction();
        })
        .catch((err) => console.error(err));
      } else {
        setPolygonList((prevPolygonList) => {
          let updatedPolygon = prevPolygonList.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                data: coords,
                measurement: calculateArea(coords),
              };
            }
            return item;
          });
          return updatedPolygon;
        });
        setParcelStyle(selectedStyle);
        removeAllInteraction(map);
        addEditInteraction();
      }
   
   
    });
  };

  useEffect(() => {
    if (map) {
      renderParcel(
        map,
        status,
        zoomLayer,
        parcelRef,
        polygonList,
        parcelStyle,
        setNearmapcoords
      );
    }
  }, [map, polygonList, parcelStyle]);

  useEffect(() => {
    const elem = document.getElementById('popup-cont');
    setHoverElem(elem);
    initializeMarker(
      map,
      latLng,
      hex2rgba,
      hoverMapKey,
      setHoverData,
      showPopup,
      markerRef,
      setHoverMapKey,
      setSelectedHoverFeature,
      elem
    );
  }, [latLng]);

  const hex2rgba = (hex, alpha) => {
    if (hex) {
      const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
      return `rgba(${r},${g},${b},${alpha})`;
    }
  };

  useEffect(() => {
    if (hoverParcelElement && hoverParcelId) {
      hoverOnParcel(map, hoverParcelElement, hoverParcelId, parcelEdit);
    }
  }, [hoverParcelId, hoverParcelElement]);

  useEffect(() => {
    if (map && latLng) {
      map.getView().animate({
        zoom: 19,
        duration: 4000,
        center: transform(latLng, 'EPSG:4326', 'EPSG:3857'),
      });
      getNearmapSurveyData();
      searchBarRef.current.style.display = 'none';
    }
  }, []);

  useEffect(() => {
    initializeMap(mapElement, setMap, setLatLng,setRemoveEventKey);
  }, []);

  // useEffect(() => {
  //   console.log("updated coordinate", updatedCoordinate);
  //     let newValue = updatedCoordinate;
  //     console.log("newvalue",newValue);
  //     // setLatLng(newValue);

  // }, [updatedCoordinate, setUpdatedCoordinate]);


  const coordinatesToLatLong = () => {
    const parcelData = [];
    for (let i = 0; i < polygonList.length; i++) {
      const coordinate = [];
      let coords = polygonList[i].data;
      for (let j = 0; j < coords.length; j++) {
        const singlePolygon = coords[j];
        const singlePolygonData = [];
        for (let k = 0; k < singlePolygon.length; k++) {
          let lonLat = toLonLat(singlePolygon[k]);
          singlePolygonData.push(lonLat);
        }
        coordinate.push(singlePolygonData);
      }
      parcelData.push(coordinate);
    }
    return parcelData;
  };

  const saveDiyParcel = () => {
    if (polygonList.length > 0 && featureSelected.length > 0) {
      const featureIds = [];
      featureSelected.forEach((feature) => {
        featureIds.push(feature.id);
      });
      removeAllInteraction(map);
      const parcelData = coordinatesToLatLong();

      console.log(parcelData, "parcelDataInDiy")
      const diyParcelData = {
        address: `${address}`,
        lat: latLng[1],
        long: latLng[0],
        feature_ids: featureIds,
        parcel: {
          geometry: {
            type: 'MultiPolygon',
            coordinates: parcelData,
          },
        },
      };
      let imageryDate = '';
      if (nearmapImageDate != 'satellite') {
        imageryDate = `?imagery=${nearmapImageDate}`;
      }
      axios
        .post(`${DIY_PARCEL_URL}${imageryDate}`, diyParcelData, {
          headers: {
            Authorization: `Token ${token}`,
            Accept: 'application/json',
          },
        })
        .then((response) => {
          if (response.data.data) {
            navigate(`/order/${response.data.data.order_id}`);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } else {
      handleSuccess(
        TOAST_TYPE.WARNING,
        'Please create parcel and add some features!'
      );
    }
  };

  const draftDiyParcel = () => {
    if (polygonList.length > 0 && featureSelected.length > 0) {
      const featureIds = [];
      featureSelected.forEach((feature) => {
        featureIds.push(feature.id);
      });
      removeAllInteraction(map);
      const parcelData = coordinatesToLatLong();
      const diyParcelData = {
        address: `${address}`,
        lat: latLng[1],
        long: latLng[0],
        feature_ids: featureIds,
        parcel: {
          geometry: {
            type: 'MultiPolygon',
            coordinates: parcelData,
          },
        },
      };
      axios
        .post(`${DIY_PARCEL_URL}?status=draft`, diyParcelData, {
          headers: {
            Authorization: `Token ${token}`,
            Accept: 'application/json',
          },
        })
        .then((response) => {
          if (response.data.data) {
            console.log(response.data.data);
            navigate(`/order/${response.data.data.order_id}`);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } else {
      handleSuccess(
        TOAST_TYPE.WARNING,
        'Please create parcel and add some features!'
      );
    }
  };

  const getNearmapSurveyData = () => {
    axios
      .get(`${DIY_NEARMAP_DATE}?lat=${latLng[1]}&long=${latLng[0]}`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        if (response.data.data) {
          setNearmapSurveyData(response.data.data);
          setNearmapImageDate(response.data.data[0].captureDate);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  

useEffect(()=>{
  console.log(`  nearmapSurveyData ------ ${ nearmapSurveyData}`)
},[nearmapSurveyData])

  useEffect(() => {
    if (map)
      if (nearmapImageDate === 'satellite') {
        map.getLayers().forEach((layer) => {
          if (layer && layer.get('title') === 'Tile Imagery') {
            map.removeLayer(layer);
          }
        });
      } else {
        map.getLayers().forEach((layer) => {
          if (
            layer &&
            layer.get('title') &&
            layer.get('title') === 'Tile Imagery'
          )
            map.removeLayer(layer);
        });
        if (nearmapcoords.length)
          nearmapcoords.map((item) => {
            setNearmap(map, nearmapImageDate, item, tileGridLyr);
          });
      }
  }, [nearmapcoords, nearmapImageDate]);

  if (map) {
    const view = map.getView();

    view.on('propertychange', (event) => {
      if (event.key === 'rotation') {
        const rotation = view.getRotation();
        const rotationInDegrees = (rotation * 180) / Math.PI;
        setDirection(rotationInDegrees);
      }
    });
  }

  return (
    <div
      ref={mapElement}
      id="map-cont"
      className="main-ol-container diy-container"
    >
      {/* <div class="badge">
        <i class="left"></i>
        <i class="right"></i>
        BETA
      </div> */}
      {address && (
        <DiyLeftBar
          map={map}
          markerRef={markerRef}
          parcelRef={parcelRef}
          oldAddress={oldAddress}
          editPolygon={editPolygon}
          polygonList={polygonList}
          setZoomLayer={setZoomLayer}
          searchBarRef={searchBarRef}
          setOldAddress={setOldAddress}
          handleSuccess={handleSuccess}
          saveDiyParcel={saveDiyParcel}
          setParcelEdit={setParcelEdit}
          setParcelStyle={setParcelStyle}
          draftDiyParcel={draftDiyParcel}
          setPolygonList={setPolygonList}
          featureSelected={featureSelected}
          setNearmapcoords={setNearmapcoords}
          nearmapImageDate={nearmapImageDate}
          setHoverParcelId={setHoverParcelId}
          setFeatureSelected={setFeatureSelected}
          setHoverParcelElement={setHoverParcelElement}
          removeEventKey={removeEventKey}

          
        />
      )}
      <div id="popup-cont" className="popup"></div>
      <div className="map-address-bar" ref={searchBarRef}>
        <div className='map-address-bar-child'>

          <AddressSearch
            handleError={handleError}
            handleSuccess={handleSuccess}
            />
        </div>
      </div>
      <div className="map-buttons">
        <div className="map-layer-button">
          <LayersTypeContainer
            map={map}
            handleLayerTypeMap={handleLayerTypeMap}
            handleLayerTypeSatellite={handleLayerTypeSatellite}
          />
          <div className="map-rotation-buttons">
            <Tooltip title="Rotate map 90deg left" placement="top-start">
              <Rotate90DegreesCcwIcon
                className="map-control-icon"
                onClick={() => rotateMap(map, -90)}
              />
            </Tooltip>
            <Tooltip title="Rotate map 90deg right" placement="top-start">
              <Rotate90DegreesCwIcon
                className="map-control-icon"
                onClick={() => rotateMap(map, 90)}
              />
            </Tooltip>
          </div>
        </div>
        <div className="map-zoom-buttons">
          <Tooltip
            title="Use Alt/Option+Shift+Drag to rotate the map. Press this button to reset."
            placement="top"
          >
            <button onClick={() => resetRotation(map)}>
              <NavigationIcon
                className="map-control-icon"
                sx={{ rotate: `${direction}deg` }}
              />
            </button>
          </Tooltip>
          <button onClick={() => handleZoomIn(map)}>
            <ZoomInIcon className="map-control-icon" />
          </button>
          <button onClick={() => handleZoomOut(map)}>
            <ZoomOutIcon className="map-control-icon" />
          </button>
        </div>
      </div>
      {address && nearmapSurveyData && polygonList.length ? (
        <div className="image-date-cont">
          <ImageryDropdown
            nearmapSurveyData={nearmapSurveyData}
            setNearmapImageDate={setNearmapImageDate}
          />
        </div>
      ) : null}
    </div>
  );
};

export default DiyOrderPage;
