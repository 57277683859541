import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const getToastOptions = () => {
  const isMobile = window.innerWidth < 820;
  return {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: isMobile ? { marginTop: '5vh' } : {}, // Add top margin if screen width is below 820px
  };
};

export const showToast = (type, message) => {
  const toastOptions = getToastOptions();

  switch (type) {
    case 'success':
      toast.success(message, toastOptions);
      break;
    case 'error':
      toast.error(message, toastOptions);
      break;
    case 'info':
      toast.info(message, toastOptions);
      break;
    case 'warning':
      toast.warn(message, toastOptions);
      break;
    default:
      toast(message, toastOptions);
      break;
  }
};
