import './App.css';
import * as React from 'react';
import LogRocket from 'logrocket';
import Home from './components/Home/Home';
import { useState, useEffect } from 'react';
import Project from './pages/Project/Project';
import { TOAST_TYPE } from './utils/constants';
import AuthPage from './pages/AuthPage/AuthPage';
import PrivateRoutes from './utils/PrivateRouter';
import Toaster from './components/Toaster/Toaster';
import SignedInRouter from './utils/SignedInRouter';
import TrialOrder from './pages/TrialOrder/TrialOrder';
import { useUserContext } from './context/UserContext';
import LandingPage from './pages/LandingPage/LandingPage';
import MyDocuments from './pages/MyDocuments/MyDocuments';
import PaymentPage from './pages/PaymentPage/PaymentPage';
import RedirectHomeRouter from './utils/RedirectHomeRouter';
import OrderIdShare from './pages/OrderIdShare/OrderIdShare';
import SettingsPage from './pages/SettingsPage/SettingsPage';
import DiyOrderPage from './pages/DiyOrderPage/DiyOrderPage';
import CustomToaster from './components/Toaster/CustomToster';
import UserSetting from './components/UserSetting/UserSetting';
import SuspendedPage from './pages/SuspendedPage/SuspendedPage';
import ProjectIdPage from './pages/ProjectIdPage/ProjectIdPage';
import PageNotFound from './components/PageNotFound/PageNotFound';
import OpenLayersPage from './pages/OpenLayersPage/OpenLayersPage';
import OtpVerifiedPage from './pages/OtpVerifiedPage/OtpVerifiedPage';
import UploadOrderPage from './pages/UploadOrderPage/UploadOrderPage';
import OrdersDashboard from './pages/OrdersDashboard/OrdersDashboard';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import TeamManagement from './components/TeamManagement/TeamManagement';
import OpenLayersIdPage from './pages/OpenLayersIdPage/OpenLayersIdPage';
import AccessDeniedPage from './pages/AccessDeniedPage/AccessDeniedPage';
import OrganizationPage from './pages/OrganizationPage/OrganizationPage';
import UploadBulkProject from './pages/UploadBulkProject/UploadBulkProject';
import TermAndConditions from './pages/TermAndConditions/TermAndConditions';
import JoinOrganization from './components/JoinOrganization/JoinOrganization';
import CreditManagement from './components/CreditManagement/CreditManagement';
import FeatureManagement from './components/FeatureManagement/FeatureManagement';
import PageNotAuthorized from './components/PageNotAuthorized/PageNotAuthorized';
import UploadBulkProjectId from './pages/UploadBulkProjectId/UploadBulkProjectId';
import CreateOrganization from './components/CreateOrganization/CreateOrganization';
import UploadOrderDashboard from './pages/UploadOrderDashboard/UploadOrderDashboard';
import OrganizationSetting from './components/OrganizationSetting/OrganizationSetting';
import { useFeedbackContext } from './context/FeedbackContext';
import ExplorePage from './pages/Explore/Explore.jsx'

   

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToast } from '../src/components/Toaster/ReactToastify';
import MyDocumentsComponent from './pages/MyDocuments/MyDocumentComponent';
import BulkImagesComponent from './pages/MyDocuments/BulkImagesComponent';
import FileExplorer from './pages/MyDocuments/FolderFile';
import RoutingFileExplorer from './pages/MyDocuments/RoutingFolderFile';


export default function App() {
  const location = useLocation();
  const url = window.location.href;
  const prod = process.env.REACT_APP_PROD;
  const role = localStorage.getItem('role');
  const { userInfo, selfCredits } = useUserContext();
  const [toasterData, setToasterData] = useState({});
  const [showToaster, setShowToaster] = useState(false);
  const pathDecider = location.pathname.substring(0, 12);
  const [showCustomToaster, setShowCustomToaster] = useState(false);



  useEffect(() => {
    // if (!url.includes('/upload/order'))
    //   LogRocket.init(`${process.env.REACT_APP_ROCKET_ID}`);
    // LogRocket.identify(userInfo.email, {
    //   name: userInfo.name,
    //   email: userInfo.email,
    // });
    if (userInfo) {

      // removed this code to disable the inspectlet
      // window.__insp.push(['identify', userInfo.email]);
      // window.__insp.push([
      //   'tagSession',
      //   { email: userInfo.email, name: userInfo.name },
      // ]);


      // Insert the User segmentation script
      const userSegmentationScript = document.createElement('script');
      userSegmentationScript.innerHTML = `
      window.usetifulTags = { userId : "${userInfo.email}" };
    `;
      document.head.appendChild(userSegmentationScript);

      // Insert the Usetiful script
      const usetifulScript = document.createElement('script');
      usetifulScript.async = true;
      usetifulScript.src = "https://www.usetiful.com/dist/usetiful.js";
      usetifulScript.setAttribute('id', 'usetifulScript');
      // usetifulScript.dataset.token = "dc0d935abb74ab1d4f5ecc4276fb6f9a";
      usetifulScript.dataset.token = process.env.REACT_APP_USETIFUL_TOKEN;
      document.head.appendChild(usetifulScript);

      return () => {
        // Cleanup function to remove the scripts when the component unmounts
        document.head.removeChild(userSegmentationScript);
        document.head.removeChild(usetifulScript);
      };
    }
  }, [userInfo]);

  const handleSuccess = (type, message) => {
    showToast(type, message);
  };
  
  const handleCustomSuccess = (type, message) => {
    showToast(type, message);
  };
  
  const handleError = (err) => {
    let error;
    if (err?.response?.data?.errorDesc) error = err.response.data.errorDesc;
    else if (err.response?.data?.message) error = err.response.data.message;
    else if (err.response?.data?.msg) error = err.response.data.msg;
    else if (err?.message) error = err.message;

    else error = 'Something went wrong';
    showToast('error', error);
  };
  
  const handleCustomError = (err) => {
    let error;
    if (err.response.data.errorDesc) error = err.response.data.errorDesc;
    else if (err.response.data.msg) error = err.response.data.msg;
    else if (err.response.data.message) error = err.response.data.message;
    else error = 'Something went wrong';
    showToast('error', error);
  };


  // const handleSuccess = (type, message) => {
  //   setShowToaster(true);
  //   setToasterData({ type: type, message: message });
  // };

  // const handleCustomSuccess = (type, message) => {
  //   setShowCustomToaster(true);
  //   setToasterData({ type: type, message: message });
  // };

  // const handleError = (err) => {
  //   let error;
  //   if (err.response.data.errorDesc) error = err.response.data.errorDesc;
  //   else if (err.response.data.msg) error = err.response.data.msg;
  //   else if (err.response.data.message) error = err.response.data.message;
  //   else error = 'Something went wrong';
  //   setToasterData({
  //     type: TOAST_TYPE.ERROR,
  //     message: error,
  //   });
  //   setShowToaster(true);
  // };

  // const handleCustomError = (err) => {
  //   let error;
  //   if (err.response.data.errorDesc) error = err.response.data.errorDesc;
  //   else if (err.response.data.msg) error = err.response.data.msg;
  //   else if (err.response.data.message) error = err.response.data.message;
  //   else error = 'Something went wrong';
  //   setToasterData({
  //     type: TOAST_TYPE.ERROR,
  //     message: error,
  //   });
  //   setShowCustomToaster(true);
  // };

  useEffect(() => {
    // if (url.includes(prod)) {
    if (url.includes(process.env.REACT_APP_DEV)) {
      window.analytics.page();
    }
  }, []);

  return (
    <div className="App">
      {showToaster ? (
        <Toaster
          type={toasterData.type}
          showToaster={showToaster}
          message={toasterData.message}
          setShowToaster={setShowToaster}
        />
      ) : null}
      {showCustomToaster ? (
        <CustomToaster
          type={toasterData.type}
          message={toasterData.message}
          showToaster={showCustomToaster}
          setShowToaster={setShowCustomToaster}
        />
      ) : null}
      {pathDecider === '/share/order' ? (
        <Routes>
          <Route
            path="/share/order/:id"
            element={
              <OrderIdShare
                handleError={handleError}
                handleSuccess={handleSuccess}
              />
            }
          />
        </Routes>
      ) : (
        <Routes>
          <Route element={<SignedInRouter />}>
            <Route
              path=""
              element={
                <AuthPage
                  handleError={handleError}
                  handleSuccess={handleSuccess}
                />
              }
            />
            <Route
              path="/signup"
              element={
                <AuthPage
                  handleError={handleError}
                  handleSuccess={handleSuccess}
                />
              }
            />
            <Route
              path="/forgot-password"
              element={
                <AuthPage
                  handleError={handleError}
                  handleSuccess={handleSuccess}
                />
              }
            />
            <Route
              path="/reset-password/user"
              element={
                <AuthPage
                  handleError={handleError}
                  handleSuccess={handleSuccess}
                />
              }
            />
            <Route path="otp-verified" element={<OtpVerifiedPage />} />
          </Route>
          <Route path="/organization" element={<OrganizationPage />} />
          <Route path="/organization/join" element={<JoinOrganization />} />
          <Route path="/organization/create" element={<CreateOrganization />} />
          <Route path="/terms-and-conditions" element={<TermAndConditions />} />
          <Route
              path="/explore"
              element={
                  <ExplorePage
                      handleError={handleError}
                      handleSuccess={handleSuccess}
                  />
              }
          />

          <Route element={<PrivateRoutes />}>
            {role === 'admin' || role === 'user' ? (

              <Route
                path="/"
                element={
                  <Home
                    handleError={handleError}
                    handleSuccess={handleSuccess}
                  />
                }
              >


                <Route
                  path="/upload/order"
                  element={
                    <UploadOrderDashboard
                      handleSuccess={handleSuccess}
                      handleError={handleError}
                    />
                  }
                />
                <Route
                  path="/upload/order/:id/tt34rees"
                  element={
                    <UploadOrderPage
                      handleError={handleError}
                      handleSuccess={handleSuccess}
                    />
                  }
                />
                <Route
                  path="/upload/order/bulk-orders"
                  element={
                    <UploadBulkProject
                      handleError={handleError}
                      handleSuccess={handleSuccess}
                    />
                  }
                />
                <Route
                  path="/upload/order/bulk-orders/:id"
                  element={
                    <UploadBulkProjectId
                      handleError={handleError}
                      handleSuccess={handleSuccess}
                    />
                  }
                />
                <Route
                  path="/preview/order/:id"
                  element={
                    <OpenLayersIdPage
                      handleError={handleError}
                      handleSuccess={handleSuccess}
                    />
                  }
                />
                <Route
                  path="/home"
                  element={
                    <LandingPage
                      handleError={handleError}
                      handleSuccess={handleSuccess}
                    />
                  }
                />
                <Route path="/map" element={<TrialOrder />} />
                <Route path="" element={<Navigate to="/home" />} />

                <Route
                  path="/project"
                  element={
                    <Project
                      handleError={handleError}
                      handleSuccess={handleSuccess}
                    />
                  }
                />
                <Route
                  path="/project/:id"
                  element={
                    <ProjectIdPage
                      handleError={handleError}
                      handleSuccess={handleSuccess}
                    />
                  }
                />
                <Route
                  path="/order"
                  element={
                    <OpenLayersPage
                      handleError={handleError}
                      handleSuccess={handleSuccess}
                    />
                  }
                />
                <Route
                  path="/order/:id"
                  element={
                    <OpenLayersIdPage
                      handleError={handleError}
                      handleSuccess={handleSuccess}
                      handleCustomError={handleCustomError}
                      handleCustomSuccess={handleCustomSuccess}
                    />
                  }
                />

                <Route
                  path="/upload/studio/:id"
                  element={
                    <OpenLayersIdPage
                      handleError={handleError}
                      handleSuccess={handleSuccess}
                    />
                  }
                />
                <Route
                  path="/diy/order"
                  element={
                    <DiyOrderPage
                      handleError={handleError}
                      handleSuccess={handleSuccess}
                    />
                  }
                />

                <Route
                  path="/dashboard"
                  element={
                    <OrdersDashboard
                      handleError={handleError}
                      handleSuccess={handleSuccess}
                    />
                  }
                />
                {/* <Route
                  path="/mydocs"
                  element={
                    <MyDocuments
                      handleError={handleError}
                      handleSuccess={handleSuccess}
                    />
                  }
                /> */}
                <Route
                  path="/mydocs"
                  element={
                    // <MyDocumentsComponent />
                    <BulkImagesComponent
                    handleError={handleError}
                    handleSuccess={handleSuccess}
                     />
                  }
                />
                <Route
                  path="/mydocs/:folderId"
                  element={
                    // <MyDocumentsComponent />
                    <RoutingFileExplorer 

                    handleError={handleError}
                    handleSuccess={handleSuccess}
                    />
                  }
                />
                <Route
                  path="/mydocs/files"
                  element={
                     <MyDocumentsComponent />
                    // <BulkImagesComponent />
                  }
                />
                <Route path="/setting" element={<SettingsPage />}>
                  <Route
                    path="/setting/user"
                    element={
                      <UserSetting
                        handleError={handleError}
                        handleSuccess={handleSuccess}
                      />
                    }
                  />

                  <Route
                    path="/setting/org"
                    element={
                      <OrganizationSetting
                        handleError={handleError}
                        handleSuccess={handleSuccess}
                      />
                    }
                  />
                  <Route path="/setting/team" element={<TeamManagement />} />
                  <Route
                    path="/setting/credit"
                    element={
                      <CreditManagement
                        handleError={handleError}
                        handleSuccess={handleSuccess}
                      />
                    }
                  />
                  <Route
                    path="/setting/feature"
                    element={
                      <FeatureManagement
                        handleError={handleError}
                        handleSuccess={handleSuccess}
                      />
                    }
                  />
                </Route>

                {/* {selfCredits ? (
                  <Route
                    path="payment"
                    element={
                      <PaymentPage
                        handleError={handleError}
                        handleSuccess={handleSuccess}
                      />
                    }
                  />
                ) : (
                  <Route
                    path="/payment"
                    element={<Navigate to="/setting/credit" />}
                  />
                )} */}
              </Route>
            ) : role === 'otter_user' ? (
              <Route path="/" element={<Home />}>
                <Route
                  path="/upload/order"
                  element={
                    <UploadOrderDashboard
                      handleSuccess={handleSuccess}
                      handleError={handleError}
                    />
                  }
                />
                <Route
                  path="/upload/studio/:id"
                  element={
                    <OpenLayersIdPage
                      handleError={handleError}
                      handleSuccess={handleSuccess}
                    />
                  }
                />
                <Route
                  path="/upload/order/:id/tt34rees"
                  element={
                    <UploadOrderPage
                      handleError={handleError}
                      handleSuccess={handleSuccess}
                    />
                  }
                />
                <Route
                  path="/upload/order/bulk-orders"
                  element={
                    <UploadBulkProject
                      handleError={handleError}
                      handleSuccess={handleSuccess}
                    />
                  }
                />
                <Route
                  path="/upload/order/bulk-orders/:id"
                  element={
                    <UploadBulkProjectId
                      handleError={handleError}
                      handleSuccess={handleSuccess}
                    />
                  }
                />
                <Route
                  path="/preview/order/:id"
                  element={
                    <OpenLayersIdPage
                      handleError={handleError}
                      handleSuccess={handleSuccess}
                    />
                  }
                />
                <Route element={<PrivateRoutes />}>
                  <Route
                    path="/order/:id"
                    element={
                      <OpenLayersIdPage
                        handleError={handleError}
                        handleSuccess={handleSuccess}
                      />
                    }
                  />
                </Route>
                <Route render={() => <Navigate to="/upload/order" />} />
              </Route>
            ) : null}
            <Route path="/4O4" element={<PageNotFound />} />
            <Route path="*" element={<RedirectHomeRouter />} />
            <Route path="/4O3" element={<PageNotAuthorized />} />
            <Route path="/access-denied" element={<AccessDeniedPage />} />
            <Route path="/account-suspension" element={<SuspendedPage />} />
          </Route>
        </Routes>
      )}
      <ToastContainer />
       
    </div>
  );
}
