import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const ToggleButtons = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeTab, setActiveTab] = useState('bulkImages');

  useEffect(() => {
    // Set the activeTab based on the current route
    if (location.pathname === '/mydocs') {
      setActiveTab('bulkImages');
    } else if (location.pathname === '/mydocs/files') {
      setActiveTab('myDocuments');
    }
  }, [location.pathname]);

  return (
    <div className='toggle_btn_div'>
      <div className='toggle_btn_container'>
        <button
          style={{
            backgroundColor: activeTab === 'bulkImages' ? '#519259' : 'grey',
            color: 'white',
          }}
          onClick={() => {
            setActiveTab('bulkImages');
            navigate('/mydocs');
          }}
        >
          Folders
        </button>
        <button
          style={{
            backgroundColor: activeTab === 'myDocuments' ? '#519259' : 'grey',
            color: 'white',
          }}
          onClick={() => {
            setActiveTab('myDocuments');
            navigate('/mydocs/files');
          }}
        >
          Uploads
        </button>
      </div>
    </div>
  );
};

export default ToggleButtons;
