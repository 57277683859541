import './DiyLeftBar.css';
import axios from 'axios';
import { transform } from 'ol/proj';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import { Fill, Stroke, Style } from 'ol/style';
import AuthToken from '../../context/AuthToken';
import { constants } from '../../utils/constants';
import CreateIcon from '@mui/icons-material/Create';
import { FETCH_FEATURE_URL } from '../../helper/ApiUrl';
import total_area_icon from '../../assets/total-area.svg';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import edit_parcel_icon from '../../assets/edit-parcel.svg';
import draw_parcel_icon from '../../assets/draw-parcel.svg';
import { FormControlLabel, FormGroup } from '@material-ui/core';
import { useAddressContext } from '../../context/AddressContext';
import PolygonCheckBox from '../PolygonCheckBox/PolygonCheckbox';
import { ReactComponent as CheckIcon } from '../../assets/Check.svg';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import cutPolygon from '../../OpenLayers/OLInteractions/CutInteraction';
import drawPolygon from '../../OpenLayers/OLInteractions/DrawInteraction';
import { ReactComponent as PropertyIcon } from '../../assets/Property.svg';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import removeInteraction from '../../OpenLayers/OLFeatures/EscRemoveInteraction';
import removeAllInteraction from '../../OpenLayers/OLFeatures/RemoveInteraction';
import Checkbox from '@mui/material/Checkbox';

const DiyLeftBar = ({
  map,
  markerRef,
  parcelRef,
  oldAddress,
  editPolygon,
  polygonList,
  setZoomLayer,
  searchBarRef,
  handleSuccess,
  setParcelEdit,
  setOldAddress,
  saveDiyParcel,
  setParcelStyle,
  setPolygonList,
  draftDiyParcel,
  featureSelected,
  setHoverParcelId,
  setNearmapcoords,
  nearmapImageDate,
  setFeatureSelected,
  setHoverParcelElement,
  removeEventKey
}) => {
  let path = polygonList;
  const diySelectRef = useRef();
  const diySelectInnerRef = useRef();
  const [manualCost, setManualCost] = useState(0);
  const [automateCost, setAutomateCost] = useState(0);
  const { address, setAddress } = useAddressContext();
  const [availableFeatures, setAvailableFeatures] = useState([]);
  const [isDrawActive, setIsDrawActive] = useState(false);
  const [isEditActive, setIsEditActive] = useState(false);
  const [isCutActive, setIsCutActive] = useState(false);

  // 
  const [open, setOpen] = useState(true);

  const parcelStyle = new Style({
    stroke: new Stroke({
      color: 'red',
      width: 2,
    }),
    fill: new Fill({
      color: 'rgb(255, 255, 255,0.2)',
    }),
  });

  const searchAddress = () => {
    console.log("clicked");
    map.getLayers().forEach((layer) => {
      if (layer?.get('title') && layer?.get('title') === 'Marker') {
        map.removeLayer(layer);
      }
    });
    setAddress(null);
    removeAllInteraction(map);
    map.removeLayer(markerRef.current);
    searchBarRef.current.style.display = 'block';
    map.getView().animate({
      zoom: 4,
      duration: 5000,
      center: transform(
        [-90.27096030000001, 39.7200124],
        'EPSG:4326',
        'EPSG:3857'
      ),
    });
  };

  const getAvailableFeatures = () => {
    axios({
      url: FETCH_FEATURE_URL,
      method: 'GET',
      headers: {
        Authorization: `Token ${AuthToken()}`,
      },
    })
      .then((res) => {
        if ('data' in res) {
          const data = res.data.data;
          setAvailableFeatures([...data]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const result = path.reduce(
    (total, currentValue) =>
      (total = parseFloat(total) + parseFloat(currentValue.measurement)),
    0
  );

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      removeAllInteraction(map);
      setIsDrawActive(false);
      setIsEditActive(false);
      setIsCutActive(false);
      document.body.classList.remove('cut-cursor');
      document.body.classList.remove('edit-cursor');
      document.body.classList.remove('split-cursor');
      document.body.classList.remove('crosshair-cursor');
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  const handleClickOutSide = (e) => {
    if (diySelectInnerRef.current) {
      if (!diySelectInnerRef.current.contains(e.target)) {
        diySelectRef.current.style.opacity = 0;
        diySelectRef.current.style.pointerEvents = 'none';
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutSide, true);
  }, []);

  const checkCheckBox = (id) => {
    let flag = false;
    featureSelected.map((item) => {
      if (id === item.id) flag = true;
    });
    return flag;
  };

  const manualOrderCost = () => {
    let no_of_blocks = Math.ceil(result / constants.base);
    if (no_of_blocks) {
      let imaginary_cost = no_of_blocks * constants.image_cost_factor;
      if (nearmapImageDate != 'satellite') {
        setManualCost(imaginary_cost);
      } else {
        setManualCost(imaginary_cost - imaginary_cost);
      }
    }
  };

  useEffect(() => {
    manualOrderCost();
  }, [polygonList, nearmapImageDate]);

  const automateOrderCost = () => {
    let no_of_blocks = Math.ceil(result / constants.base);
    if (no_of_blocks) {
      let imaginary_cost = no_of_blocks * constants.image_cost_factor;
      let feature_cost =
        no_of_blocks * constants.feature_cost_factor * featureSelected.length;
      if (nearmapImageDate != 'satellite') {
        setAutomateCost(imaginary_cost + feature_cost);
      } else {
        setAutomateCost(imaginary_cost + feature_cost - imaginary_cost);
      }
    }
  };

  useEffect(() => {
    if (address !== oldAddress) {
      setPolygonList([]);
      setFeatureSelected([]);
      setOldAddress(address);
    }
  }, [address]);

  useEffect(() => {
    if (!oldAddress) {
      setOldAddress(address);
    }
  }, []);

  useEffect(() => {
    setIsDrawActive(false);
    setIsEditActive(false);
    setIsCutActive(false);
  }, []);

  useEffect(() => {
    automateOrderCost();
  }, [polygonList, featureSelected, nearmapImageDate]);



  //
  useEffect(() => {
    console.log(polygonList, "In useEffect Polygon List parcelselection.jsx ");
  }, [polygonList]);


  const reActivateFunctionality = ( polygonList) => {
    // setPolygonList([]);
    console.log(isDrawActive, "isDrawActive in Reactivate function");
    console.log(polygonList, "after reactivate Polygon List ");
    console.log("after reactivate Polygon List ");

    if (isDrawActive) {
      // removeAllInteraction(map);

      setOpen(true);
      setIsDrawActive(true);
      setIsEditActive(false);
      setIsCutActive(false);
      drawPolygon(
        map,
        parcelRef,
        polygonList,
        setZoomLayer,
        setParcelEdit,
        setPolygonList,
        setParcelStyle,
        handleSuccess
      );
    } 
    else if (isEditActive) {
      setOpen(false);
      console.log("isEditActive ", polygonList)
      editPolygon(polygonList);
    }
     else if (isCutActive) {
      cutPolygon(
        map,

        polygonList,
        setZoomLayer,
        setParcelEdit,
        setPolygonList,
        setParcelStyle,
        handleSuccess
      );
    }
  };


  return (
    <div className="diy-left-bar-container">
      <div className="diy-left-bar-one">
        <PropertyIcon />
        <p>{address}</p>
        <CreateIcon onClick={searchAddress} className="create-icon" />
      </div>
      <div className="diy-left-bar-two">
        Modify Parcel
        <div className="draw-edit-button-cont diy-left-bar-parcel-btn">
          <Tooltip title="Draw Polygon" placement="top">
            <button
              onClick={() => {
                removeAllInteraction(map);
                drawPolygon(
                  map,
                  parcelRef,  
                  polygonList,
                  setZoomLayer,
                  setParcelEdit,
                  setPolygonList,
                  setParcelStyle,
                  handleSuccess,
                  removeEventKey
                )
                setIsDrawActive(true);
                setIsEditActive(false);
                setIsCutActive(false);
              }
              }
              className={isDrawActive ? "draw-parcel-button-active" : "draw-parcel-button"}
            >
              <img src={draw_parcel_icon} alt="draw_parcel_icon" />
              Draw
            </button>
          </Tooltip>
          <Tooltip title="Modify Polygon" placement="top">
            <button onClick={() => { 
               removeAllInteraction(map);
              editPolygon(); 
              setIsEditActive(true); 
              setIsDrawActive(false); 
              setIsCutActive(false); 
              }} className={isEditActive ? "edit-parcel-button-active" : "edit-parcel-button"}
            >
              <img src={edit_parcel_icon} alt="edit_parcel_icon" />
              Edit
            </button>
          </Tooltip>
          <Tooltip title="Cut Polygon" placement="top">
            <button
              onClick={() => {
                removeAllInteraction(map);
                cutPolygon(
                  map,
                  polygonList,
                  setZoomLayer,
                  setParcelEdit,
                  setPolygonList,
                  setParcelStyle,
                  handleSuccess
                )
                setIsDrawActive(false);
                setIsCutActive(true);
                setIsEditActive(false);

              }}
              className={isCutActive ? "cut-parcel-button-active" : "cut-parcel-button"}
            >
              <ContentCutIcon className="cut-parcel-icon" /> Cut
            </button>
          </Tooltip>

        </div>
        <div className="parcel-id-container diy-parcel-id-container">
          <div className="parcel-ids diy-parcel-ids">
            {path.map((poly, index) => (
              <PolygonCheckBox
                map={map}
                data={poly}
                path={path}
                id={poly.id}
                index={index}
                key={poly.id}
                setPath={setPolygonList}
                setNearmapcoords={setNearmapcoords}
                setHoverParcelId={setHoverParcelId}
                setHoverParcelElement={setHoverParcelElement}

                  polygonList={polygonList}
                  setPolygonList={setPolygonList}
                  reActivateFunctionality={reActivateFunctionality}
         
                  isDrawActive={isDrawActive}
                  isEditActive={isEditActive}
                  isCutActive={isCutActive}
                  setIsDrawActive={setIsDrawActive}
                  setIsEditActive={setIsEditActive}
                  setIsCutActive={setIsCutActive}
              />
            ))}
          </div>
          <div className="total-area-container">
            <img src={total_area_icon} alt="total_area_icon" />
            Total area {result.toFixed(2)} acre
          </div>
        </div>
      </div>
      <div className="diy-left-bar-three">
        Select Features
        <div className="diy-feature-selection-div">
          {featureSelected &&
            featureSelected.map((feature, index) => {
              return (
                <span>
                  {++index}. {feature.name}
                </span>
              );
            })}
        </div>
        <button
          className="diy-adding-new-feature"
          onClick={() => {
            getAvailableFeatures();
            removeAllInteraction(map);
            setParcelStyle(parcelStyle);
            diySelectRef.current.style.opacity = 1;
            diySelectRef.current.style.pointerEvents = 'auto';
          }}
        >
          <AddIcon className="view-add-icon" />
          <span>Add New Feature</span>
        </button>
        <div ref={diySelectRef} className="diy-feature-info-container">
          <div ref={diySelectInnerRef} className="diy-feature-container">
            <div style={{padding: "5px 10px", boxSizing: "border-box"}} className="view-modal-top add-layer-modal-close">
              <span
                onClick={() => {
                  diySelectRef.current.style.opacity = 0;
                  diySelectRef.current.style.pointerEvents = 'none';
                }}
              >
                X
              </span>
            </div>
            <div className="layers-info-inner-container-one diy-info-inner-container-one">
              <FormGroup row={false}>
                {availableFeatures.map(({ id, name }) => (
                  <FormControlLabel
                    key={id}
                    className="layers-info-input"
                    control={
                      <Checkbox
                        size='small'
                        color="success"
                        value={id}
                        checked={checkCheckBox(id)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFeatureSelected([
                              ...featureSelected,
                              { id: id, name: name },
                            ]);
                          } else {
                            setFeatureSelected(
                              featureSelected.filter(
                                (feature) => feature.id !== id
                              )
                            );
                          }
                        }}
                      />
                    }
                    label={name}
                  />
                ))}
              </FormGroup>
            </div>
            <div className="property-function-button diy-function-button">
              <button
                onClick={() => {
                  diySelectRef.current.style.opacity = 0;
                  diySelectRef.current.style.pointerEvents = 'none';
                }}
              >
                <CheckIcon className="check-icon" />{' '}
                <span>Confirm Selected Feature</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="diy-left-bar-four">
        <button onClick={saveDiyParcel}>Start Measurement</button>
        <span>
          <AttachMoneyOutlinedIcon className="diy-cost-icon" /> {manualCost}
        </span>
      </div>
      <div className="diy-left-bar-four">
        <button onClick={draftDiyParcel}>Automate Property</button>
        <span>
          <AttachMoneyOutlinedIcon className="diy-cost-icon" /> {automateCost}
        </span>
      </div>
    </div>
  );
};

export default DiyLeftBar;
