import axios from 'axios';
import React, { useState } from 'react';
import './MapLeftBar.css';
import './PlaceOrder.css';
import { Tooltip } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import AuthToken from '../../context/AuthToken';
import { ORDER_URL } from '../../helper/ApiUrl';
import AddressBar from '../AddressBar/AddressBar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import total_area_icon from '../../assets/total-area.svg';
import { useScreenType } from '../../utils/commonMethods';
import { STATUS, TOAST_TYPE } from '../../utils/constants';
import AddAttachment from '../AddAttachment/AddAttachment';
import { ReactComponent as Arrow } from '../../assets/close.svg';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ReactComponent as CheckIcon } from '../../assets/Check.svg';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import PropertyInformation from '../PropertyInformation/PropertyInformation';
import { ReactComponent as CheckBlackIcon } from '../../assets/CheckBlack.svg';

import MediaDialog from '../MediaDialog/MediaDialog';
import path from "path";

import useMediaQuery from '@mui/material/useMediaQuery';


export default function PlaceOrder({
  cost,
  step,
  status,
  result,
  setStep,
  address,
  expHours,
  setStatus,
  resetData,
  orderNote,
  expMinutes,
  isCloseBtn,
  timerHours,
  attachments,
  handleError,
  toggleSlide,
  featureCost,
  bulkOrderId,
  imageryCost,
  setOrderNote,
  propertyCost,
  timerMinutes,
  handleSuccess,
  setPlacedTime,
  setAttachments,
  toggleSlideBtn,
  navigateAddress,
  setPlaceOrderFe,
  featureSelected,
  setFeatureSelected,
  setPropertyCost,
  nearmapImageDate,
  handleCustomError,
  handleCustomSuccess,
  clearedSteps,
  setClearedSteps
}) {
  const isSmallerThan820px = useMediaQuery('(max-width:820px)');
  const { id } = useParams();
  const params = useParams();
  const isMobile = useScreenType();

  const [dialogMedia, setDialogMedia] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = (item) => {
    let p = path.extname(item);
    if (
      p !== ".jpg" &&
      p !== ".jpeg" &&
      p !== ".png" &&
      p !== ".gif" &&
      p !== ".mp4" &&
      p !== ".mov"
    )
      window.open(item, "_blank");
    else {
      setDialogMedia(item);
      setOpenDialog(true);
    }
  };

  

    const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();
  const removeFeature = (id)=>{
    setFeatureSelected((prev)=> prev.filter(( prevEachFeature)=> prevEachFeature.id !== id))
  }

  const placeOrder = (e) => {
    e.preventDefault();
    let data;
    let imageryDate = '';
    if (nearmapImageDate != 'satellite') {
      imageryDate = `?imagery=${nearmapImageDate}`;
    }
    if (orderNote) {
      data = { order_note: orderNote };
    }
    axios({
      url: `${ORDER_URL}${params.id}/place${imageryDate}`,
      method: 'POST',
      data: data,
      headers: {
        Authorization: `Token ${AuthToken()}`,
      },
    })
      .then((res) => {
        setStatus(STATUS.IN_PROGRESS);
        setPlaceOrderFe(true);
        setPropertyCost(cost);
        setClearedSteps([...clearedSteps, 4])
       
        if (bulkOrderId) {
          handleCustomSuccess(TOAST_TYPE.SUCCESS, 'Order successfully placed');
        } else {
          handleSuccess(TOAST_TYPE.SUCCESS, 'Order successfully placed');
        }
        //  navigate('/dashboard')
      })
      .catch((err) => {
        if (bulkOrderId) {
          handleCustomError(err);
        } else {
          handleError(err);
        }
      });
  };

  return (
    <div className="property-container" style={{maxHeight:"80vh"}}>
      <div className="property-inner-cont">
        {/* {!isMobile ? (
          status === STATUS.DRAFT ? (
            <div className="property-inner-cont-one">
              <Tooltip title="Back">
                <button onClick={() => setStep(step - 1)}>
                  <ArrowBackIcon className="arrow-back-icon" />
                </button>
              </Tooltip>
              <AddressBar
                address={address}
                resetData={resetData}
                navigateAddress={navigateAddress}
              />
            </div>
          ) : (
            <AddressBar
              address={address}
              resetData={resetData}
              navigateAddress={navigateAddress}
            />
          )
        ) : null} */}
        {/* <PropertyInformation
          handleError={handleError}
          handleSuccess={handleSuccess}
        /> */}
        <div className="property-attribute-cont">
          <div className="close-btn-div">
            <div className="draft-status-back-button-div">
              {status === STATUS.DRAFT && (
                <Tooltip title="Back">
                  <button onClick={() => setStep(step - 1)}>
                    <ArrowBackIcon className="arrow-back-icon" />
                  </button>
                </Tooltip>
              )}
               <span>Place Order</span>
            </div>
            <div
              style={
                status === STATUS.DRAFT || status === STATUS.IN_PROGRESS
                  ? {
                      height: '30%',
                    }
                  : null
              }
              className={`map-leftbar-close-btn ${
                isCloseBtn ? '' : 'slide-close-hidden'
              }`}
            >
              <button
                className="close-feature-container"
                onClick={() => {
                  toggleSlide();
                  toggleSlideBtn();
                }}
              >
                <Arrow />
              </button>
            </div>
          </div>
          <div className="time-cost-container">
            {status === STATUS.DRAFT ? (
              <div className="time-container">
                <div className="time-head">Expected Time</div>
                <div className="time-value">
                  <ul>
                    <li>
                      {expHours < 2 ? '2' : expHours}
                      <span>hour</span>
                    </li>
                    <li>
                      {expHours < 2 ? '0' : expMinutes}
                      <span>minute</span>
                    </li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className="time-container">
                <div className="time-head">Remaining Time</div>
                <div className="time-value">
                  <ul>
                    <li>
                      {timerHours}
                      <span>hour</span>
                    </li>
                    <li>
                      {timerMinutes}
                      <span>minute</span>
                    </li>
                  </ul>
                </div>
              </div>
            )}
            {status === STATUS.DRAFT ? (
              <div className="cost-container">
                <div className="cost-cont">
                  <div className="time-head">Expected Cost</div>
                
                  {!isSmallerThan820px &&<Tooltip
                    title={
                      <>
                        <div>Image Cost: {imageryCost} USD</div>
                        <div>Feature Cost: {featureCost} USD</div>
                      </>
                    }
                    enterTouchDelay={0} // Show tooltip immediately on touch devices
                    leaveTouchDelay={3000} // Hide tooltip after 3 seconds if not clicked again
                    onClick={(event) => event.stopPropagation()} // Ensure the tooltip is triggered properly on mobile
                  >
                    <InfoOutlinedIcon className="cost-info-icon" />
                  </Tooltip>}

                  {isSmallerThan820px && <Tooltip
                    title={
                      <>
                        <div>Image Cost: {imageryCost} USD</div>
                        <div>Feature Cost: {featureCost} USD</div>
                      </>
                    }
                    PopperProps={{
                      // disablePortal: true,
                    }}
                    onClick={() => setOpen(true)}
                    onClose={handleTooltipClose}
                    open={open}
                  >
                    <InfoOutlinedIcon className="cost-info-icon" />
                  </Tooltip>}
                </div>
                <div className="cost-value">
                  {cost} <span>USD</span>
                </div>
              </div>
            ) : status === STATUS.IN_PROGRESS ? (
              <div className="cost-container">
                <div className="cost-head">Total Cost</div>
                {propertyCost ? (
                  <div className="cost-value">
                    {propertyCost} <span>USD</span>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
          <p className="feature-selection-heading-place-oreder">
            Selected Features
          </p>
          <div className="selected-features-container">
        {/* {console.log(featureSelected,"featureSelected")} */}
            {featureSelected.map((item) => {
              return (
                <div className="selected-feature">
                  <p>{item.name}</p>
                  {/* {status === STATUS.DRAFT?<p className='remove__selected__features' id="remove__selected__features" onClick={()=> removeFeature(item.id) } >x</p>:null} */}
                </div>
              );
            })}
          </div>
          <div className="total-area-container">
            <img src={total_area_icon} alt="total_area_icon" />
            Total area {result.toFixed(2)} acre
          </div>
        </div>
        {!isMobile ? (
          <div className="notes-and-attachments-container" style={{margin:"2% 0"}}>
            <div>
              <p
                className="feature-selection-heading-place-oreder"
                style={{ marginTop: '0px' }}
              >
                Order Notes:
              </p>
              <div className="order-note-container">
                <textarea
                  rows="1"
                  required
                  disabled={status !== STATUS.DRAFT}
                  placeholder={
                    status === STATUS.DRAFT
                      ? 'Please describe your order below.'
                      : 'No order notes added.'
                  }
                  value={orderNote}
                  onChange={(e) => setOrderNote(e.target.value)}
                  style={{ fontFamily: 'Roboto, sans-serif',color:"black",background:`${!orderNote && status !== STATUS.DRAFT?"#80808075":"#ffffff"}` }}
                />
              </div>

              {
                status === STATUS.DRAFT ? (<AddAttachment
                id={id}
                maxSize={10}
                bulkOrderId={bulkOrderId}
                attachmentForBulk={false}
                attachments={attachments}
                handleError={handleError}
                handleSuccess={handleSuccess}
                setAttachments={setAttachments}
                handleCustomError={handleCustomError}
                handleCustomSuccess={handleCustomSuccess}
              />): null
              }
            {console.log(attachments,"attachments",attachments.length,"attachments.length")}
             { attachments && attachments.length>0 &&<p
                className="text_uploaded_attachment"
                >
                Uploaded Attachments ({attachments.length}) 
                <button className='attachment_preview_btn' 
                onClick={()=>document.querySelector('.attachment_preview_box').style.display="block"}
                >View</button>
              </p>}
              {/* { attachments &&
                Array.isArray(attachments) ?
               <div  
               style={{width:"100%",maxHeight:"3.5rem",display:"flex",
               alignItems:'center',padding:"0.4rem",
               flexWrap:"wrap",overflow:"scroll",gap:"0.4rem"}}>
                {attachments.map((attachment,index)=>(
                  <div key={index} 
                  onClick={() => handleOpenDialog(attachment)}
                  className=''
                  style={{width:"4.5rem",height:"3rem", }}>
                  <img src={attachment} style={{maxWidth:"100%",borderRadius:"4px",
                  boxShadow:" rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
                  ,objectFit:"cover"
                  }} />
                  </div>  
                ))}
              </div>
              : null
              } */}
            
            </div>
          </div>
        ) : null}
        <div className="property-function-button">
          {status === STATUS.DRAFT ? (
            <>
              <div>
                <button className='ottermap-button' style={{width: "100%"}} onClick={placeOrder}>
                  <CheckIcon className="check-icon" /> Place Order
                </button>
              </div>
            </>
          ) : (
           <>
           <div className="order-status-in-progress" style={{padding:"0.8rem"}}>
              <div>
                <h4>Order In Progress</h4>
              </div>
              <div>
                <div class="anim-element-wrapper">
                  <div class="aem anim-elem"></div>
                  <div class="aem anim-elem-2"></div>
                  <div class="aem anim-elem-3"></div>
                  <div class="aem anim-elem-4"></div>
                </div>
              </div>
              
            </div>
            <div>
                <p style={{fontSize:"0.6rem",fontWeight:"600",textAlign:"center",margin:"0",lineHeight:"1"}}
                >
                If you have any questions or issues, reach us at <br/> <a href='mailto:info@ottermap.com' style={{color:"#064635"}}>info@ottermap.com</a>.</p>
            </div>
           </>
            
          )}
        </div>
      </div>

    

      <MediaDialog 
         dialogMedia={dialogMedia}
         openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />
    </div>
  );
}
